import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const SelectType = observer(({ data, change }) => {

    const [state, setState] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element'>
            <label htmlFor="type">Тип</label>
            <select id="type" value={state !== null ? state : "all"} onChange={(e) => setState(e.target.value)}>
                <option key={ Math.random() } value="all">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default SelectType;