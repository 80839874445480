import { makeAutoObservable } from 'mobx';

class DataStorage {
    users = []
    roles = []
    reloader = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setUsers = (users) => {
        this.users = users;
    }

    setRoles = (roles) => {
        this.roles = roles;
    }

    setReloader = () => {
        this.reloader++;
    }
}

const data = new DataStorage();
export default data;