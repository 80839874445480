import './orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ContentBlock from '../../common/elements/content-block';
import Column from "../../common/marking/column"
import Input from './elements/input';
import Textarea from './elements/textarea';
import TableCardShop from '../../common/table/TableCardShop';
import api from "../../../services/api"
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';
import CardShop from './card-shop';
import Customer from './elements/customer';
import TaskBar from './elements/task-bar';
import AddressModule from '../Address/AddressModule';
import Row from '../../common/marking/row';
import DeliveryType from './elements/delivery-type';
import toast from 'react-hot-toast';
import InputHSelect from '../../elements/inpute/InputHSelect';
import { getNumber } from '../../../services/util';


const OrderEdit = observer(() => {

    const [ render, setRender ] = useState(1)

    // eslint-disable-next-line
    const navigator = useNavigate()
    const { id } = useParams();
    const [ order, setOrder ] = useState(null)
    // eslint-disable-next-line
    const [ status, setStatus ] = useState(false)

    // eslint-disable-next-line
    const [ goodsGroup, setGoodsGroup ] = useState(null);
    const [ managers, setManagers ] = useState(null)
    const [ typeLeads, setTypeLeads ] = useState(null)
    const [ typeOrder, setTypeOrder ] = useState(null)
    const [ shops, setShops ] = useState(null)
    // eslint-disable-next-line
    const [ warehouse, setWarehouse ] = useState(null)
    // eslint-disable-next-line
    const [ discount, setDiscount ] = useState(0)
    const [ totalPrice, setTotalPrice ] = useState()

    const [ fieldManagers, setFieldManagers ] = useState(null)
    const [ fieldTypeLeads, setFieldTypeLeads ] = useState(null)
    const [ fieldTypeOrder, setFieldTypeOrder ] = useState(null)
    const [ fieldShops, setFieldShops ] = useState(null)

    // eslint-disable-next-line
    const [ goodsTag, setGoodsTag ] = useState(null)
    const [ customers, setCustomers ] = useState([])
    const [ customer, setCustomer ] = useState(null)
    const [ firstname, setFirstname ] = useState(null)
    const [ surname, setSurname ] = useState(null)
    const [ patronymic, setPatronymic ] = useState(null)
    const [ email, setEmail ] = useState(null)
    const [ phone, setPhone ] = useState(null)
    const [ shopCard, setShopCard ] = useState(null)

    // eslint-disable-next-line
    const [ addressState, setAddressState ] = useState(null)
    const [ deliveryState, setDeliveryState ] = useState({})

    const saveOrder = () => {
        let data = new FormData()
        data.append('orderID', id != null ? id : 0)

        data.append('generalManager', fieldManagers)
        data.append('generalTypeOrder', fieldTypeOrder)
        data.append('generalTypeLeads', fieldTypeLeads)
        data.append('generalShop', fieldShops)

        data.append('customerID', customer != null ? customer.id : 0)
        data.append('customerFirstname', firstname != null ? firstname : '')
        data.append('customerSurname', surname != null ? surname : '')
        data.append('customerPatronymic', patronymic != null ? patronymic : '')
        data.append('customerEmail', email != null ? email : '')
        data.append('customerPhone', phone != null ? phone : '')

        order.shopCard.forEach(item => {
            data.append('shopCard[]', JSON.stringify(item))
        })

        data.append('addressPostCode', addressState != null && addressState.postCode != null ? addressState.postCode : '')
        data.append('addressCountry', addressState != null && addressState.country != null ? addressState.country : '')
        data.append('addressDistrict', addressState != null && addressState.district != null ? addressState.district : '')
        data.append('addressCity', addressState != null && addressState.city != null ? addressState.city : '')
        data.append('addressLocality', addressState != null && addressState.locality  != null ? addressState.locality : '')
        data.append('addressStreet', addressState != null && addressState.street != null ? addressState.street : '')
        data.append('addressHouse', addressState != null && addressState.house != null ? addressState.house : '')
        data.append('addressBuilding', addressState != null && addressState.building != null ? addressState.building : '')
        data.append('addressSubway', addressState != null && addressState.subway != null ? addressState.subway : '')
        data.append('addressComment', addressState != null && addressState.comment != null ? addressState.comment : '')
        data.append('geoLat', getNumber(addressState != null && addressState.geoLat))
        data.append('geoLon', getNumber(addressState != null && addressState.geoLon))

        data.append('deliveryMode', deliveryState.deliveryMode != null ? deliveryState.deliveryMode : '')
        data.append('deliveryDate', deliveryState.deliveryDate != null ? deliveryState.deliveryDate : '')
        data.append('deliveryStartTime', deliveryState.deliveryStartTime != null ? deliveryState.deliveryStartTime : '')
        data.append('deliveryEndTime', deliveryState.deliveryEndTime != null ? deliveryState.deliveryEndTime : '')
        data.append('deliveryPrice', deliveryState.deliveryPrice != null ? deliveryState.deliveryPrice : '')
        data.append('deliverySupplierPrice', deliveryState.deliverySupplierPrice != null ? deliveryState.deliverySupplierPrice : '')

        data.append('commentCustomer', order != null ? order.commentCustomer : '')
        data.append('commentManager', order != null ? order.commentManager : '')

        data.append('discount', discount)
        data.append('totalPrice', totalPrice)

        const getData = async () => {
            await api.post('/orders/save-order', data)
                .then(res => toast.success("Сохранено"))
                .catch(e => toast.error("Ошибка"))
        }
        getData()
    }

    const clearActiveCustomer = () => {
        setCustomer(null)
    }

    // const changeReservation = () => {
    //     const getData = async () => {
    //         const data = new FormData()
    //         data.append('id', Number(id))
    //         await api.post('/orders/make-reservation', data)
    //             .then(res => setStatus(!status))
    //             .catch(e => console.log(e))
    //     }
    //     getData()
    // }

    const getCustomer = (id) => {
        let data = new FormData()
        data.append('id', Number(id))
        api.post('/customer/get-customer', data)
            .then(res => setCustomer(res.data))
            .catch(e => console.log(e))

        const area = document.querySelector('#customer-list-right')
        area.classList.add('d-none')

        const activeUser = document.querySelector('#active-customer')
        activeUser.classList.remove('d-none')
        activeUser.classList.add('padding-bottom-none')
    }

    const chooseCustomer = async (event) => {
        let customer = event.target.parentElement.dataset.customers;
        if (customer == null) customer = event.target.dataset.customers;
        getCustomer(customer)
    }

    const hideCustomersList = () => {
        const area = document.querySelector('#customer-list-right')
        area.classList.add('d-none')
    }

    useEffect(() => {
        const getData = async() => {
            let dataOrder = new FormData()
            dataOrder.append('id', Number(id))
            await api.post('/orders/get-order', dataOrder)
                .then(res => {
                    setOrder(res.data.order)
                    setManagers(res.data.managers.map(item => {
                        return {
                            id: item.id,
                            name: `${item.username !== null ? item.username : ''} ${item.surname !== null ? item.surname : ''} ${item.patronymic !== null ? item.patronymic : ''}`
                        }
                    }))
                    setShops(res.data.shops)
                    setTypeLeads(res.data.typeLeads)
                    setTypeOrder(res.data.typeOrders)
                    setWarehouse(res.data.warehouses)
                    setGoodsTag(res.data.goodsTags)
                    setGoodsGroup(res.data.groupGoods)
                    setShopCard(res.data.order.shopCard)
                })
                .catch(e => console.log(e))
        }
        getData()        
    // eslint-disable-next-line
    },[])

    const setCommentManager = (value) => {
        let tempOrder = order
        tempOrder.commentManager = value
        setOrder(JSON.parse(JSON.stringify(tempOrder)))
    }

    const setCommentCustomer = (value) => {
        let tempOrder = order
        tempOrder.commentCustomer = value
        setOrder(JSON.parse(JSON.stringify(tempOrder)))
    }

    useEffect(() => {
        if(order != null) {
            setOrder(JSON.parse(JSON.stringify(order)))
        }
        if(shopCard != null) {
            setShopCard(JSON.parse(JSON.stringify(shopCard)))
        }
    // eslint-disable-next-line
    }, [render])

    useEffect(() => {
        const area = document.querySelector('#customer-list-right')
        if (area !== null) {

            if (customers.length === 0) area.classList.add('d-none')
            else area.classList.remove('d-none')

            let html = '';
            if (customers.length > 0) 
                customers.forEach(item => {
                    html += `
                        <div data-customers="${item.id}">
                            <div>${item.firstname} ${item.surname !== null ? item.surname : ''} ${item.patronymic !== null ? item.patronymic : ''}</div>
                            <div>${item.users ? item.users.username : 'свободен'}</div>
                        </div>`
                })
            
                html += `
                    <div class="customer-list-btn" id="customer-list-btn">
                        <div>Новый клиент</div>
                    </div>`
                
            area.innerHTML = html;
            
            const button = document.querySelector('#customer-list-btn')
            button.removeEventListener('click', hideCustomersList)
            button.addEventListener('click', hideCustomersList)

            const listCustomers = document.querySelectorAll('[data-customers]')
            listCustomers.forEach(item => {
                item.removeEventListener('click', chooseCustomer)
                item.addEventListener('click', chooseCustomer)
            })
        }

    // eslint-disable-next-line
    }, [customers])

    useEffect(() => {
            const customerFields = {
                firstname: {
                    field: document.querySelector('#customer-firstname'),
                    value: customer != null ? customer.firstname : "0"
                },
                surname: {
                    field: document.querySelector('#customer-surname'),
                    value: customer != null ? customer.surname : "0"
                },
                patronymic: {
                    field: document.querySelector('#customer-patronymic'),
                    value: customer != null ? customer.patronymic : "0"
                },
                phone: {
                    field: document.querySelector('#customer-phone'),
                    value: customer != null ? customer.phone : ""
                },
                email: {
                    field: document.querySelector('#customer-email'),
                    value: customer != null ? customer.email : ""
                }
            }

            const highlightFields = (object, value) => {
                const keys = Array.from(Object.keys(object))
                keys.forEach(item => {
                    object[item].field.disabled = value
                    object[item].field.value = ""
            })
            }

            const containsNullFields = object => {
                let result = true;
                const keys = Array.from(Object.keys(object))
                keys.forEach(item => {
                    if(object[item].field === null) 
                        result = false
                })
                return result
            }

            const fillValueInObject = (object) => {
                const keys = Array.from(Object.keys(object))
                keys.forEach(item => {
                    const element = object[item]
                    element.field.value = element.value
                })
                highlightFields(customerFields, true)
            }

            if (containsNullFields(customerFields)) {
                if (customer === null) highlightFields(customerFields, false)
                else fillValueInObject(customerFields)
            }
    // eslint-disable-next-line
    }, [customer])

    useEffect(() => {
        if (order != null) {
            if (id != null) {
                getCustomer(order.customers.id) 
                if (order.reservation != null)
                    setStatus(order.reservation)
            }
            setDeliveryState({
                deliveryMode: order.typeDelivery != null ? order.typeDelivery.id : 'all',
                deliveryDate: order.deliveryDate != null ? order.deliveryDate : '',
                deliveryStartTime: order.deliveryStartTime != null ? order.deliveryStartTime : '',
                deliveryEndTime: order.deliveryEndTime != null ? order.deliveryEndTime : '',
                deliveryPrice: order.deliveryPrice != null ? order.deliveryPrice : '',
                deliverySupplierPrice: order.deliverySupplierPrice != null ? order.deliverySupplierPrice : ''
            })
            setFieldManagers(order.user != null ? order.user.id : 'all')
            setFieldTypeLeads(order.typeLeads != null ? order.typeLeads.id : 'all')
            setFieldTypeOrder(order.typeOrder != null ? order.typeOrder.id : 'all')
            setFieldShops(order.shops != null ? order.shops.id: 'all')
            setAddressState(order.deliveryAddress != null ? {
                postCode: order.deliveryAddress.postCode,
                country: order.deliveryAddress.country,
                district: order.deliveryAddress.district,
                city: order.deliveryAddress.city,
                locality: order.deliveryAddress.locality,
                street: order.deliveryAddress.street,
                house: order.deliveryAddress.house,
                building: order.deliveryAddress.building,
                subway: order.deliveryAddress.subway,
                comment: order.deliveryAddress.comment,
                geoLat: order.deliveryAddress.geoLat,
                geoLon: order.deliveryAddress.geoLon
            } : null)
        }
    // eslint-disable-next-line 
    }, [order])

    useEffect(() => {
        const getCustomerData = async (firstname, surname, patronymic) => {
            let data = new FormData()
            data.append('firstname', firstname)
            data.append('surname', surname)
            data.append('patronymic', patronymic)

            await api.post('/customer/get-customer-name', data)
                .then(res => setCustomers(res.data))
                .catch(e => console.log(e))
        }
        getCustomerData(firstname, surname, patronymic)
    }, [firstname, surname, patronymic])

    useEffect(() => {
        const getCustomerData = async (email) => {
            let data = new FormData()
            data.append('email', email)

            await api.post('/customer/get-customer-email', data)
                // .then(res => setCustomers(res.data))
                .catch(e => console.log(e))
        }
        getCustomerData(email)
    }, [email])

    useEffect(() => {
        const getCustomerData = async (phone) => {
            let data = new FormData()
            data.append('phone', phone)

            await api.post('/customer/get-customer-phone', data)
                // .then(res => setCustomers(res.data))
                .catch(e => console.log(e))
        }
        getCustomerData(phone)
    }, [phone])

    useEffect(() => {
        if(order != null) {
            let tempOrder = order
            tempOrder.shopCard = shopCard
            setOrder(JSON.parse(JSON.stringify(tempOrder)))
        }
    // eslint-disable-next-line
    }, [shopCard])

    return (
        <div>
            { order === null ? <div className='preloader-block'><div className='loader'></div></div> :
                <div>
                    <CardShop 
                        goodsInOrder={ shopCard }
                        setGoodsInOrder={ setShopCard }
                        setRender={setRender}
                        />
                    <div className="Order">
                        <div className='order-top'>
                            <div className='order-links'>
                                <Link to={'/'}>Главная</Link>
                                <span>→</span>
                                <Link to={'/sales/orders'}>Заказы</Link>
                            </div>
                            <div className='order-h1'>
                                Заказ { order !== null ? order.id : '' }
                                <div className='order-h1-container'>
                                    <div className='order-h1-head'>Сумма заказа</div>
                                    <div className='order-h1-body'>{order !== null ? Number(order.totalPrice) : ''} ₽</div>
                                </div>
                                <div className='order-h1-container'>
                                    <div className='order-h1-head'>Поступил</div>
                                    <div className='order-h1-body order-h1-body__nobold'>{ order !== null ? new Date(order.dateCreated).toLocaleString() : '' }</div>
                                </div>
                            </div>
                            { order !== null ? <TaskBar order={order}/> : '' }
                        </div>
                        <ContentBlock title="Основное" name="common" defaultState={true} button={null} content={
                            <div className='ContentBlockBody'>
                                <Column contant={
                                    <div>
                                        {fieldManagers != null && managers !== null ? <InputHSelect label="Менеджер" name="manager" data={managers} value={ fieldManagers } method={ setFieldManagers } /> : null}
                                        {fieldTypeLeads != null && typeLeads !== null ? <InputHSelect label="Способ" name="type_leads" data={typeLeads} value={ fieldTypeLeads } method={ setFieldTypeLeads }/> : null}
                                    </div>
                                }/>
                                <Column contant={
                                    <div>
                                        {fieldTypeOrder != null && typeOrder !== null ? <InputHSelect label="Тип заказа" name="type_order" data={typeOrder} value={ fieldTypeOrder } method={ setFieldTypeOrder } /> : null}
                                        {fieldShops != null && shops !== null ? <InputHSelect label="Магазин" name="shops" data={shops} value={ fieldShops } method={ setFieldShops } /> : null}
                                    </div>
                                }/>
                            </div>
                        }/>
                        <ContentBlock title="Клиент" name="customer" defaultState={true} button={null} content={
                            <div className='ContentBlockBody'>
                                <Customer customer={customer} id="active-customer" method={clearActiveCustomer}/>
                                <Column contant={
                                    <div>
                                        <Input title="Фамилия" name="customer-surname" change={ setSurname }/>
                                        <Input title="Имя" name="customer-firstname" change={ setFirstname }/>
                                        <Input title="Отчество" name="customer-patronymic" change={ setPatronymic }/>
                                    </div>
                                }/>
                                <Column contant={
                                    <div>
                                        <div className='customer-list d-none' id="customer-list-right"></div>
                                        <Input title="Email" name="customer-email" change={ setEmail }/>
                                        <Input title="Телефон" name="customer-phone" change={ setPhone }/>
                                    </div>
                                }/>
                            </div>
                        }/>
                        <ContentBlock title="Состав заказа" name="card-shop" defaultState={true} button={
                            <div className='table-goods-top-btn'>Добавить товар</div>
                        } content={
                            <div className='ContentBlockBody'>
                                {/* <div className='order-reservation-block'>
                                    { 
                                        id != null ? <div className={ status ? 'order-reservation-block__button order-reservation-block-button__active' : 'order-reservation-block__button' }
                                            onClick={ changeReservation } >Забронировать</div>
                                        : ''
                                    }
                                </div> */}
                                { order !== null ? <TableCardShop goods={ shopCard != null ? shopCard : null } discauntEnter={order !== null ? order.discount : ""} setShopCardData={setShopCard} setRender={setRender} deliveryState={ deliveryState } orderId={ id } methodOrderDiscont={ setDiscount } methodOrderTotalPrice={ setTotalPrice }/> : '' }
                            </div>
                        }/>
                        <ContentBlock title="Доставка" name="delivery" defaultState={true} button={null} content={
                            <div className='ContentBlockBody'>
                                <Row contant={
                                    <div>
                                        <AddressModule value={ addressState } method={ setAddressState } />
                                        <DeliveryType value={ deliveryState } method={ setDeliveryState } />
                                    </div>
                                }/>
                            </div>
                        }/>
                        <ContentBlock title="Комментарии" name="comments" defaultState={true} button={null} content={
                            <div className='ContentBlockBody'>
                                <Column contant={
                                    <div>
                                        <Textarea title="Комментарии клиента" name="comments-customer" value={order !== null ? order.commentCustomer : ""} method={ setCommentCustomer } />
                                    </div>
                                }/>
                                <Column contant={
                                    <div>
                                        <Textarea title="Комментарии оператора" name="comments-manager" value={order !== null ? order.commentManager : ""} method={ setCommentManager }/>
                                    </div>
                                }/>
                            </div>
                        }/>
                    </div>
                    <Foother contant={ 
                        <div className="user-foother">
                            <ButtonSave text="Сохранить" method={ ()=> saveOrder() }/>
                            <div id="user-save__success">Сохранено</div>
                            <div id="user-save__wrong">Ошибка сохранения</div>
                        </div>
                    } />
                </div>
            }
        </div>
    )
})

export default OrderEdit;