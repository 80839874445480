import { observer } from "mobx-react-lite"
import "../../elements.css"
import React, { useEffect, useState } from "react"

const InputCheckbox = observer(({ lableText, dataAll, dataChecked }) => {

    const [ dataArray, setDataArray ] = useState(dataChecked)

    const handleChange = async (e) => {
        await dataArray.map(item => String(item.id)).includes(e.target.id)
            ? setDataArray(dataArray.filter(x => String(x.id) !== e.target.id))
            : setDataArray([...dataArray, {id: Number(e.target.id), name: e.target.name}])
        }

    useEffect(() => {
        
    // eslint-disable-next-line
    },[ dataArray ])

    return (
        <div className="input-label">
            <label>{ lableText }</label>
                <div className="checkbox-box">
                    {
                        dataAll.map(item => {
                            return  <div className="checkbox-element" key={ Math.random() }>
                                        <input  type="checkbox"
                                            name={ item.name }
                                            id={ item.id }
                                            checked={ dataArray.map(el => el.id).includes(item.id) }
                                            onChange={ e => handleChange(e)}
                                        />
                                        <label>{ item.name }</label>
                                    </div>
                        })
                    }
                </div>
        </div>
    )
})

export default InputCheckbox