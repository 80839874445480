import { COUNT_ELEMENT_ON_PAGE } from "../../../settings" 


export const insertPaginator = (countElements, currentPage, method) => {
    // Общее кол-во элементов, текущая страница, метод для записи текущей страницы
    // На странице должен быть блок <div className="table-foother" id='paginator'></div>
    const area = document.querySelector('#paginator')
    if (countElements !== 0) {
        const totalPage = Math.round( countElements / COUNT_ELEMENT_ON_PAGE , 0)
        if (totalPage > 0) {
            let html = ''
            if (currentPage > 3) html += `<div class='paginator-number' data-page="${ 1 }">${ 1 }</div><div class='paginator-number paginator-number__svg'><svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>`
            for (let i = 0; i < totalPage; i++) {
                let insertElement = `<div class='paginator-number ${ currentPage === i + 1 ? 'paginator-number__active' : ''}' data-page="${ i + 1 }">${ i + 1 }</div>`
                if (totalPage < 6) {
                    html += insertElement
                } else {
                    if (currentPage > 2 && currentPage < totalPage - 1) {
                        if ((currentPage - i) < 4 && (currentPage - i) > -2 )
                            html += insertElement
                    } else {
                        // eslint-disable-next-line
                        switch (currentPage) {
                            case 1:
                                if (i < 5) html += insertElement
                                break;
                            case 2:
                                if (i < 5) html += insertElement
                                break;
                            case totalPage - 1:
                                if (i >= totalPage - 5) html += insertElement
                                break;
                            case totalPage:
                                if (i >= totalPage - 5) html += insertElement
                                break;
                        }
                    }
                }
            }
            if (currentPage < totalPage - 3) html += `<div class='paginator-number paginator-number__svg'><svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div><div class='paginator-number' data-page="${ totalPage }">${ totalPage }</div>`
            area.innerHTML = html

            const pages = Array.from(document.querySelectorAll('[data-page]'))
            pages.forEach(page => page.addEventListener('click', () => {
                method(Number(page.dataset.page))
            }))
            
        }
    }
}