import { makeAutoObservable } from 'mobx';

class Menu {
    activeFirstMenu = 'orders';
    secondMenuIsShow = false;
    secondMenuShowBlocks = new Set();

    constructor() {
        makeAutoObservable(this);
    }

    chooseFirstMenu = (id) => {
        this.activeFirstMenu = id;
    }

    changeStateSecondMenu = () => {
        this.secondMenuIsShow = !this.secondMenuIsShow;
    }

    showSecondMenuBlock = (id) => {
        this.secondMenuShowBlocks.add(id);
    }

    hideSecondMenuBlock = (id) => {
        this.secondMenuShowBlocks.delete(id);
    }

}

const singltone = new Menu();
export default singltone;