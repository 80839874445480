import { makeAutoObservable } from 'mobx';

class ShopFilterStorage {

    render = 0
    status = null
    statusGroup = null
    name = null

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setStatus = (status) => {
        this.status = status
    }

    setName = (name) => {
        this.name = name
    }

    setStatusGroup = (status) => {
        this.statusGroup = status
    }
    
}

const data = new ShopFilterStorage();
export default data;