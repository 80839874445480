import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const OrdersFilterStatusPaymant = observer(({ selectChangeColor, data, change }) => {

    const [state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])


    return (

        <div className='filter-element' data-order-filter="5">
            <label htmlFor="status_payment">Статусы оплаты</label>
            <select id="status_payment" value={state !== null ? state : "all"} onChange={(e) => {
                    selectChangeColor()
                    setState(e.target.value)
                }}>
                <option value="all">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default OrdersFilterStatusPaymant;