import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const TableManagers = observer(({ orders, managers }) => {

    const navigate = useNavigate()
   
    const linkToUser = (id) => {
        navigate('/user/' + id);
    }
    const linkToManager = (id) => {
        navigate('/sales/managers/' + id);
    }

    useEffect(() => {
        const body = document.querySelector('#table-body')

        let html = ""
        if (managers.length > 0) {
            managers.forEach(element => {
                let countOrder = orders.filter(item => item.user.id === element.id).length
                let totalOrder = orders.filter(item => item.user.id === element.id).reduce((acc, item) => acc + item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0) - item.discount, 0)
                let averageOrder = totalOrder / countOrder;
                html += `
                    <tr data-manager="${element.id}">
                        <td class='table-users_space table-users-col-1'>
                            <div class='table-users_left-span'>
                                ${ element.photo !== null ? `<img class='table-users_img' src=${element.photo} alt="" />` : `<div class='table-users_img'></div>` }
                                <span><a src="#" id="link-user_${element.id}">${ element.surname !== null ? element.surname : '' } ${ element.username !== null ? element.username : ''} ${ element.patronymic !== null ? element.patronymic : '' }</a></span>
                            </div>
                            <span class=${
                                element.status === 1 ? 'table-users_status-1' : 
                                element.status === 2 ? 'table-users_status-2' : 
                                element.status === 3 ? 'table-users_status-3' : 'table-users_status-4'}>${ 
                                    element.status === 1 ? 'cвободен' : 
                                    element.status === 2 ? 'занят' : 
                                    element.status === 3 ? 'на обеде' : 'перерыв' }</span>
                        </td>
                        <td class='table-users_space table-users-col-2'>${element.email !== null ? element.email : ""}</td>
                        <td class='table-users_space table-users-col-3'>${element.jobTitle !== null ? element.jobTitle : ""}</td>
                        <td class='table-users_space table-users-col-4'>${ orders !== null ? countOrder : "-"}</td>
                        <td class='table-users_space table-users-col-5'>${ orders !== null ? totalOrder : "-"}</td>
                        <td class='table-users_space table-users-col-6'>${ orders !== null ? averageOrder : "-"}</td>
                `
            })
        } else {
            html += `<tr class="table-empty-data">
                        <td>Нет данных</td>
                        </tr>`
        }
            
        body.innerHTML = html;

        managers.forEach(element => {
            let link = document.querySelector('#link-user_' + element.id)
            if (link !== null)
                link.addEventListener('click', () => linkToUser(element.id))
        })

        const managersRow = document.querySelectorAll('[data-manager]')
        managersRow.forEach(element => {
            element.addEventListener('click', () => linkToManager(element.dataset.manager))
        })

    // eslint-disable-next-line
    }, [managers])

    return (
        <table className="Table">
            <thead id="table-head">
                <tr>
                    <th className='table-users-col-1'><span>ФИО</span></th>
                    <th className='table-users-col-2'><span>E-mail</span></th>
                    <th className='table-users-col-3'><span>Должность</span></th>
                    <th className='table-users-col-4'><span>Количество заказов</span></th>
                    <th className='table-users-col-5'><span>Средний чек</span></th>
                    <th className='table-users-col-6'><span>Сумма продаж</span></th>
                </tr>
            </thead>
            <tbody id="table-body"></tbody>
        </table>
    )
})

export default TableManagers;