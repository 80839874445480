import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import ContentBlock from '../../common/elements/content-block';
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';
import api from "../../../services/api"
import Input from './elements/input';
import Select from './elements/select';
import InputBoolean from './elements/input-boolean';
import Textarea from './elements/textarea';

const Warehouse = observer(() => {

    const [ typeWarehouse, setTypeWarehouse ] = useState(null)

    useEffect(() => {
        const getData = async () => {
            await api.post('orders/get-warehouse-type', {})
                .then(res => setTypeWarehouse(res.data))
                .catch(e => console.log(e))
        }
        
        getData();
    },[])

    return (
        <div>
            <div className="Order">
                <div className='order-top'>
                    <div className='order-links'>
                        <Link to={'/'}>Главная</Link>
                        <span>→</span>
                        <Link to={'/sales/warehouse'}>Склады</Link>
                    </div>
                    <div className='order-h1'>Новый склад</div>
                </div>
                <ContentBlock title="Основные" name="main" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <Input title="Название" name="full-name" />
                            <Input title="Символьный код" name="name" />
                            { typeWarehouse ? <Select data={ typeWarehouse } title="Тип" name="name" /> : '' }
                            <InputBoolean title="Активность" name="status" checked={true} />
                            <Textarea title="Описание" name="description" />
                        </div>
                    </div>
                }/>
                <ContentBlock title="Адрес" name="address" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <Input title="Индекс" name="post-code" />
                            <Input title="Страна" name="country" />
                            <Input title="Район" name="district" />
                            <Input title="Город" name="city" />
                            <Input title="Адрес" name="address" />
                            <Input title="Метро" name="subway" />
                        </div>
                    </div>
                }/>
                <ContentBlock title="Контакты" name="contacts" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <Input title="Контактное лицо" name="contact-person" />
                            <Input title="Телефон" name="phone" />
                            <Input title="E-mail" name="email" />
                        </div>
                    </div>
                }/>
            </div>
            <Foother contant={ 
                <div className="user-foother">
                    <ButtonSave text="Сохранить" method={ ()=> {  } }/>
                    <div id="user-save__success">Сохранено</div>
                    <div id="user-save__wrong">Ошибка сохранения</div>
                </div>
             } />
        </div>
    )
})

export default Warehouse;