import './GroupUser.css';
import React, { useEffect } from 'react'
import Head from "../../common/marking/head"
import ContantUser from "../../common/marking/contant-user"
import dataStorage from "../../../storage/data"
import api from "../../../services/api"
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import ButtonAdd from "../../common/elements/button-add"
import Table from "../../common/table/Table"

const GroupUser = observer(() => {

    const { roles, setRoles } = dataStorage;

    useEffect(() => {
        
        const getRoles = async () => {
            await api.post('user/get-roles', {})
                .then(result => {
                    let oldData = ''
                    roles.forEach(item => oldData += item[1] + item[0])
                    let newData = ''
                    result.data.roles.forEach(item => newData += item.name + item.fullName)
                    if (oldData !== newData) setRoles(result.data.roles.map(role => [role.fullName, role.name]))
                })
                .catch(e => console.log(e))
        }

        getRoles()

        // eslint-disable-next-line
    },[ roles ])

    return (
        <div className="GroupUser">
            <Head contant={ <div>
                    <span>Группы пользователей</span>
                    {/* <ButtonAdd method={() => {}}/> */}
                </div> }/>
            <ContantUser contant={ 
                <div className="marking-contant__body">
                    <Table  head={ ['Название', 'Символьный код'] }
                            body={ roles } />
                </div> 
            }/>
        </div>
    )
})

export default GroupUser;