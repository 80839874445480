import { observer } from "mobx-react-lite"
import "./elements.css"
import React, { useEffect, useState } from "react"

const Select = observer(({ data, lable, defaultValue, noMargin }) => {

    const [ state, setState ] = useState(null)

    useEffect(() => {
        
    }, [state])

    return (
        <div className={ noMargin !==null & noMargin !== undefined ? "input-label" : "input-label m-25"}>
            <label>{ lable }</label>
            <select value={ defaultValue !== null && defaultValue !== undefined ? defaultValue.id : state !== null ? state : "all" } onChange={e => setState(e.target.value)}>
                <option value="all">Выберете значение</option>
                {
                    data.map(item => <option key={Math.random()} value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
    )
})

export default Select