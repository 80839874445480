import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import orderStorage from "../../../storage/order"

const TableCardShopProvider = observer(({ goods, activeGoods, edit }) => {

    // eslint-disable-next-line
    const { goodsInCard, setGoodsInCardArray, render, setRender } = orderStorage;

    const calculate = (id) => {
        const count = document.querySelector('#count-id_' + id)
        const price = document.querySelector('#price-id_' + id)
        const total = document.querySelector('#total-id_' + id)
        total.innerHTML = count.value * price.innerHTML
        calculateTotal()
    }

    const calculateTotal = () => {
        const totalPrice = document.querySelector('#card-shop-total')

        const arrayPrices = document.querySelectorAll('[name="total-price"]')
        let total = 0;

        Array.from(arrayPrices).forEach(item => {
            total += Number(item.innerHTML)
        })

        totalPrice.innerHTML = total;
    }

    const deleteGoods = (id) => {
        setGoodsInCardArray(goodsInCard.filter(item => item.id !== id))
        setRender()
    }

    useEffect(() => {
        if (activeGoods !== null && activeGoods !== '' && activeGoods.length !== 0) {
            setGoodsInCardArray(activeGoods.map(item => item))
            setRender()
        }
    // eslint-disable-next-line
    }, [activeGoods])

    useEffect(() => {
        const body = document.querySelector('#table-body')
        let html = ``
        let count = 0;
        if (goodsInCard.length !== 0) {
            html += goodsInCard.map(item => {
                count += 1;
                let good = [];
                
                if (edit)
                    good = goods.filter(element => Number(element.id) === Number(item.goods.id))
                else
                    good = goods.filter(element => Number(element.id) === Number(item.id))

                return(
                    `<tr>
                        <td class='table-orders-col1'><span class="table-orders-delete" data-delete-goods="${good[0].id}"></span></td>
                        <td class='table-orders-col1'><span>${ count }</span></td>
                        <td class='table-orders-col2'><span>${ good[0].name }</span></td>
                        <td class='table-orders-col'><span>${ good[0].shops.name }</span></td>
                        <td class='table-orders-col'><span><input type="number" value="${ item.count !== null ? item.count : 1 }" id="count-id_${good[0].id}"/></span></td>
                        <td class='table-orders-col'><span id="price-id_${good[0].id}">${ good[0].price }</span></td>
                        <td class='table-orders-col'><span name="total-price" id="total-id_${good[0].id}">${ item.count !== null ? good[0].price * item.count : good[0].price }</span></td>
                    </tr>`
                )
            }).join('')
        } else {
            html = `<div class="table-orders-null">
                <div>В заказе еще нет товаров, добавьте их с помощью кнопки «Добавить товар» справа</div>
            </div>`
        }

        body.innerHTML = html
        calculateTotal();

        goodsInCard.forEach(item => {
            const goodInput = document.querySelector('#count-id_' + item.id)

            if (goodInput !== null) {
                goodInput.addEventListener('change', () => {
                    calculate(item.id)
                })
            }
        })
        const deleteButtons = document.querySelectorAll('[data-delete-goods]')
        deleteButtons.forEach(item => {
            item.addEventListener('click', () => deleteGoods(Number(item.dataset.deleteGoods)))
        })
    // eslint-disable-next-line
    }, [render])

    return (
        <div className='table-order-conteiner'>
            <table className="Table">
                <thead id="table-head">
                    <tr>
                        <th className='table-orders-col1'><span></span></th>
                        <th className='table-orders-col1'><span>№</span></th>
                        <th className='table-orders-col2'><span>Товар</span></th>
                        <th className='table-orders-col'><span>Магазин</span></th>
                        <th className='table-orders-col'><span>Количество</span></th>
                        <th className='table-orders-col'><span>Цена</span></th>
                        <th className='table-orders-col'><span>Стоимость</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
            <div id='table-bottom-head'></div>
            <div id='table-bottom'>
                    <div id='table-bottom-total'>
                        <div className='table-bottom-row'>
                            <div className='table-bottom-key'>Стоимость товаров</div>
                            <div className='table-bottom-value' id="card-shop-total">0</div>
                            <div className='table-bottom-currency'>₽</div>
                        </div>
                    </div>
                </div>
        </div>
    )
})

export default TableCardShopProvider;