import './Users.css';
import React, { useEffect, useState } from 'react'
import Head from "../../common/marking/head"
import Contant from "../../common/marking/contant"
import { observer } from 'mobx-react-lite';
import FilterButton from '../../common/elements/filter-button';
import ManagersFilter from '../../common/elements/filters/managers-filter';
import TableManagers from '../../common/table/TableManagers';
import { compareArray } from "../../common/elements/filters/service"
import managersStorage from '../../../storage/managers-filter'
import api from '../../../services/api'

const Users = observer(() => {

    const [ orders, setOrders ] = useState(null)
    const [ managers, setManagers ] = useState(null)

    const { 
        render,
        name,
        totalOrder,
        averageOrder,
        countOrder
    } = managersStorage;

    useEffect(() => {
        const getData = async () => {
            await api.post('/user/get-users', {})
                .then(res => {
                    let array = []
                    let nameData;
                    let countOrderData;
                    let totalOrderData;
                    let averageOrderData;

                    let managersArray = res.data.filter(item => item.roles.map(e => e.id).includes(1))

                    if (name !== null && name !== "") nameData = managersArray.filter(item => String(item.username).toLowerCase().includes(name.toLowerCase()) || String(item.surname).toLowerCase().includes(name.toLowerCase()) || String(item.patronymic).toLowerCase().includes(name.toLowerCase()))
                    else nameData = managersArray

                    if (countOrder !== null && (countOrder.min !== 0 && countOrder.max !== 0)) {
                        countOrderData = managersArray.filter(element => {
                            let countOrders = orders.filter(item => item.user.id === element.id).length
                            if (Number(countOrders) >= Number(countOrder.min) && Number(countOrders) <= Number(countOrder.max)) return true
                            else return false
                        });
                    } else countOrderData = managersArray

                    if (totalOrder !== null && (totalOrder.min !== 0 && totalOrder.max !== 0)) {
                        totalOrderData = managersArray.filter(element => {
                            let totalOrders = orders.filter(item => item.user.id === element.id)
                                .reduce((acc, item) => acc + item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0) - item.discount, 0)
                            if (Number(totalOrders) >= Number(totalOrder.min) && Number(totalOrders) <= Number(totalOrder.max)) return true
                            else return false
                        });
                    } else totalOrderData = managersArray

                    if (averageOrder !== null && (averageOrder.min !== 0 && averageOrder.max !== 0)) {
                        averageOrderData = managersArray.filter(element => {
                            let countOrders = orders.filter(item => item.user.id === element.id).length
                            let totalOrders = orders.filter(item => item.user.id === element.id)
                                .reduce((acc, item) => acc + item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0) - item.discount, 0)
                            let averageOrders = totalOrders / countOrders
                            
                            if (Number(averageOrders) >= Number(averageOrder.min) && Number(averageOrders) <= Number(averageOrder.max)) return true
                            else return false
                        });
                    } else averageOrderData = managersArray

                    array.push(nameData)
                    array.push(countOrderData)
                    array.push(totalOrderData)
                    array.push(averageOrderData)
                    
                    setManagers(compareArray(array))
                })
                .catch(e => console.log(e))

        }
        getData()
    // eslint-disable-next-line
    },[render])
    
    useEffect(() => {
        const getData = async () => {
            await api.post('/user/get-users', {})
                .then(res => setManagers(res.data.filter(item => item.roles.map(e => e.id).includes(1))))
                .catch(e => console.log(e))
            await api.post('/orders/get-orders', {})
                .then(res => setOrders(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (
        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Менеджеры</span>
                            <FilterButton />
                        </div>
                    </div>
                    <ManagersFilter />
                </div> }/>
            <Contant contant={ 
                <div className="marking-contant__body">
                    { managers !== null && orders !== null ? <TableManagers managers={ managers } orders={ orders }/> : "" }
                </div> 
            }/>
        </div>
    )
})

export default Users;