import "./elements.css"
import React from "react"

const ButtonAdd = ({ method }) => {

    return (
        <div className="button-add" onClick={ method }>Добавить</div>
    )
}

export default ButtonAdd