import "./marking.css";
import React from 'react';

const ContantCommon = ({ contant }) => {

    return (
        <div className="marking_contant__container">
            { contant }
        </div>
    )
}

export default ContantCommon;