import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const OrdersFilterManagers = observer(({ change }) => {

    const [ state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element'>
            <label htmlFor="status">Активность</label>
            <select id="status" onChange={(e) => setState(e.target.value)}>
                <option value="all">Все</option>
                <option value="true">Активные</option>
                <option value="false">Неактивные</option>
            </select>
        </div>
        
    )
})

export default OrdersFilterManagers;