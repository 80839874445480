import './Profile.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import BtnClose from "../../common/btn-close/btn-close";
import orderImg from "./img/order.svg";
import api from "../../../services/api";
import userStorage from "../../../storage/user";

import { Link } from "react-router-dom";
import { useEffect } from 'react';

const Profile = observer(({logout}) => {

    const { user, setUser } = userStorage;

    const close = () => {
        const container = document.querySelector('#container-profile')
        const contantContainerLeft = document.querySelector('.ContantContainer-left')

        contantContainerLeft.classList.remove('ContantContainer-left__active')
        container.classList.remove('Contant-left__active')
    }

    const changeStatusTo = (statusId) => {
        api.post('user/update-status', {
            id: user.id,
            status: statusId
          })
          .then(result => {
            setUser(result.data)
          })
          .catch(e => console.log(e))
    }

    useEffect(() => {
        const chooseStatus = () => {
            const statuses = document.querySelectorAll('.profile-contant__col')
            Array.from(statuses).forEach(item => [
                item.classList.remove('profile-contant__active')
            ])
    
            switch (user.status) {
                case 1:
                    statuses[0].classList.add('profile-contant__active')
                    break;
                case 2:
                    statuses[1].classList.add('profile-contant__active')
                    break;
                case 3:
                    statuses[2].classList.add('profile-contant__active')
                    break;
                case 4:
                    statuses[3].classList.add('profile-contant__active')
                    break;
                default:
                    break;
            }
    }

        if (user.status !== 0) chooseStatus()
    }, [user] )

    return (
        <div className="Profile">
            <div className="profile-top">
                <div className='profile-top__text'>Профиль пользователя</div>
                <BtnClose logout={ logout }></BtnClose>
            </div>
            <div className='profile-contant'>
                <div className='profile-contant__top'>
                    <div className='profile-img'><img src={ user.photo } alt='' /></div>
                    <div className='profile-text'>
                        <h5>{ user.username }</h5>
                        <span>{ user.email }</span>
                    </div>
                </div>
                <div className='profile-contant__status'>
                    <div className='profile-contant__col profile-contant__col-1' onClick={() => changeStatusTo(1)}>Свободен</div>
                    <div className='profile-contant__col profile-contant__col-2' onClick={() => changeStatusTo(2)}>Занят</div>
                    <div className='profile-contant__col profile-contant__col-3' onClick={() => changeStatusTo(3)}>На обеде</div>
                    <div className='profile-contant__col profile-contant__col-4' onClick={() => changeStatusTo(4)}>Перерыв</div>
                </div>
            </div>
            <div className='profile-foother'>
                <div className='profile-foother__container profile-foother__container-left'>
                    <div>
                            <svg className="MenuFirstElement__icon">
                                <use href={orderImg + '#settings_outlined'}></use>
                            </svg>
                            <Link to="/user" className="profile-foother__container-link" onClick={() => close()}>Настройки профиля</Link>
                    </div>
                </div>
                <div className='profile-foother__container profile-foother__container-right profile-foother__container-end'>
                    <div onClick={logout}>
                        <svg className="MenuFirstElement__icon">
                            <use href={orderImg + '#sign_out'}></use>
                        </svg>
                        <span>Выход</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Profile;