import './orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import OrdersFilter from '../../common/elements/filters/orders-filter';
import TableOrders from '../../common/table/TableOrders';
import ButtonAdd from '../../common/elements/button-add';
import { useNavigate } from 'react-router-dom';
import api from "../../../services/api"
import orderStorage from "../../../storage/order";
import ordersFilterStorage from "../../../storage/orders-filter"
import { compareArray } from "../../common/elements/filters/service"


const Orders = observer(() => {

    const [ orders, setOrders ] = useState(null)
    const [ dictinary, setDictinary ] = useState([]);

    const { clearGoodsInCard } = orderStorage;
    const { render,
            id, 
            orderType, 
            // eslint-disable-next-line
            customer, 
            manager, 
            // eslint-disable-next-line
            paymentStatus, 
            // eslint-disable-next-line
            goods,
            shops,
            // eslint-disable-next-line
            paymentType,
            // eslint-disable-next-line
            deliveryType } = ordersFilterStorage;

    const navigator = useNavigate()

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-orders', {})
            .then(res => {
                let array = []
                let idData;
                let orderTypeData;
                // eslint-disable-next-line
                let customerData;
                let managerData;
                // eslint-disable-next-line
                let paymentStatusData;
                // eslint-disable-next-line
                let goodsData;
                let shopsData;
                // eslint-disable-next-line
                let paymentTypeData;
                // eslint-disable-next-line
                let deliveryTypeData;

                if (id !== null && id !== "") idData = res.data.filter(item => String(item.id) === id)
                else idData = res.data

                if (orderType !== null && orderType !== "all") orderTypeData = res.data.filter(item => String(item.typeOrder.id) === orderType)
                else orderTypeData = res.data

                if (manager !== null && manager !== "all") managerData = res.data.filter(item => String(item.user.id) === manager)
                else managerData = res.data

                if (shops !== null && shops !== "all") shopsData = res.data.filter(item => String(item.shops.id) === shops)
                else shopsData = res.data

                if (customer !== null && customer !== "") customerData = res.data.filter(item =>    String(item.customers.firstname).toLowerCase().includes(customer.toLowerCase()) || 
                                                                                                    String(item.customers.surname).toLowerCase().includes(customer.toLowerCase()) || 
                                                                                                    String(item.customers.patronymic).toLowerCase().includes(customer.toLowerCase()) ||
                                                                                                    String(item.customers.email).toLowerCase().includes(customer.toLowerCase()) ||
                                                                                                    String(item.customers.phone).toLowerCase().includes(customer.toLowerCase()))
                else customerData = res.data

                if (goods !== null && goods !== "") {
                    goodsData = res.data.filter(item => {
                        if (item.shopCard.filter(el =>  String(el.goods.name).toLowerCase().includes(goods.toLowerCase()) || 
                                                                                                                    String(el.goods.description).toLowerCase().includes(goods.toLowerCase()) || 
                                                                                                                    String(el.goods.vendorCode).toLowerCase().includes(goods.toLowerCase()) ||
                                                                                                                    String(el.goods.barcode).toLowerCase().includes(goods.toLowerCase())).length > 0) return true;
                        else return false
                    })
                } else goodsData = res.data

                array.push(idData)
                array.push(orderTypeData)
                array.push(managerData)
                array.push(shopsData)
                array.push(customerData)
                // array.push(paymentStatusData)
                array.push(goodsData)
                // array.push(paymentTypeData)
                // array.push(deliveryTypeData)

                setOrders(compareArray(array))
            })
            .catch(e => console.log(e))

            await api.post('/orders/get-order-status', {})
                .then(res => setDictinary(res.data.map(e => e.name)))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[render])

    return (
        <div className="GroupUser">
            {   orders === null && dictinary.length === 0 ? <div className='preloader-block'><div className='loader'></div></div> :
                <div>
                    <Head contant={ 
                        <div className='head-container'>
                            <div className='head-first-line'>
                                <div>
                                    <span>Заказы</span>
                                    <FilterButton />
                                </div>
                                <ButtonAdd method={() => {
                                    clearGoodsInCard();
                                    navigator('/sales/orders/new')
                                }}/>
                            </div>
                            <OrdersFilter />
                        </div> }/>
                    <Contant contant={ 
                        <div className="marking-contant__body">
                            { orders !== null ? <TableOrders orders={ orders } dictinary={ dictinary }/> : '' }
                        </div> 
                    }/>
                </div>
            }
        </div>
    )
})

export default Orders;