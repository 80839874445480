import { observer } from "mobx-react-lite"
import "./elements.css"
import React, { useEffect, useState } from "react"
import userStorage from "../../../storage/user"

const InputCheckbox = observer(({ lableText, inputId, data, settingsName, user }) => {

    const { setUserSettings } = userStorage;
    const [ dataArray, setDataArray ] = useState( user.roles.map(role => {
        if ( role.name ) return role.name
        else return role
    }) )

    const handleChange = async (e) => {
        await dataArray.includes(e.target.name)
            ? setDataArray(dataArray.filter(x => x !== e.target.name))
            : setDataArray([...dataArray, e.target.name])
        }

    useEffect(() => {
        setUserSettings(settingsName, dataArray)
    // eslint-disable-next-line
    },[ dataArray ])

    return (
        <div className="input-label">
            <label>{ lableText }</label>
                <div className="checkbox-box">
                    {
                        data.map(item => {
                            return  <div className="checkbox-element" key={ Math.random() }>
                                        <input  type="checkbox"
                                                name={ item.name }
                                                id={ inputId }
                                                checked={ dataArray.includes(item.name) }
                                                onChange={ e => handleChange(e)}
                                        />
                                        <label>{ item.fullName }</label>
                                    </div>
                        })
                    }
                </div>
        </div>
    )
})

export default InputCheckbox