import './orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import SelectType2 from './elements/select-type-2';
import InputType2 from './elements/input-type-2';
import Checkbox from './elements/checkbox';
import orderStorage from "../../../storage/order";
import goodsStorage from "../../../storage/goods-filter"
// eslint-disable-next-line
import { compareArray } from "../../common/elements/filters/service"
import InputBtn from "./elements/input-btn"
import FootherIn from '../../common/marking/foother-in';
import ButtonSave from '../../common/elements/button-save';

const CardShop = observer(({warehouseFilter, shopsFilter, goodsGroupFilter, goodsTagFilter, goodsFilter}) => {

    // eslint-disable-next-line
    const { goodsInCard, setGoodsInCard, render, setRender } = orderStorage;
    // eslint-disable-next-line
    const { shop, setShop, group, setGroup, warehouse, setWarehouse, manufacture, setManufacture, goodTag, setGoodTag, nameGood, setNameGood } = goodsStorage;
    // eslint-disable-next-line
    const [goods, setGoods] = useState(null)

    const hideGoods = () => {
        const area = document.querySelector('#card-shop')
        area.classList.remove('card-shop__active')
        setRender();
    }

    const chooseGood = (id) => {
        if (goodsInCard.filter(item => item.id === id).length < 1) {
            setGoodsInCard({
                id: id,
                count: 1
            })
            const good = document.querySelector(`#goods-id_${id}`)
            good.innerHTML = `<div class='card-shop-right-element-td card-shop-right-element-td_choosen'>Товар добавлен</div>`
        }
    }

    const applyFilter = () => {

        let array = []
        let shopData;
        let groupData;
        let manufactureData;
        let nameData;
        let goodsTagData;
        
        if (nameGood !== null && nameGood !== "") nameData = goodsFilter.filter(item => String(item.name).toLowerCase().includes(String(nameGood).toLowerCase()))
        else nameData = goodsFilter

        if (shop !== null && shop !== "all") shopData = goodsFilter.filter(item => item.shops.id === Number(shop))
        else shopData = goodsFilter

        if (group !== null && group !== "all") groupData = goodsFilter.filter(item => item.groupGoods.id === Number(group))
        else groupData = goodsFilter

        if (manufacture !== null && manufacture !== "") manufactureData = goodsFilter.filter(item => item.manufacture === manufacture)
        else manufactureData = goodsFilter

        if (goodTag !== null && goodTag.length !== 0) 
            goodsTagData = goodsFilter.filter(good => {
                if (good.goodsTag.length > 0) {
                    let isValid = true;
                    goodTag.forEach(tag => {
                        if(!good.goodsTag.map(e => e.id).includes(tag)) 
                            isValid = false;
                    })
                    return isValid
                } else {
                    return false
                }
        })
        else goodsTagData = goodsFilter

        array.push(shopData)
        array.push(groupData)
        array.push(manufactureData)
        array.push(nameData)
        array.push(goodsTagData)

        setGoods(compareArray(array))
    }
    
    useEffect(() => {
        setGoods(goodsFilter)
    // eslint-disable-next-line
    }, [goodsFilter])

    return (

        <div className='card-shop' id="card-shop">
            <div className='card-shop-container '>
                <div className='card-shop-left'>
                    <div>
                        { shopsFilter !== null ? <SelectType2 title="Магазин" name="shop" data={ shopsFilter } choose={setShop}/> : '' }
                        { goodsGroupFilter !== null ? <SelectType2 title="Группа" name="group-goods" data={ goodsGroupFilter } choose={setGroup}/> : '' }
                        {/* { warehouseFilter !== null ? <SelectType2 title="Склад" name="warehouse" data={ warehouseFilter } choose={setWarehouse}/> : '' } */}
                        <InputType2 title="Производитель" name="manufacture" choose={setManufacture}/>
                        { goodsTagFilter !== null ? <Checkbox data={ goodsTagFilter } choose={setGoodTag}/> : '' }
                    </div>
                    <FootherIn contant={ 
                        <div className="user-foother">
                            <ButtonSave text="Применить" method={ applyFilter }/>
                            <div id="user-save__success">Сохранено</div>
                            <div id="user-save__wrong">Ошибка сохранения</div>
                        </div>
                    } />
                </div>
                <div className='card-shop-right'>
                    <div className='card-shop-right-top'>
                        <div className='card-shop-right-top-h1'>Добавление товаров</div>
                        <InputBtn choose={ setNameGood } apply={ applyFilter } placeholder="Название"/>
                        <div className='card-shop-right-top-close' onClick={() => hideGoods()}></div>
                    </div>
                    <div className='card-shop-right-content'>
                        {
                            goods !== null ?
                            goods.map(item => {
                                
                                return(
                                    <div className='card-shop-right-element' key={Math.random()} id={`goods-id_${item.id}`} onClick={() => chooseGood(item.id)}>
                                        { !goodsInCard.map(item => item.id).includes(item.id) ? <div className='card-shop-right-element-td'>{ item.name }</div> : <div className='card-shop-right-element-td card-shop-right-element-td_choosen'>Товар добавлен</div> }
                                        { !goodsInCard.map(item => item.id).includes(item.id) ? <div className='card-shop-right-element-td'>{ item.manufacture !== null ? item.manufacture : '-' }</div> : '' }
                                        { !goodsInCard.map(item => item.id).includes(item.id) ? <div className='card-shop-right-element-td'>{ item.price }</div> : '' }
                                    </div>
                                )
                            }) :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
        
    )
})

export default CardShop;