import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Toaster } from 'react-hot-toast';

import Login from "./components/common/login/Login";
import MenuFirst from "./components/menu/MenuFirst/MenuFirst";
import MenuSecond from "./components/menu/MenuSecond/MenuSecond";
import ContantContainer from "./components/common/contant-container/ContantContainer";
import FixContainer from "./components/common/contant-container/FixContainer";
import User from "./components/contents/User/User"
import UserID from "./components/contents/User/UserID"

import GroupUser from "./components/contents/GroupUser/GroupUser"
import Users from "./components/contents/Users/Users"
import Orders from "./components/contents/Orders/Orders"
import OrderEdit from "./components/contents/Orders/OrderEdit"
import ShopsSetting from "./components/contents/Setting/ShopSetting"

import { logout } from "./actions/auth";

import EventBus from "./common/EventBus";
import PasswordPage from "./components/contents/User/PasswordPage";
import EmailPage from "./components/contents/User/EmailPage";
import WarehouseSetting from "./components/contents/Setting/WarehouseSetting";
import Warehouse from "./components/contents/Setting/Warehouse";
import Shop from "./components/contents/Setting/Shop";
import OrderTypesSetting from "./components/contents/Setting/OrderTypesSetting";
import LeadTypes from "./components/contents/Setting/LeadTypes";
import DeliveryTypes from "./components/contents/Setting/DeliveryTypes";
import PaymentTypes from "./components/contents/Setting/PaymentTypes";
import PaymentStatus from "./components/contents/Setting/PaymentStatus";
import Statuses from "./components/contents/Setting/Statuses";
import StatusesGroup from "./components/contents/Setting/StatusesGroup";
import StatusStep from "./components/contents/Setting/StatusStep";
import Customer from "./components/contents/Customer/Customers";
import CustomerProfile from './components/contents/Customer/CustomerEdit'
import CustomersNew from './components/contents/Customer/CustomersNew'
import Managers from './components/contents/Users/Managers'
import Manager from './components/contents/Users/Manager'
import Products from "./components/contents/Products/Products";
import ProductNew from "./components/contents/Products/ProductNew";
import ProductGroupNew from "./components/contents/ProductsGroup/ProductGroupNew";
import ProductsGroup from "./components/contents/ProductsGroup/ProductsGroup";
import ShipmentPickup from "./components/contents/ShipmentPickup/ShipmentPickup";
import ProductsSaldo from "./components/contents/ProductsSaldo/ProductsSaldo";
import GoodsReceipt from "./components/contents/GoodsReceipt/GoodsReceipt";
import GoodsReceiptNew from "./components/contents/GoodsReceipt/GoodsReceiptNew";
import Provider from "./components/contents/Provider/Provider";
import ProviderNew from "./components/contents/Provider/ProviderNew";
import ShipmentDelivery from "./components/contents/ShipmentDevivery/ShipmentDelivery";
import SupplyOrder from "./components/contents/SupplyOrder/SupplyOrder";
import CommonSetting from "./components/contents/Setting/Common";
import GoodsReservation from "./components/contents/GoodsReservation/GoodsReservation";
import Popup from "./components/elements/popup/Popup";
import PopupGood from "./components/elements/popup/PopupGood";
import { Document } from "./printing/Document";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    // history.listen((location) => {
    //   props.dispatch(clearMessage()); // clear message when changing location
    // });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    // eslint-disable-next-line
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      
      <BrowserRouter>
        <Toaster />
        { currentUser ? (
          <div className="container">
            <MenuFirst user={currentUser}/>
            <MenuSecond />
              <Routes>
                <Route path='/' element={<ContantContainer location="main" contant={ <Orders /> } logout={this.logOut} user={currentUser} />} />
                {/* <Route path='/' element={<InvoiceDocument />} /> */}
                
                <Route exact path="/login" component={Login} />
                { /* sales group */ }
                <Route path='/sales/orders' element={<ContantContainer location="main" contant={ <Orders /> } logout={this.logOut} user={ currentUser }/>} />
                <Route path='/sales/order/:id' element={<ContantContainer location="main" contant={ <OrderEdit /> } logout={this.logOut} user={ currentUser }/>} />
                <Route path='/sales/orders/new' element={<ContantContainer location="main" contant={ <OrderEdit /> } logout={this.logOut} user={ currentUser }/>} />
                <Route path='/sales/order/invoice/:id' element={ <Document type='invoice' /> } logout={this.logOut} user={ currentUser }/>
                <Route path='/sales/order/torg12/:id' element={ <Document type='torg12' />} logout={this.logOut} user={ currentUser }/>

                <Route path='/sales/customers' element={<ContantContainer location="main" contant={ <Customer /> } logout={this.logOut} user={ currentUser } />} />
                <Route path='/sales/customers/:id' element={<ContantContainer location="main" contant={ <CustomerProfile /> } logout={this.logOut} user={ currentUser } />} />
                <Route path='/sales/customers/new' element={<ContantContainer location="main" contant={ <CustomersNew /> } logout={this.logOut} user={ currentUser } />} />
                {/* <Route path='/sales/customers-corporate' element={<ContantContainer location="main" contant="Контент Корпоративные" logout={this.logOut} user={ currentUser } />} />
                <Route path='/sales/loyalty' element={<ContantContainer location="main" contant="Контент Лояльность" logout={this.logOut} user={ currentUser } />} />
                <Route path='/sales/letters' element={<ContantContainer location="main" contant="Контент Письма" logout={this.logOut} user={ currentUser } />} />
                <Route path='/sales/sms' element={<ContantContainer location="main" contant="Контент СМС" logout={this.logOut} user={currentUser} />} /> */}
                <Route path='/sales/products' element={<ContantContainer location="main" contant={ <Products /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/products/new' element={<ContantContainer location="main" contant={ <ProductNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/products/import' element={<ContantContainer location="main" contant={ <PopupGood /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/products/:id' element={<ContantContainer location="main" contant={ <ProductNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/product-groups' element={<ContantContainer location="main" contant={ <ProductsGroup /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/product-groups/new' element={<ContantContainer location="main" contant={ <ProductGroupNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/product-groups/:id' element={<ContantContainer location="main" contant={ <ProductGroupNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/product-groups/import' element={<Popup location="main" contant={ <ProductsGroup /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/shipments' element={<ContantContainer location="main" contant={ <ShipmentDelivery /> } logout={this.logOut} user={currentUser} />} />
                
                <Route path='/goods/provider' element={<ContantContainer location="main" contant={ <Provider /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/goods/provider/new' element={<ContantContainer location="main" contant={ <ProviderNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/goods/provider/:id' element={<ContantContainer location="main" contant={ <ProviderNew /> } logout={this.logOut} user={currentUser} />} />

                <Route path='/goods/receipt' element={<ContantContainer location="main" contant={ <GoodsReceipt /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/goods/receipt/new' element={<ContantContainer location="main" contant={ <GoodsReceiptNew /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/goods/receipt/:id' element={<ContantContainer location="main" contant={ <GoodsReceiptNew /> } logout={this.logOut} user={currentUser} />} />

                <Route path='/goods/reservation' element={<ContantContainer location="main" contant={ <GoodsReservation /> } logout={this.logOut} user={currentUser} />} />

                <Route path='/sales/supply' element={<ContantContainer location="main" contant={ <SupplyOrder /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/moving' element={<ContantContainer location="main" contant={ <ShipmentPickup /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/movements' element={<ContantContainer location="main" contant="Контент Перемещения" logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/inventories' element={<ContantContainer location="main" contant="Контент Инвентаризация" logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/saldo' element={<ContantContainer location="main" contant={ <ProductsSaldo /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/managers' element={<ContantContainer location="main" contant={ <Managers /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/managers/:id' element={<ContantContainer location="main" contant={ <Manager /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/sales/costs' element={<ContantContainer location="main" contant="Контент Финансы" logout={this.logOut} user={currentUser} />} />
                { /* analitics group page */ }
                <Route path='/analytics/orders' element={<ContantContainer location="main" contant="Контент Аналитика Заказы" logout={this.logOut} user={currentUser} />} />
                <Route path='/analytics/customers' element={<ContantContainer location="main" contant="Контент Аналитика Клиенты" logout={this.logOut} user={currentUser} />} />
                <Route path='/analytics/products' element={<ContantContainer location="main" contant="Контент Аналитика Виджеты" logout={this.logOut} user={currentUser} />} />
                <Route path='/analytics/report' element={<ContantContainer location="main" contant="Контент Аналитика Отчеты по продажам" logout={this.logOut} user={currentUser} />} />
                { /* settings group */ }
                <Route path='/settings/users' element={<ContantContainer location="main" contant={ <Users /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/group' element={<ContantContainer location="main" contant={ <GroupUser /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/shops' element={<ContantContainer location="main" contant={ <ShopsSetting /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/shops/new' element={<ContantContainer location="main" contant={ <Shop /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/warehouse' element={<ContantContainer location="main" contant={ <WarehouseSetting /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/warehouse/new' element={<ContantContainer location="main" contant={ <Warehouse /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/order-types' element={<ContantContainer location="main" contant={ <OrderTypesSetting /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/lead-types' element={<ContantContainer location="main" contant={ <LeadTypes /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/delivery-types' element={<ContantContainer location="main" contant={ <DeliveryTypes /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/payment-types' element={<ContantContainer location="main" contant={ <PaymentTypes /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/payment-status' element={<ContantContainer location="main" contant={ <PaymentStatus /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/order-status' element={<ContantContainer location="main" contant={ <Statuses /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/order-status-group' element={<ContantContainer location="main" contant={ <StatusesGroup /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/order-status-way' element={<ContantContainer location="main" contant={ <StatusStep /> } logout={this.logOut} user={currentUser} />} />
                <Route path='/settings/common' element={<ContantContainer location="main" contant={ <CommonSetting /> } logout={this.logOut} user={currentUser} />} />

        
                { /* other pages */ }

                <Route path='/tasks' element={<ContantContainer location="main" contant="Контент Задачи" logout={this.logOut} user={currentUser} />} />
                <Route path='/chat' element={<ContantContainer location="main" contant="Контент Чат" logout={this.logOut} user={currentUser} />} />
                <Route path='/mail' element={<ContantContainer location="main" contant="Контент email" logout={this.logOut} user={currentUser} />} />
                <Route path='/chinaOrders' element={<ContantContainer location="main" contant="Контент Заказы из Китая" logout={this.logOut} user={currentUser} />} />
        
                <Route path='/notification' element={<ContantContainer location="left" contant="" logout={this.logOut} user={currentUser} />} />
                <Route path='/request' element={<ContantContainer location="left" contant="" logout={this.logOut} user={currentUser} />} />
                <Route path='/profile' element={<ContantContainer location="left" contant="123" logout={this.logOut} user={currentUser} />} />

                <Route exact path='/password/:id' element={<FixContainer contant={ <PasswordPage /> } logout={this.logOut} user={currentUser} />} />
                <Route exact path='/user' element={<ContantContainer contant={ <User/> } logout={this.logOut} user={currentUser} />} />
                <Route exact path='/email' element={<ContantContainer contant={ <EmailPage/> } logout={this.logOut} user={currentUser} />} />

                <Route exact path='/user/:id' element={<ContantContainer contant={ <UserID/> } logout={this.logOut} user={currentUser} />} />

            </Routes>
            </div>
          ) : (
            <Login/>
          )
        }
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
