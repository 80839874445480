import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Head from '../../common/marking/head';
import ContantCommon from '../../common/marking/contant-common';
import api from "../../../services/api"
import TableStatusStepSetting from '../../common/table/TableStatusStepSetting'
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';

const StatusStep = observer(() => {

    const [ statusStep, setStatusStep ] = useState(null)
    const [ orderType, setOrderType ] = useState(null)
    const [ steps, setSteps ] = useState([])

    useEffect(() => {

        const getData = async () => {
            await api.post('/orders/get-status-step-table', {})
                .then(res => {
                    setStatusStep(res.data)
                })
                .catch(e => console.log(e))
            await api.post('/orders/get-type-order', {})
                .then(res => {
                    setOrderType(res.data)
                })
                .catch(e => console.log(e))
        }

        getData()
    // eslint-disable-next-line
    },[])

    return (
        <div className="Component">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Переходы статусов</span>
                        </div>
                    </div>
                </div> }/>
                <ContantCommon contant={ 
                    <div className="marking_contant__common">
                        { orderType !== null && statusStep !== null ? <TableStatusStepSetting statusStep={ statusStep } orderType={ orderType } method={ setSteps } /> : '' }
                    </div> 
                }/>
                <Foother contant={
                <div className="user-foother">
                    <ButtonSave text="Сохранить" method={ ()=> { console.log(steps) } }/>
                    <div id="user-save__success">Сохранено</div>
                    <div id="user-save__wrong">Ошибка сохранения</div>
                </div>
            }/>
        </div>
    )
})

export default StatusStep;