import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const TableCustomers = observer(({ users, orders }) => {

    const navigate = useNavigate()

    const linkToCustomer = (id) => {
        navigate('/sales/customers/' + id)
    }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (users.length > 0) {
                users.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id > b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    let ordersFiltered = orders.filter(item => {
                        if(element.customers !== null)
                            return String(item.customers.id) === String(element.id)
                        return false
                    })
                    let ordersCount = ordersFiltered.length
                    // let ordersTotal = ordersFiltered.shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0)
                    let ordersTotal = ordersFiltered.length === 0 ? 0 : ordersFiltered[0].shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0) - ordersFiltered[0].discount;
                    let averagePrice = ordersCount !== 0 ? ordersTotal / ordersCount : 0;
                    html += `
                        <tr id="link-to_${element.id}" data-customer="${element.id}">
                            <td class='table-orders-col'>${ element.firstname !== null ? element.firstname : "" } ${ element.surname !== null ? element.surname : "" } ${ element.patronymic !== null ? element.patronymic : "" }</td>
                            <td class='table-orders-col'>${ new Date(element.registration).toLocaleDateString() }</td>
                            <td class='table-orders-col'>${ element.email !== null ? element.email : "" }</td>
                            <td class='table-orders-col'>${ element.phone !== null ? element.phone : "" }</td>
                            <td class='table-orders-col'>${ element.users !== null ? element.surname !== null ? `${element.users.username} ${element.users.surname}` : element.users.username : "" }</td>
                            <td class='table-orders-col'>${ ordersCount }</td>
                            <td class='table-orders-col'>${ ordersTotal }</td>
                            <td class='table-orders-col'>${ averagePrice }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            const customers = document.querySelectorAll('[data-customer]')
            customers.forEach(item => item.addEventListener('click', () => {
                linkToCustomer(item.dataset.customer)
            }))

    // eslint-disable-next-line
    }, [users])

    return (
        <div className='table-box'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-orders-col'><span>ФИО</span></th>
                        <th className='table-orders-col'><span>Дата регистрации</span></th>
                        <th className='table-orders-col'><span>Email</span></th>
                        <th className='table-orders-col'><span>Телефон</span></th>
                        <th className='table-orders-col'><span>Менеджер</span></th>
                        <th className='table-orders-col'><span>Количество заказов</span></th>
                        <th className='table-orders-col'><span>Средний чек</span></th>
                        <th className='table-orders-col'><span>Сумма заказов</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
)
})

export default TableCustomers;