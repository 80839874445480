import { makeAutoObservable } from 'mobx';

class StatusStepStorage {
    orderTypeStep = null;
    render = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setOrderTypeStep = (type) => {
        this.orderTypeStep = type;
    }

    setRender = () => {
        this.reloader++;
    }
}

const data = new StatusStepStorage();
export default data;