import './products.css';
import '../element.css';
import React from 'react'
import ProductNewIcon from './img/product-new.png'

const ProductImagesBlock = () => {

    return (
        <div className='product-images-block'>
            <div className='product-images-block__h1'>Например — основное, под разными углами, крупным планом и при использовании</div>
            <div className='product-images-block__area'>
                <label for="product-images-block__input" className='product-images-block__label'>
                    <img className="product-images-block-lable__img" src={ProductNewIcon} alt=''/>
                    <div className="product-images-block-lable__h1">Выберете файл</div>
                    <div className="product-images-block-lable__h2">PNG, JPG, SVG, WEBP, не более 3 МБ</div>
                </label>
                <input className='product-images-block__input' id="product-images-block__input" type='file'/>
            </div>
        </div>      
    )
}

export default ProductImagesBlock;