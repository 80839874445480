import './Users.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import api from "../../../services/api"
import Row from '../../common/marking/row';

const CustomerEdit = observer(() => {

    const [ manager, setManager ] = useState(null)
    const [ linkState, setLinkState ] = useState('')
    const [ table, setTable ] = useState(null)
    const [ customers, setCustomers ] = useState(null)
    const [ orders, setOrders ] = useState(null)

    const { id } = useParams();

    const chooseDataTable = (data) => {
        if (data === 'orders') {
            console.log(orders)
            setLinkState('orders')
            setTable(
                <div>{ orders.map(item => {
                    return (
                        <div key={Math.random()} className='manager-order-container'>
                            <Link className='manager-order-container__link' to={'/sales/order/' + item.id}>Заказ #{ item.id }</Link>
                            <div className='manager-order-container__element'>{ new Date(item.dateCreated).toLocaleDateString() }</div>
                            { 
                                item.customers !== null ? 
                                    <Link className='manager-order-container__link' to={'/sales/customers/' + item.customers.id}>{ item.customers.firstname }</Link> 
                                : "" }
                            <div className='manager-order-container__element' style={{background: `#${item.orderStatus.statusGroup.backgroundColor}`, color: `#${item.orderStatus.statusGroup.color}`, borderRadius: '4px'}}>{ item.orderStatus.name }</div>
                            <div className='manager-order-container__element'>{ item.shopCard.reduce((acc, item) => acc + (item.count * item.goods.price), 0) - item.discount } ₽</div>
                        </div>
                    )})}
                </div>
            )
                
        } else {
            console.log(customers)
            setLinkState('customers')
            setTable(
                <div>{ customers.map(item => {
                    return (
                        <div key={Math.random()} className='manager-order-container'>
                            <Link className='manager-order-container__link' to={'/sales/customers/' + item.id}>{ item.firstname } { item.surname !== null ? item.surname : "" } { item.patronymic !== null ? item.patronymic : "" }</Link>
                        </div>
                    )})}
                </div>
            )
        }
    }

    useEffect(() => {
        const getManager = async () => {
            let data = new FormData()
            data.append('id', Number(id))

            await api.post('/user/get-user', data)
                .then(res => setManager(res.data))
                .catch(e => console.log(e))
        }
        getManager()
    // eslint-disable-next-line
    }, [linkState])

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-orders', {})
                .then(res => setOrders(res.data.filter(item => {
                    if (item.user !== null) {
                        if (Number(item.user.id) === Number(id)) return true
                        else return false
                    } else return false
                })))
                .catch(e => console.log(e))

            await api.post('/customer/get-customers', {})
                .then(res => setCustomers(res.data.filter(item => {
                    if (item.users !== null) {
                        if (Number(item.users.id) === Number(id)) return true
                        else return false
                    } else return false
                })))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    }, [])

    return (
        <div className='GroupUser'>
            { manager === null ? <div className='preloader-block'><div className='loader'></div></div> :
                <div className='head-container'>
                    <div className="Order">
                        <div className='order-top'>
                            <div className='order-links'>
                                <Link to={'/'}>Главная</Link>
                                <span>→</span>
                                <Link to={'/sales/managers'}>Список менеджеров</Link>
                            </div>
                            <div className='order-h1-btn'>
                                <span className='customers-column customers-column__right'>
                                    { manager.username !== null ? manager.username : "" } { manager.surname !== null ? manager.surname : "" } { manager.patronymic !== null ? manager.patronymic : "" }
                                </span>
                            </div>
                        </div>

                        <div className="manager-column__left">

                            <div className="CustomerContainer">
                                <div className="customer-container">
                                    <div className="customer-container__lable"><div>ОСНОВНЫЕ ДАННЫЕ</div></div>
                                    <Row contant={ 
                                        <div>
                                            <div className='profile-contant__top profile-contant__mb25'>
                                                <div className='profile-img'><img src={ manager.photo } alt='' /></div>
                                                <div className='profile-text-customer'>
                                                    <h5>{ manager.surname !== null ? manager.surname : "" } { manager.username !== null ? manager.username : "" } { manager.patronymic !== null ? manager.patronymic : "" }</h5>
                                                </div>
                                            </div>
                                            <div className='manager-bottom'>
                                                <span className='manager-bottom__left'>Телефон: </span>
                                                <span className='manager-bottom__right'>{ manager !== null && manager.phone !== null ? manager.phone : "" }</span>
                                                <span className='manager-bottom__left'>Email: </span>
                                                <span className='manager-bottom__right'>{ manager !== null && manager.email !== null ? manager.email : "" }</span>
                                                <span className='manager-bottom__left'>Дата регистрации: </span>
                                                <span className='manager-bottom__right'>{ new Date(manager.dateCreated).toLocaleDateString() }</span>
                                            </div>
                                        </div>
                                    } />
                                </div>
                            </div>

                            <div className="CustomerContainer">
                                <div className="customer-container">
                                    <div className="customer-container__lable"><div>ПОСЛЕДНЯЯ АКТИВНОСТЬ</div></div>
                                    <Row contant={ 
                                        <div>
                                            <div className='profile-contant__top'>
                                                <div className={ linkState === 'orders' ? 'manager-link-activity manager-link-activity__active' : 'manager-link-activity' } data-manager-link="orders" onClick={ e => chooseDataTable(e.target.dataset.managerLink) }>Заказы</div>
                                                <div className={ linkState === 'customers' ? 'manager-link-activity manager-link-activity__active' : 'manager-link-activity' } data-manager-link="customers" onClick={ e => chooseDataTable(e.target.dataset.managerLink) }>Клиенты</div>
                                            </div>
                                            <div className='manager-bottom__activity'>
                                                { table !=null ? table : "" }
                                            </div>
                                        </div>
                                    } />
                                </div>
                            </div>

                        </div>

                        <div className="manager-column__right">

                            <div className="CustomerContainer">
                                <div className="customer-container">
                                    <div className="customer-container__lable"><div>КЛЮЧЕВЫЕ ПОКАЗАТЕЛИ</div></div>
                                    <Row contant={ 
                                        <div>
                                            <div className='profile-contant__top-data'>

                                                <div className='manager-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ 1 }</span>
                                                        <span className='customers-data-rub'>₽</span>
                                                    </div>
                                                    <div className='customers-data-description'>Общая сумма заказов</div>
                                                </div>

                                                <div className='manager-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ 2 }</span>
                                                        <span className='customers-data-rub'>₽</span>  
                                                    </div>
                                                    <div className='customers-data-description'>Средний чек</div>
                                                </div>

                                                <div className='manager-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ 3 }</span>
                                                    </div>
                                                    <div className='customers-data-description'>Количество заказов</div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    } />
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            }
        </div>
    )
})

export default CustomerEdit;