import './filters.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import shopFilterStorage from "../../../../storage/shop-filter"
import Status from "./shops-filter/status"
import Name from "./shops-filter/name"

const ShopFilter = observer(() => {

    // eslint-disable-next-line
    const { setStatus, setName, setRender } = shopFilterStorage;

    const applyFilter = () => {
        setRender()
    }

    return (
        <div className='filter-zone' id="filter-zone">
                <div className='filter-zone-row'>
                    <Status change={ setStatus }/>
                    <Name change={ setName }/>
                </div>
            <div className='filter-btn' onClick={ applyFilter }>Применить</div>
        </div>
    )
})

export default ShopFilter;