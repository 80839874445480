import './style.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableOrdersEasy from '../../common/table/TableOrdersEasy';

const ShipmentDelivery = observer(() => {

    const [orders, setOrders] = useState(null)
    const [ dictinary, setDictinary ] = useState([]);

    console.log(orders)


    useEffect(() => {
        const getData = async () => {
            await api.post("/orders/get-orders")
                .then(res => setOrders(res.data.filter(item => Number(item.orderStatus.id) === 6)))
                .catch(e => console.log(e))
            await api.post('/orders/get-order-status', {})
                .then(res => setDictinary(res.data.map(e => e.name)))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (

        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Заказы готовые к доставке</span>
                            {/* <FilterButton /> */}
                        </div>
                    </div>
                    {/* <CustomerFilter /> */}
                </div> }/>
            <Contant contant={ 
                <div className="marking-contant__body">
                    { orders !== null ? <TableOrdersEasy orders={ orders } dictinary={ dictinary } /> : "" }
                </div> 
            }/>
        </div>
    )
})

export default ShipmentDelivery;