import React from "react";
import './invoice.css'
import { COMPANY } from "../../settings";


export const InvoiceDocument = ({ order }) => {

  return(
    <div>
      <div className="invoice">
        <table width="100%" style={{fontFamily: "Arial"}}>
          <tbody>
            <tr style={{margin: "0 5px", display: "table"}}>
              <td style={{width: "68%", padding: "20px 0"}}>
                <div style={{textAlign: "justify", fontSize: "11pt"}}>Внимание! Оплата данного счета означает согласие с условиями поставки товара. Счет действителен в течение 5(пяти) банковских дней, не считая дня выписки счета. Уведомление об оплате  обязательно, в противном случае НЕ ГАРАНТИРУЕТСЯ наличие товара на складе. Товар отпускается по факту прихода денег на р/с Поставщика, самовывозом, при наличии доверенности и паспорта.</div>
              </td>
            </tr>
          </tbody> 
        </table>
      
        <table width="100%" border="2" style={{borderCollapse: "collapse", width: "100%", fontFamily: "Arial"}} cellPadding="2" cellSpacing="2">
          <tbody>
            <tr>
              <td colSpan="2" rowSpan="2" style={{minHeight: "13mm", width: "105mm"}}>
                <table width="100%" border="0" cellPadding="0" cellSpacing="0" style={{height: "13mm"}}>
                  <tbody>
                    <tr>
                      <td valign="top" style={{fontSize: "12pt"}}>
                        <div>{ COMPANY.bankName }</div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="bottom" style={{height: "3mm"}}>
                        <div style={{fontSize: "10pt"}}>Банк получателя</div>
                      </td>
                    </tr>
                    </tbody>
                </table>
              </td>
              <td style={{minHeight:"7mm",height:"auto", width: "25mm"}}>
                <div>БИK</div>
              </td>
              <td rowSpan="2" style={{verticalAlign: "top", width: "60mm"}}>
                <div style={{height: "7mm", lineHeight: "7mm", verticalAlign: "middle"}}>{ COMPANY.bankBik}</div>
                <div>{ COMPANY.bankAccountKor }</div>
              </td>
            </tr>
            <tr>
              <td style={{width: "25mm"}}>
                <div>Сч. №</div>
              </td>
            </tr>
            <tr>
              <td style={{minHeight:"6mm", height:"auto", width: "50mm"}}>
                <div>ИНН { COMPANY.inn }</div>
              </td>
              <td style={{minHeight:"6mm", height:"auto", width: "55mm"}}>
                <div>КПП { COMPANY.kpp }</div>
              </td>
              <td rowSpan="2" style={{minHeight: "19mm", height: "auto", verticalAlign: "top", width: "25mm"}}>
                <div>Сч. №</div>
              </td>
              <td rowSpan="2" style={{minHeight: "19mm", height: "auto", verticalAlign: "top", width: "60mm"}}>
                <div>{ COMPANY.bankAccount }</div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{minHeight:"13mm", height: "auto"}}>
                <table border="0" cellPadding="0" cellSpacing="0" style={{height: "13mm", width: "105mm"}}>
                  <tbody>
                    <tr>
                      <td valign="top" style={{fontSize: "12pt"}}>
                        <div>{ COMPANY.nameShort }</div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="bottom" style={{height: "3mm"}}>
                        <div style={{fontSize: "10pt"}}>Получатель</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
      
        <div style={{fontWeight: "bold", fontSize: "18pt", paddingLeft: "5px", fontFamily: "Arial"}}>
          Счет № { order.id } от { new Date(Date.now()).toISOString().slice(0, 10) }
        </div>
        <br/>
      
        <div style={{backgroundColor:"#000000", width:"100%", fontSize:"1px", height:"2px"}}>&nbsp;</div>
      
        <table width="100%" style={{fontFamily: "Arial"}}>
          <tbody>
            <tr style={{margin: "5px 0", display: "table"}}>
              <td style={{width: "30mm", verticalAlign: "top"}}>
                <div style={{paddingLeft:"2px"}}>Поставщик:    </div>
              </td>
              <td>
                <div style={{fontWeight:"bold", paddingLeft:"2px"}}>
                  <span>{COMPANY.nameShort} ИНН {COMPANY.inn}, КПП { COMPANY.kpp },</span>
                  <br/>
                  <span style={{fontWeight: "normal"}}>{ COMPANY.address }, тел.: { COMPANY.phone } , email: { COMPANY.email }  </span>          
                </div>
              </td>
            </tr>
            <tr style={{margin: "5px 0", display: "table"}}>
              <td style={{width: "30mm", verticalAlign: "top"}}>
                <div style={{paddingLeft:"2px"}}>Покупатель:    </div>
              </td>
              <td>
                <div style={{fontWeight:"bold", paddingLeft:"2px"}}>
                { order.customers.firstname } { order.customers.surname }, ИНН , КПП ,<br/><span style={{fontWeight: "normal"}}>{ order.customers.address != null ? order.customers.address.value : '' } тел.: { order.customers.phone != null ? order.customers.phone : '' } , email:  { order.customers.email != null ? order.customers.email : '' } </span>            </div>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table border="2" width="100%" cellPadding="10px" cellSpacing="2">
          <thead>
            <tr>
              <th style={{width:"13mm"}}>№</th>
              <th>Товары (работы, услуги)</th>
              <th style={{width:"20mm"}}>Кол-во</th>
              <th style={{width:"17mm"}}>Ед.</th>
              <th style={{width:"27mm"}}>Цена</th>
              <th style={{width:"27mm"}}>Сумма</th>
            </tr>
          </thead>
          <tbody >
            {
              order != null ?
              order.shopCard.map((item, index) => {
                  return(<tr key={Math.random()}>
                    <td style={{width:"13mm"}}>{ index + 1 }</td>
                    <td>{ item.goods.name }</td>
                    <td style={{width:"20mm", textAlign: "center"}}>{ item.count }</td>
                    <td style={{width:"17mm"}}>Шт.</td>
                    <td style={{width:"27mm", textAlign: "center"}}>{ item.goods.price }</td>
                    <td style={{width:"27mm", textAlign: "center"}}>{ item.goods.price * item.count }</td>
                  </tr>)
              }) : ''
            }
          </tbody>
        </table>
    
        <br/>
        <table style={{fontFamily: "Arial"}} border="0" width="100%" cellPadding="1" cellSpacing="1">
          <tbody>
            <tr>
              <td></td>
              <td style={{width:"37mm", fontWeight:"bold", textAlign: "right"}}>Всего к оплате:</td>
              <td style={{width:"27mm", fontWeight:"bold", textAlign: "center"}}>{ order.shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0) }</td>
            </tr>
          </tbody>
        </table>
    
        <div style={{fontFamily: "Arial", fontSize: "12pt"}}>
          Всего наименований { order.shopCard.length } на сумму { order.shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0) } рублей.<br />
        </div>
        <br/>
        <div style={{backgroundColor:"#000000", width:"100%", fontSize:"1px", height:"2px"}}>&nbsp;</div>
        <br/>
        <div style={{fontFamily: "Arial", fontSize: "10pt"}}>
          1. Счет действителен в течении 5 (пяти) банковских дней, не считая дня выписки счета. В случае нарушения срока оплаты сохранение цены на товар и наличие товара на складе НЕ ГАРАНТИРУЕТСЯ.<br />
          2. Оплата данного счета означает согласие с условиями изложенными в п.1
        </div>
        <div style={{padding: "30px 10px", width: "100%", height: "250px", fontSize: "10pt", fontWeight: "bold"}}>
          <div>Руководитель { COMPANY.seoDiscription } { COMPANY.seoShortName }</div>
          <br/><br/><br/>
          <div>Главный бухгалтер { COMPANY.chiefAccountantName }</div>
          <br/>
      
          <div style={{fontWeight: "normal"}}>М.П.</div>
          <br/>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </div>
    </div>
  )
}