import './Customer.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import Row from '../../common/marking/row';
import { Link } from 'react-router-dom';

const Orders = observer(({ filteredOrders }) => {

    return (
        filteredOrders.map(item => {
            return (
                <div key={ Math.random() }>
                    <div className="CustomerContainer">
                        <div className="customer-container">
                            <Row contant={
                                <div key={ Math.random() }>
                                    <div className='customer-order-top'>
                                        <div className='customer-order-top__column'>
                                            <span>Заказ</span>
                                            <Link to={ "/sales/order/" + item.id }>{ item.id }</Link>
                                            <div className='customer-order-top__status' style={ {
                                                background: `#${item.orderStatus.statusGroup.backgroundColor}`, 
                                                color: `#${item.orderStatus.statusGroup.color}`
                                            } }>{ item.orderStatus.name }</div>
                                        </div>
                                        <div className='customer-order-top__column-right'>{ new Date(item.dateCreated).toLocaleString() }</div>
                                    </div>
                                    <div className='customer-order-body'>
                                        {
                                            item.shopCard.map(element => {
                                                return <div key={ Math.random() } className='customer-order-body__good'>
                                                    <div className='customer-order-body__good-name'>{ element.goods.name }</div>
                                                    <div className='customer-order-body__good-value'><span>{ element.count }</span><span>&nbsp;шт</span></div>
                                                    <div className='customer-order-body__good-value'><span>{ element.goods.price }</span><span>&nbsp;₽</span></div>
                                                    <div className='customer-order-body__good-value'><span>{ element.goods.price * element.count }</span><span>&nbsp;₽</span></div>
                                                </div>
                                            })
                                        }
                                        <div className='customer-order-body__good'>
                                            <div className='customer-order-body__good-discount'>Скидка { item.discount } ₽</div>
                                            <div className='customer-order-body__good-total'>Итого { item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0) - item.discount } ₽</div>
                                        </div>
                                    </div>
                                </div>
                            }/>
                        </div>
                    </div>
                    {
                        item.tasks !== null ?
                        item.tasks.map(task => {
                            return (
                                <div key={ Math.random() } className="CustomerContainer CustomerContainer__top">
                                    <div className="customer-container">
                                        <Row contant={
                                            <div key={ Math.random() }>
                                                <div className='customer-order-top'>
                                                    <div className='customer-order-top__column'>
                                                        <span>{ task.title }</span>
                                                    </div>
                                                    <div className='customer-order-top__column-close'></div>
                                                </div>
                                                <div className='customer-order-body'>
                                                    <div className='customer-order-body__good'>
                                                        <div className='customer-order-body__task customer-order-body__task-date'>{ new Date(task.date).toLocaleDateString() }</div>
                                                        <div className='customer-order-body__task customer-order-body__task-user'>{ task.users[0] !== null ? task.users[0] : "-" }</div>
                                                        <div className='customer-order-body__task customer-order-body__task-order'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }/>
                                    </div>
                                </div>
                            )
                        })
                        : ""
                    }
                </div>
            )
        })
    )
})

export default Orders;