import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import imgTrue from "./img/true.svg"

const TableStatusSetting = observer(({ data }) => {
    console.log(data)
    useEffect(() => {
        const body = document.querySelector('#table-body')
        let html = '';
        data.forEach(element => {
            html += `<tr id="shop-id_${ element.id }">
                <td class="table-status-col">${ element.name }</td>
                <td class="table-status-col">${ element.status ? `<img src=${ imgTrue } alt="" width="20px" height="20px" />` : '' }</td>
                <td class="table-status-col">${ element.code }</td>
                <td class="table-status-col">${ element.priority }</td>
                <td class="table-status-col">${ element.statusGroup.fullName }</td>
            </tr>`
        });
        body.innerHTML = html

    // eslint-disable-next-line
    }, [data])
    
    return (
        <table className="Table">
            <thead id="table-head">
                <tr>
                    <th className='table-status-col'><span>Название</span></th>
                    <th className='table-status-col'><span>Активность</span></th>
                    <th className='table-status-col'><span>Символьный код</span></th>
                    <th className='table-status-col'><span>Порядок</span></th>
                    <th className='table-status-col'><span>Группа</span></th>
                </tr>
            </thead>
            <tbody id="table-body"></tbody>
        </table>
    )
})

export default TableStatusSetting;