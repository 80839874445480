import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import imgTrue from "./img/true.svg"

const TableStatusGroup = observer(({ data }) => {

    useEffect(() => {
        const body = document.querySelector('#table-body')
        let html = '';
        data.forEach(element => {
            html += `<tr id="shop-id_${ element.id }">
                <td class="table-col-4"><span style="background: #${element.backgroundColor}; color: #${element.color}; border: 1px solid #${element.color}">${ element.name }</span></td>
                <td class="table-col-4">${ element.status ? `<img src=${ imgTrue } alt="" width="20px" height="20px" />` : '' }</td>
                <td class="table-col-4">${ element.code }</td>
                <td class="table-col-4">${ element.priority }</td>
            </tr>`
        });
        body.innerHTML = html

    // eslint-disable-next-line
    }, [data])
    
    return (
        <table className="Table">
            <thead id="table-head">
                <tr>
                    <th className='table-col-4'><span>Название</span></th>
                    <th className='table-col-4'><span>Активность</span></th>
                    <th className='table-col-4'><span>Символьный код</span></th>
                    <th className='table-col-4'><span>Приоритет</span></th>
                </tr>
            </thead>
            <tbody id="table-body"></tbody>
        </table>
    )
})

export default TableStatusGroup;