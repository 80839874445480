import './inputes.css';
import React, { useEffect, useState } from 'react'
import { useMask } from '@react-input/mask';

const InputH = ({
    marginY,        // Margin top and bottom : Integer
    label,          // Label's title : String
    classInput,     // Class for input
    inputId,        // Input's id : String
    value,          // Input's value : String
    placeholder,    // Input's placeholder : String
    mask,           // Input's mask : Object { mask: '__:__', replacement: { _: /\d/ } }
    method,         // Input's onChange method : setState()
    disabled,       // Input's disabled : Boolean
    required        // Required field or not
}) => {
    
    const [ state, setState ] = useState( value != null ? value : '' )

    const inputMask = useMask( mask )

    useEffect(() => {
        if (method != null && typeof method === 'function')
            method(state)
    // eslint-disable-next-line
    }, [state])

    return (
        <div 
            className='input-horizontal' 
            style={{ 
                marginBottom: marginY != null ? marginY + 'px' : 10 + 'px',
                marginTop: marginY != null ? marginY + 'px' : 10 + 'px' }} >
                    <label className='input-horizontal__label'>{ label !== null ? label : '' }</label>
                    <input 
                        id={ inputId }
                        ref={ mask != null ? inputMask : undefined }
                        className={` input input-horizontal__input ${ classInput != null ? classInput : '' }` }
                        placeholder={ placeholder !== null ? placeholder : '' }
                        value={ state }
                        onChange={ e => setState(e.target.value) }
                        disabled={ disabled ? true : false }
                        data-required={ required != null ? true : false } />
        </div>        
    )
}

export default InputH;