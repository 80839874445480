import './products.css';
import '../element.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import api from '../../../services/api'
import ContentBlock from '../../common/elements/content-block';
import InputH from '../../elements/inpute/InputH';
import InputHTextarea from '../../elements/inpute/InputHTextarea';
import InputHSelect from '../../elements/inpute/InputHSelect';
import Foother from '../../common/marking/foother';
import ButtonSaveData from '../../elements/button/Save';
import ProductCharacteristic from './characteristic';
import ProductCharacteristicButton from './characteristic-button';
import ProductImagesBlock from './images-block';

const ProductNew = observer(() => {

    const { id } = useParams()

    const [ shops, setShops ] = useState(null)
    const [ goodsGroup, setGoodsGroup ] = useState(null)
    const [ vat, setVat ] = useState(null)

    // fields values
    const [ isLoad, setIsLoad ] = useState(false)
    const [ fieldName, setFieldName ] = useState('')
    const [ fieldDescription, setFieldDescription ] = useState('')
    const [ fieldGroupGood, setFieldGroupGood ] = useState('')
    const [ fieldLink, setFieldLink ] = useState('')
    const [ fieldManufacture, setFieldManufacture ] = useState('')
    const [ fieldPrice, setFieldPrice ] = useState('')
    const [ fieldPriceProvider, setFieldPriceProvider ] = useState('')
    const [ fieldShops, setFieldShops ] = useState('')
    // eslint-disable-next-line
    const [ fieldStatus, setFieldStatus ] = useState('')
    const [ fieldVat, setFieldVat ] = useState('')

    const [ characteristics, setCharacteristics ] = useState([["fsdf1", "2342423423"],["gdgdfgdg2", "3453453"]])

    const fillCharacteristics = (data) => {
        // let temporary = new Array();
        // temporary = [...characteristics]
        // temporary[data[2]] = [data[0], data[1]]
        // setCharacteristics(temporary)
    }

    const addCharacteristics = () => {
        // eslint-disable-next-line
        let temporary = new Array();
        temporary = [...characteristics]
        temporary.push(["", ""])
        setCharacteristics(temporary)
    }

    let dataGood = new FormData()
        dataGood.append("id", id != null ? id : 0)
        dataGood.append("name", fieldName != null ? fieldName : "")
        dataGood.append("description", fieldDescription != null ? fieldDescription : "")
        dataGood.append("groupGoodsId", fieldGroupGood !== 'all' ? fieldGroupGood : null)
        dataGood.append("link", fieldLink != null ? fieldLink : "")
        dataGood.append("manufacture", fieldManufacture != null ? fieldManufacture : "")
        dataGood.append("price", fieldPrice != null ? fieldPrice : "")
        dataGood.append("priceProvider", fieldPriceProvider != null ? fieldPriceProvider : "")
        dataGood.append("shopsId", fieldShops !== 'all' ? fieldShops : null)
        dataGood.append("vatId", fieldVat !== 'all' ? fieldVat : null)

    useEffect(() => {
        let dataGood = new FormData()
        dataGood.append('id', Number(id))
        const getGood = async () => {
            await api.post('/goods/get-good', dataGood)
                .then(res => {
                    setFieldName(res.data.name != null ? res.data.name : "")
                    setFieldDescription(res.data.description != null ? res.data.description : "")
                    setFieldGroupGood(res.data.groupGoods.id != null ? res.data.groupGoods.id : "")
                    setFieldLink(res.data.link != null ? res.data.link : "")
                    setFieldManufacture(res.data.manufacture != null ? res.data.manufacture : "")
                    setFieldPrice(res.data.price != null ? res.data.price : "")
                    setFieldPriceProvider(res.data.priceProvider != null ? res.data.priceProvider : "")
                    setFieldShops(res.data.shops.id != null ? res.data.shops.id : "")
                    setFieldVat(res.data.vat.id != null ? res.data.vat.id : "")
                    setIsLoad(true)
                })
                .catch(e => console.log(e))
        }
        const getData = async () => {
            await api.post('/orders/get-shops', {})
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-goods-group', {})
                .then(res => setGoodsGroup(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-vats', {})
                .then(res => setVat(res.data))
                .catch(e => console.log(e))
        }
        if (id != null) {
            getGood()
        } else {
            setIsLoad(true)
        }
        getData()

    // eslint-disable-next-line
    },[])

    useEffect(() => {
        console.log(characteristics)
        console.log('changed')
    }, [characteristics])

    return (

        isLoad ?
        <div className="Element">
            <div className='element-top'>
                <div className='element-links'>
                    <Link to={'/'}>Главная</Link>
                    <span>→</span>
                    <Link to={'/sales/products'}>Товары</Link>
                </div>
                <div className='element-h1'>{ id != null ? fieldName : 'Новый товар' }</div>
            </div>
            <ContentBlock title="Главная информация" name="main" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        <InputH label={'Название'} value={fieldName} method={setFieldName} marginY={25} required={true} />
                        <InputHTextarea label={'Описание'} value={fieldDescription} method={setFieldDescription} marginY={25} size={'big'}/>
                        <InputH label={'Производитель'} value={fieldManufacture} method={setFieldManufacture} marginY={25}/>
                        { shops !== null ? <InputHSelect label={'Магазин'} marginY={25} data={ shops } value={fieldShops} method={ setFieldShops } required={true} /> : "" }
                        <InputH label={'Ссылка в магазине'} value={fieldLink} method={setFieldLink} marginY={25}/>
                        { goodsGroup !== null ? <InputHSelect label={'Товарная группа'} marginY={25} data={ goodsGroup } value={fieldGroupGood} method={ setFieldGroupGood } required={true} /> : "" }
                    </div>
                </div>
            }/>
            <ContentBlock title="Характеристика" name="characteristic" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        {
                            characteristics != null ?
                            characteristics.map((item, index) => {
                                return(
                                    <div key={Math.random()}>
                                        <ProductCharacteristic value={ item } method={ fillCharacteristics } indexElement={ index } />
                                    </div>
                                )
                            }) : ""
                        }
                    <ProductCharacteristicButton method={ addCharacteristics } />
                    </div>
                </div>
            }/>
            <ContentBlock title="Изображение" name="images" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        <ProductImagesBlock />
                    </div>
                </div>
            }/>
            <ContentBlock title="Цена" name="price" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        { vat !== null ? <InputHSelect label={'Ставка НДС'} marginY={25} data={ vat } value={fieldVat} method={ setFieldVat } required={true} /> : "" }
                        <InputH label={'Закупочная цена'} value={fieldPriceProvider} method={setFieldPriceProvider} marginY={25}/>
                        <InputH label={'Цена'} value={fieldPrice} method={setFieldPrice} marginY={25} required={true} />
                    </div>
                </div>
            }/>
            <Foother left={true} contant={
                <div className="element-foother">
                    <ButtonSaveData text="Сохранить" url={'/goods/save-good'} params={dataGood}/>
                    <div id="element-save__success" className="d-none">Сохранено</div>
                    <div id="element-save__wrong" className="d-none">Ошибка сохранения</div>
                </div>
            }/>
        </div>
        :
        <div className='preloader-block'><div className='loader'></div></div>
        
    )
})

export default ProductNew;