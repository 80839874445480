import { makeAutoObservable } from 'mobx';

class WarehouseFilterStorage {

    render = 0
    status = 'all'
    name = ''
    type = ''

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setStatus = (status) => {
        this.status = status
    }

    setName = (name) => {
        this.name = name
    }

    setType = (type) => {
        this.type = type
    }
    
}

const data = new WarehouseFilterStorage();
export default data;