import './btn-close.css'
import React from 'react'

const BtnClose = ({logout}) => {
    const closeMenu = () => {
        const container = document.querySelector('#container-profile')
        const contantContainerLeft = document.querySelector('.ContantContainer-left')

        contantContainerLeft.classList.remove('ContantContainer-left__active')
        container.classList.remove('Contant-left__active')
    }
    
    return (
        <div className='btn-close' onClick={closeMenu}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="32" className="omnica-popper-target">
                <path d="M7.113 6.176a.6.6 0 01.848 0L12 10.214l4.038-4.038a.6.6 0 01.849 0l.937.937a.6.6 0 010 .848L13.785 12l4.04 4.04a.6.6 0 010 .848l-.937.937a.6.6 0 01-.849 0L12 13.784l-4.038 4.04a.6.6 0 01-.849 0l-.937-.937a.6.6 0 010-.848l4.04-4.04-4.039-4.038a.6.6 0 010-.849l.937-.936z" fill="currentColor"></path>
            </svg>
        </div>
    )
}

export default BtnClose;