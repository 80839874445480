import "./marking.css"
import React from 'react'

const Foother = ({ contant, left }) => {

    return (
        <div className={ left !== true || left == null ? "foother-container" : "foother-container-m0" }>
            { contant }
        </div>
    )
}

export default Foother;