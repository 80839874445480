import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { insertPaginator } from '../elements/paginator';

const TableProducts = observer(({ products, countElements, method }) => {

    const [ currentPage, setCurrentPage ] = useState(1)
    const navigate = useNavigate()

    const linkToGood = (id) => {
        navigate('/sales/products/' + id)
    }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (products.length > 0) {
                products.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id > b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    html += `
                        <tr id="link-to_${element.id}">
                            <td class='table-products-col'>${ element.name }</td>
                            <td class='table-products-col'>${ element.status ? "<div class='active-element'></div>" : "" }</td>
                            <td class='table-products-col'>${ element.shops.name }</td>
                            <td class='table-products-col'>${ element.groupGoods.name }</td>
                            <td class='table-products-col'>${ "0" }</td>
                            <td class='table-products-col'>${ element.price }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            insertPaginator(countElements, currentPage, setCurrentPage)
            products.forEach(element => {
                let link = document.querySelector('#link-to_' + element.id)
                link.addEventListener('click', () => linkToGood(element.id))
            })

    // eslint-disable-next-line
    }, [products])

    useEffect(() => {
        method(currentPage)
    // eslint-disable-next-line
    }, [currentPage])

    return (
        <div className='table-box__column'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-products-col'><span>Название</span></th>
                        <th className='table-products-col'><span>Активность</span></th>
                        <th className='table-products-col'><span>Магазин</span></th>
                        <th className='table-products-col'><span>Группа</span></th>
                        <th className='table-products-col'><span>Количество</span></th>
                        <th className='table-products-col'><span>Стоимость</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
            <div className='table-foother-count'>Всего товаров: { countElements }</div>
            <div className="table-foother" id='paginator'></div>
        </div>
    )
})

export default TableProducts;