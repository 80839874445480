import { observer } from "mobx-react-lite"
import "./elements.css"
import React, { useState } from "react"
import userStorage from "../../../storage/user"

const InputLable = observer(({ lableText, inputId, inputeValue, isRequired, settingsName }) => {

    const [ value, setValue ] = useState(inputeValue)
    const { setUserSettings } = userStorage;

    const changeValue = () => {
        const input = document.querySelector(`#${inputId}`)
        setValue(input.value)
        setUserSettings(settingsName, input.value)
    }

    return (
        <div className="input-label">
            <label className={ isRequired ? 'input-label-requared' : '' }>{ lableText }</label>
            <input id={ inputId } value={ value } onChange={ () => changeValue() }/>
        </div>
    )
})

export default InputLable