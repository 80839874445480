import { observer } from "mobx-react-lite"
import "./elements.css"
import React, { useEffect } from "react"
import statusStepStorage from "../../../storage/status-step"

const SelectStep = observer(({ data, lable }) => {

    const { orderTypeStep, setOrderTypeStep } = statusStepStorage;

    useEffect(() => {

    }, [orderTypeStep])

    return (
        <div className="input-label m-25">
            <label>{ lable }</label>
            <select value={ orderTypeStep !== null ? orderTypeStep : "all" } onChange={e => setOrderTypeStep(e.target.value)}>
                <option value="all">Выберете значение</option>
                {
                    data.map(item => <option key={Math.random()} value={ item.id } >{ item.name }</option>)
                }
            </select>
        </div>
    )
})

export default SelectStep