import { showProfile } from "../MenuFirstElement/MenuFirstElement"
import "./MenuFirstProfile.css"
import { observer } from 'mobx-react-lite';
import userStorage from "../../../storage/user"
import { useMemo } from "react";
import React from 'react'

const MenuFirstProfile = observer(({ id }) => {

    // eslint-disable-next-line
    const { user, setUser } = userStorage;

    let curentStatus;

    useMemo(()=> {
        // eslint-disable-next-line
        curentStatus = 'MenuFirstProfile__status '        

        switch (user.status) {
            case 1:
                curentStatus += 'status__green'
                break;
            case 2:
                curentStatus += 'status__red'
                break;
            case 3:
                curentStatus += 'status__orange'
                break;
            case 4:
                curentStatus += 'status__gray'
                break;
            default:
                break;
        }
    }, [user])

    return (
        <div 
            className="MenuFirstProfile"
            id={'menu_element-' + id}
            onClick={() => showProfile()}>
                <div className="MenuFirstProfile__profile" style={{  backgroundImage: "url('.." + user.photo + "')" }}></div>
                <div className={ curentStatus }></div>
        </div>
    )
})

export default MenuFirstProfile;