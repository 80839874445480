import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const OrdersFilterManagers = observer(({ change, data }) => {

    const [ state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element'>
            <label htmlFor="status">Группа статусов</label>
            <select 
                id="status"
                value={ state !== null ? state : "all" }
                onChange={(e) => setState(e.target.value)}>
                    <option value="all">Выберете значение</option>
                    {
                        data.map(item => <option key={Math.random()} value={ item.id }>{ item.name }</option>) 
                    }
            </select>
        </div>
        
    )
})

export default OrdersFilterManagers;