import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const TableProviders = observer(({ providers }) => {

    const navigate = useNavigate()

    const linkToGood = (id) => {
        navigate('/goods/provider/' + id)
    }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (providers.length > 0) {
                providers.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id > b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    html += `
                        <tr id="link-to_${element.id}">
                            <td class='table-provider-col-1'>${ element.name }</td>
                            <td class='table-provider-col-2'>+</td>
                            <td class='table-provider-col-3'>${ element.description }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            providers.forEach(element => {
                let link = document.querySelector('#link-to_' + element.id)
                link.addEventListener('click', () => linkToGood(element.id))
            })

    // eslint-disable-next-line
    }, [providers])

    return (
        <div className='table-box'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-provider-col-1'><span>Поставщик</span></th>
                        <th className='table-provider-col-2'><span>Активность</span></th>
                        <th className='table-provider-col-3'><span>Описание</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
    )
})

export default TableProviders;