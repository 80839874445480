import './Request.css';
import React from 'react'

const Request = ({contant}) => {
    return (
        <div className="Request">
            Обращения
        </div>
    )
}

export default Request;