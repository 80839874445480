import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Head from '../../common/marking/head';
import FilterButton from '../../common/elements/filter-button';
// eslint-disable-next-line
import ButtonAdd from '../../common/elements/button-add';
import { useNavigate } from 'react-router-dom';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableStatusSetting from '../../common/table/TableStatusSetting';
import shopFilterStorage from "../../../storage/shop-filter"
import { compareArray } from "../../common/elements/filters/service"
import StatusFilter from '../../common/elements/filters/status-filter';

const Statuses = observer(() => {

    // eslint-disable-next-line
    const navigator = useNavigate()
    const [ shops, setShops ] = useState(null)
    const { status, statusGroup, name, render } = shopFilterStorage;

    useEffect(() => {

        const getData = async () => {
            await api.post('/orders/get-order-status', {})
                .then(res => {

                    let sortRes = res.data
                        .sort((a, b) => {
                            if (a.statusGroup.priority - b.statusGroup.priority === 0) return 0
                            if (a.statusGroup.priority - b.statusGroup.priority < 0) return -1
                            else return 1
                        })
                        .sort((a, b) => {
                            if (a.statusGroup.priority - b.statusGroup.priority === 0) {
                                if (a.priority - b.priority === 0) return 0
                                if (a.priority - b.priority < 0) return -1
                                else return 1
                            } else return 0
                        })

                    let array = []
                    let statusData;
                    let statusGroupData;
                    let nameData;

                    if (status !== null && status !== "all") statusData = sortRes.filter(item => String(item.status) === status)
                    else statusData = sortRes

                    if (statusGroup !== null && statusGroup !== "all") statusGroupData = sortRes.filter(item => String(item.statusGroup.id) === statusGroup)
                    else statusGroupData = sortRes

                    if (name !== null && name !== "") nameData = sortRes.filter(item => item.name.toLowerCase().includes(name.toLowerCase()))
                    else nameData = sortRes

                    array.push(statusData)
                    array.push(nameData)
                    array.push(statusGroupData)

                    setShops(compareArray(array))

                })
                .catch(e => console.log(e))
        }

        getData()
    // eslint-disable-next-line
    },[render])

    return (
        <div className="Component">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Статусы</span>
                            <FilterButton />
                        </div>
                        {/* <ButtonAdd method={() => {
                            navigator('/settings/order-status/new')
                        }}/> */}
                    </div>
                    <StatusFilter />
                </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        { shops !== null ? <TableStatusSetting data={ shops } /> : '' }
                    </div> 
            }/>
        </div>
    )
})

export default Statuses;