import React, { useEffect, useRef, useState } from "react"
import './documents.css'
import { useNavigate, useParams } from "react-router-dom"
import api from '../services/api'
import { TORG12Document } from "./torg12/Torg12"
import { InvoiceDocument } from "./invoice/InvoiceDocument"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

export const Document = ({ type }) => {

    const { id } = useParams();
    var navigator = useNavigate()

    const reportTemplateRef = useRef(null)

    const [ state, setState ] = useState('landscape')

    useEffect(() => {
        if(type === 'invoice')
            setState('portrait')
    // eslint-disable-next-line
    }, [])

    const printHTML = async() => {
        const input = reportTemplateRef.current
        try {
            const canvas = await html2canvas(input)
            const imgData = canvas.toDataURL("image/png")
            const pdf = new jsPDF({
                orientation: state
            })

            const width = pdf.internal.pageSize.getWidth()
            const height = (canvas.height * width) / canvas.width

            pdf.addImage(imgData, "PNG", 10, 10, width - 20, height - 20)
            pdf.save("doc.pdf")

        } catch (e) {
            console.log(e)
        }
    }

    const [ order , setOrder ] = useState(null)
    const [ doc, setDoc ] = useState(null)

    useEffect(() => {
        if(order != null) {
            switch (type) {
                case 'torg12':
                    setDoc(<TORG12Document order={ order }/>)
                    break;
                case 'invoice':
                    setDoc(<InvoiceDocument order={ order }/>)
                    break;
                default:
                    break
            }
        }
    // eslint-disable-next-line
    }, [order])

    useEffect(() => {
      const getData = async() => {
        const data = new FormData()
        data.append('id', Number(id))
        await api.post('/orders/get-order-only', data)
          .then(res => setOrder(res.data))
          .catch(e => console.log(e))
      }
      getData()
    // eslint-disable-next-line
    }, [])

    return(
        order != null ?
        <div className="document">
            <div className="document-buttoms">
                <div className="document-buttom" onClick={() => navigator('/sales/order/' + id)}>Назад</div>
                <div className="document-buttom" onClick={() => printHTML()}>Печать</div>
            </div>
            <div className="document-container" ref={ reportTemplateRef }>
                { doc != null && order != null ? doc : '' }
            </div>
        </div>
        : <div></div>
    )
}