import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const CustomerFilterName = observer(({ change }) => {

    const [state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element' data-order-filter="1">
            <label htmlFor="name">ФИО</label>
            <input id="name" onChange={(e) => setState(e.target.value)} />
        </div>
        
    )
})

export default CustomerFilterName;