import { observer } from 'mobx-react-lite'
import React from 'react'
import './ContantContainer.css'
import menu from '../../../storage/menu';
import { useEffect } from 'react';
import Notification from '../../contents/Notification/Notification';
import Request from '../../contents/Request/Request';
import Profile from '../../contents/Profile/Profile';

const ContantContainer = observer(({location, contant, logout, user}) => {

    const {secondMenuIsShow, changeStateSecondMenu} = menu;

    window.addEventListener('load', () => {
        const hideButton = document.querySelector('#ContantContainer__hide-button');
        hideButton.addEventListener('click', () => {
            changeStateSecondMenu();
        })
    })

    useEffect(() => {
        let menuSecond = document.querySelector('.MenuSecond')
        const hideButton = document.querySelector('#ContantContainer__hide-button');
        if (!secondMenuIsShow) {
            menuSecond.classList.remove('MenuSecond__active');
            hideButton.classList.remove('ContantContainer__button-show-active');
        } else {
            menuSecond.classList.add('MenuSecond__active');
            hideButton.classList.add('ContantContainer__button-show-active');
        }
    }, [secondMenuIsShow])

    return (
        <div className="ContantContainer">
            <div className="ContantContainer__button-show" id="ContantContainer__hide-button"></div>
            { contant }
            <div className="ContantContainer-left"></div>
            <div className="ContantContainer-left"></div>
            <div className="Contant-left" id="container-notification"><Notification/></div>
            <div className="Contant-left" id="container-request"><Request/></div>
            <div className="Contant-left Contant-left__profile" id="container-profile"><Profile logout={logout} user={user}/></div>
        </div>
    )
    
})

export default ContantContainer;