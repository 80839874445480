import "./elements.css"
import React from "react"

const ButtonSave = ({ text, method }) => {

    return (
        <div className="button-save" onClick={ method }>{text}</div>
    )
}

export default ButtonSave