import './table.css';
import React, { useEffect } from 'react'

const Table = ({ head, body }) => {

    useEffect(() => {
        const tableBody = document.querySelector('#table-body')
        const tableHead = document.querySelector('#table-head')

        let htmlHead = '<tr>';
        for (let i = 0; i < head.length; i++) {
            htmlHead += `<th class="table-group-col"><span>${head[i]}</span></th>`
        }
        htmlHead += "</tr>"
        tableHead.innerHTML = htmlHead

        let htmlBody = '';
        for (let j = 0; j < body.length; j++) {
            let htmlRow = '<tr>';
            for (let i = 0; i < head.length; i++) {
                htmlRow += `<td class="table-group-col">${body[j][i]}</td>`
            }
            htmlRow += '</tr>'
            htmlBody += htmlRow
        }

        tableBody.innerHTML = htmlBody;
    // eslint-disable-next-line
    }, [])

    return (
        <table className="Table">
            <thead id="table-head">
                
            </thead>
            <tbody id="table-body">

            </tbody>
        </table>
    )
}

export default Table;