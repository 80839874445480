import '../orders.css';
import React, { useEffect, useState } from 'react'

const InputBtn = ({ state, method, placeholder }) => {

    const [ name, setName ] = useState("")
    const [ parent, setParent ] = useState("")
    const [ shop, setShop ] = useState("")
    const [ active, setActive ] = useState("")

    useEffect(() => {
        setParent(state.groupGoods)
        setShop(state.shop)
        setActive(state.active)
        setName(state.name)
    // eslint-disable-next-line
    }, [])

    const applyFilter = () => {
        method({
            groupGoods: parent,
            shop: shop,
            active: active,
            name: name
        })
    }

    return (

        <div className='order-element-search'>
            <input value={ name } onChange={e => setName(e.target.value)} placeholder={placeholder}/>
            <div onClick={ applyFilter }></div>
        </div>
        
    )
}

export default InputBtn;