import '../filters.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import ordersStorage from "../../../../../storage/orders"

const OrdersFilterTypesDelivery = observer(({ selectChangeColor, data }) => {

    const { setTypeDelivery } = ordersStorage;

    return (

        <div className='filter-element' data-order-filter="9">
            <label htmlFor="type_delivery">Тип доставки</label>
            <select id="type_delivery" defaultValue="" onChange={(e) => {
                    selectChangeColor()
                    setTypeDelivery(e.target.value)
                }}>
                <option value="">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default OrdersFilterTypesDelivery;