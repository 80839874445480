import "./elements.css"
import React from "react"
import api from "../../../services/api"

const ButtonSaveData = ({ text, url, params }) => {

    const saveData = async (url, params) => {
        const saveSuccess = document.querySelector('#element-save__success')
        const saveWrong = document.querySelector('#element-save__wrong')

        let requiredFields = document.querySelectorAll('[data-required="true"]')
        Array.from(requiredFields)
                .forEach(item => item.classList.remove('validation-field'))
        if (requiredFields.length > 0) 
            requiredFields = Array.from(requiredFields)
                .filter(item => (item.value === 'all' || item.value === ""))
        
        if (requiredFields.length <= 0) {
            Array.from(requiredFields)
                .forEach(item => item.classList.remove('validation-field'))
        
            await api.post(url, params)
                .then(res => {
                    if (res.data === true) {
                        saveSuccess.classList.remove('d-none')
                        saveWrong.classList.add('d-none')
                    } else {
                        saveSuccess.classList.add('d-none')
                        saveWrong.classList.remove('d-none')
                    }
                })
                .catch(e => {

                })
        } else {
            Array.from(requiredFields)
                .forEach(item => item.classList.add('validation-field'))
        }
    }

    return (
        <div className="button-save" onClick={ () => saveData(url, params) }>{text}</div>
    )
}

export default ButtonSaveData