export const compareArray = (array) => {

    let previousArray = array[0]

    if (array.length > 1) {
        for (let i = 1; i < array.length; i++) {
            // eslint-disable-next-line
            previousArray = array[i].filter(item => previousArray.includes(item))
        }
    }
    
    return previousArray;
}