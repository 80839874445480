import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const TableOrders = observer(({ orders, dictinary }) => {

    const [ status, setStatus ] = useState(false)
    const navigate = useNavigate()

    const showStatus = () => {
        const statusBar = document.querySelector('#table-status')
        const table = document.querySelector('#table')
        statusBar.classList.add('table-status__active')
        table.classList.add('Table__active')
        statusBar.innerHTML = `<div class="table-status-top" id="table-status-back">← Свернуть</div><ul id="table-status-contain">${ dictinary.map(e => `<li>${e}</li>`).join('') }</ul>`
        
        const back = document.querySelector('#table-status-back')
        back.removeEventListener('click', showStatus)
        back.addEventListener('click', hideStatus)
        setStatus(true)
    }

    const hideStatus = () => {
        const statusBar = document.querySelector('#table-status')
        const table = document.querySelector('#table')
        statusBar.classList.remove('table-status__active')
        table.classList.remove('Table__active')
        statusBar.innerHTML = '<span id="table-status-back">Статусы</span>'

        const back = document.querySelector('#table-status-back')
        back.removeEventListener('click', hideStatus)
        back.addEventListener('click', showStatus)
        setStatus(false)
    }

    let openStatus = () => {
        if (status) {
            hideStatus()
        } else {
            showStatus()
        }
    }

    const linkToOrder = (id) => {
        navigate('/sales/order/' + id)
    }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (orders.length > 0) {
                orders.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id < b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    html += `
                        <tr id="link-to_${element.id}">
                            <td class='table-orders-col'>${ element.id }</td>
                            <td class='table-orders-col'>${ new Date(element.dateCreated).toLocaleDateString() }</td>
                            <td class='table-orders-col'>нет</td>
                            <td class='table-orders-col'>${ element.customers.firstname } ${ element.customers.surname }</td>
                            <td class='table-orders-col'>${ element.typeOrder.name }</td>
                            <td class='table-orders-col'>${ element.user.username } ${ element.user.surname }</td>
                            <td class='table-orders-col'>${ element.orderStatus.name }</td>
                            <td class='table-orders-col'>нет</td>
                            <td class='table-orders-col'>${ element.totalPrice }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            orders.forEach(element => {
                let link = document.querySelector('#link-to_' + element.id)
                link.addEventListener('click', () => linkToOrder(element.id))
            })

    // eslint-disable-next-line
    }, [orders])

    return (
        <div className='table-box'>
            <div className='table-status' id="table-status"><span id="table-status-back" onClick={openStatus}>Статусы</span></div>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-orders-col'><span>Номер</span></th>
                        <th className='table-orders-col'><span>Дата и время</span></th>
                        <th className='table-orders-col'><span>Тип клиента</span></th>
                        <th className='table-orders-col'><span>Покупатель</span></th>
                        <th className='table-orders-col'><span>Тип</span></th>
                        <th className='table-orders-col'><span>Менеджер</span></th>
                        <th className='table-orders-col'><span>Статус заказа</span></th>
                        <th className='table-orders-col'><span>Оплата</span></th>
                        <th className='table-orders-col'><span>Сумма</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
    )
})

export default TableOrders;