import { makeAutoObservable } from 'mobx';

class GoodsFilterStorage {

    render = 0
    shop = null
    group = null
    warehouse = null
    manufacture = null
    goodTag = null
    nameGood = null

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setShop = (shop) => {
        this.shop = shop
    }

    setGroup = (group) => {
        this.group = group
    }

    setWarehouse = (warehouse) => {
        this.warehouse = warehouse
    }

    setManufacture = (manufacture) => {
        this.manufacture = manufacture
    }

    setGoodTag = (goodTag) => {
        this.goodTag = goodTag
    }

    setNameGood = (nameGood) => {
        this.nameGood = nameGood
    }
    
}

const data = new GoodsFilterStorage();
export default data;