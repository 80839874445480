import './Customer.css';
import React, { useEffect, useState } from 'react'
import Head from "../../common/marking/head"
import Contant from "../../common/marking/contant"
// eslint-disable-next-line
import api, { dadataToken } from "../../../services/api"
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Row from '../../common/marking/row';
import Column from '../../common/marking/column';
import InputLable from '../../common/elements/input-lable';
import InputLableDate from '../../common/elements/input-lable-date';
import Select from '../../common/elements/select';
import AddressModule from '../Address/AddressModule';
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';

const Customers = observer(() => {

    const [ managers, setManagers ] = useState(null)
    const [ shops, setShops ] = useState(null)
    const currentUser = JSON.parse(window.localStorage.getItem('user'))

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-managers', {})
                .then(res => setManagers(res.data.map(item => {
                    return {
                        id: item.id, 
                        name: `${item.username} ${item.surname !== null ? item.surname : ""} ${item.patronymic !== null ? item.patronymic : ""}`
                    }})))
                .catch(e => console.log(e))
            await api.post('/orders/get-shops', {})
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (
        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='order-top'>
                            <div className='order-links'>
                                <Link to={'/'}>Главная</Link>
                                <span>→</span>
                                <Link to={'/sales/customers'}>Список клиентов</Link>
                            </div>
                            <div className='order-h1'>Новый клиент</div>
                        </div>
                </div> }/>
            <Contant contant={ 
                <div className="marking-contant__body-element">

                    <div className='customer-edit-container customer-edit-container__not-border'>
                        <div className="user-setting-container__lable"><div>ОСНОВНЫЕ ДАННЫЕ</div></div>
                            <Row contant={ 
                                <div>
                                    <Column contant={ 
                                        <div>
                                            <InputLable lableText="Имя" inputId="customer_firstname" inputeValue="" isRequired={ true } settingsName="username" />
                                            <InputLable lableText="Фамилия" inputId="customer_firstname" inputeValue="" isRequired={ false } settingsName="surname" />
                                            <InputLable lableText="Отчество" inputId="customer_firstname" inputeValue="" isRequired={ false } settingsName="patronymic" />
                                            <InputLable lableText="Email" inputId="customer_email" inputeValue="" isRequired={ false } settingsName="email" />
                                            <InputLableDate lableText="День рождения" inputId="customer_date_birthday" inputeValue="" isRequired={ false } settingsName="date_birthday" />
                                        </div>
                                    }/>
                                    <Column contant={ 
                                        <div>
                                            { managers !== null ? <Select data={ managers } lable="Менеджер" defaultValue={ currentUser } noMargin={true}/> : "" }
                                            { shops !== null ? <Select data={ shops } lable="Магазин" noMargin={true} /> : "" }
                                            <Select data={ [{id: 1, name: 'Да'}, {id: 2, name: 'Нет'}] } lable="Email подписка" defaultValue={ {id: 1, name: 'Да'} } noMargin={true} />
                                            <Select data={ [{id: 1, name: 'Мужской'}, {id: 2, name: 'Женский'}] } lable="Пол" noMargin={true} />
                                        </div>
                                    }/>
                                </div>
                            }/>
                    </div>

                    <div className='customer-edit-container'>
                        <div className="user-setting-container__lable"><div>ТЕЛЕФОНЫ</div></div>
                            <Row contant={ 
                                <div>
                                    <Column contant={ 
                                        <InputLable lableText="Телефон" inputId="customer_phone" inputeValue="" isRequired={ false } settingsName="phone" />
                                    }/>
                                </div>
                            }/>
                    </div>

                    <div className='customer-edit-container'>
                        <div className="user-setting-container__lable"><div>АДРЕС</div></div>
                            <Row contant={ 
                                <AddressModule />
                            }/>
                    </div>

                </div>
            }/>
            <Foother contant={ 
                        <div className="user-foother">
                            <ButtonSave text="Сохранить" method={ ()=> {  } }/>
                            <div id="user-save__success">Сохранено</div>
                            <div id="user-save__wrong">Ошибка сохранения</div>
                        </div>
                    } />
        </div>
    )
})

export default Customers;