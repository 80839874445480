import './MenuFirst.css';
import React, { useEffect } from 'react'
import MenuFirstElement from '../MenuFirstElement/MenuFirstElement';
import MenuFirstProfile from '../MenuFirstProfile/MenuFirstProfile';
import { observer } from 'mobx-react-lite';
import dataStorage from "../../../storage/data";
import api from "../../../services/api"
import { useNavigate } from 'react-router-dom';

const MenuFirst = observer(({user}) => {

    const { setRoles } = dataStorage;
    const navigator = useNavigate()

    useEffect(() => {
        
        const getRoles = async () => {
            await api.post('user/get-roles', {})
                .then(result => {
                    setRoles(result.data.roles.map(role => [role.fullName, role.name]))
                })
                .catch(e => console.log(e.message))
        }

        getRoles()

        // eslint-disable-next-line
    },[])

    return (
        <div className="MenuFirst">
            <div className="MenuFirst__container MenuFirst__container-top">
                <div className="MenuFirst__icon" onClick={() => navigator('/')}></div>
                <MenuFirstElement 
                    name={"Продажи"}
                    img={"#shopping_cart_outlined"}
                    id={"orders"} 
                    active={false}/>
                {/* <MenuFirstElement 
                    name={"Аналитика"} 
                    img={"#chart_bar_2"} 
                    id="analytics"
                    active={true}/> */}
                <MenuFirstElement 
                    name={"Задачи"} 
                    img={"#checkmark_circle_outlined"}
                    id={"tasks"} 
                    active={false}/>
                {/* <MenuFirstElement 
                    name={"Чат"} 
                    img={"#sms_chat_outlined"}
                    id={"chat"} 
                    active={false}/>
                <MenuFirstElement 
                    name={"Почта"} 
                    img={"#email"}
                    id={"mail"} 
                    active={false}/>
                <MenuFirstElement 
                    name={"Заказ из Китая"} 
                    img={"#order"}
                    id={"chinaOrders"} 
                    active={false}/> */}
            </div>
            <div className="MenuFirst__container MenuFirst__container-bottom">
            <MenuFirstElement 
                    name={"Показать"} 
                    img={"#more_horizontal"}
                    id={"more"}/>
                <MenuFirstElement 
                    name={"Оповещения"} 
                    img={"#notifications_outlined"}
                    id={"notifications"}/>
                <MenuFirstElement 
                    name={"Обращения"} 
                    img={"#help_outlined"}
                    id={"requests"}/>
                <MenuFirstElement 
                    name={"Настройки"} 
                    img={"#settings_outlined"}
                    id={"settings"}/>
                <MenuFirstProfile 
                    id={"profile"}
                    user={user}/>
            </div>
        </div>
    )
})

export default MenuFirst;