import { observer } from 'mobx-react-lite';
import './filters.css';
import React, { useEffect, useState } from 'react'
import ordersStorage from "../../../../storage/orders"

const FilterPopUp = observer(() => {

    const defaultValueFilters = [1, 2, 4, 7]
    const { setRender } = ordersStorage;

    let filterOrder = JSON.parse(window.localStorage.getItem('filter-orders'))

    const [ activeFilter, setActiveFilter ] = useState(
        filterOrder !== null ? filterOrder : defaultValueFilters
    );
    const [ group, setGroup ] = useState([])

    const filters = [
            {id: 1, name: 'Номер заказа', group: 'Заказ',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='1' checked={activeFilter.includes(1)} onChange={(e) => handleChange(e)}/><label>Номер заказа</label></div>},
            {id: 2, name: 'Типы заказа', group: 'Заказ',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='2' checked={activeFilter.includes(2)} onChange={(e) => handleChange(e)}/><label>Типы заказа</label></div>},
            {id: 3, name: 'Покупатель', group: 'Покупатель', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='3' checked={activeFilter.includes(3)} onChange={(e) => handleChange(e)}/><label>Покупатель</label></div>},
            {id: 4, name: 'Менеджеры', group: 'Заказ', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='4' checked={activeFilter.includes(4)} onChange={(e) => handleChange(e)}/><label>Менеджеры</label></div>},
            {id: 5, name: 'Статусы оплаты', group: 'Оплата',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='5' checked={activeFilter.includes(5)} onChange={(e) => handleChange(e)}/><label>Статусы оплаты</label></div>},
            {id: 6, name: 'Товар в заказе', group: 'Заказ', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='6' checked={activeFilter.includes(6)} onChange={(e) => handleChange(e)}/><label>Товар в заказе</label></div>},
            {id: 7, name: 'Магазины', group: 'Заказ', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='7' checked={activeFilter.includes(7)} onChange={(e) => handleChange(e)}/><label>Магазины</label></div>},
            {id: 8, name: 'Типы оплаты', group: 'Оплата', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='8' checked={activeFilter.includes(8)} onChange={(e) => handleChange(e)}/><label>Типы оплаты</label></div>},
            {id: 9, name: 'Типы доставки', group: 'Доставка',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='9' checked={activeFilter.includes(9)} onChange={(e) => handleChange(e)}/><label>Типы доставки</label></div>}
    ]

    const handleChange = (e) => {
        activeFilter.includes(Number(e.target.dataset.value))
            ? setActiveFilter(activeFilter.filter(x => x !== Number(e.target.dataset.value)))
            : setActiveFilter([...activeFilter, Number(e.target.dataset.value)])
    }

    const resetDefault = () => {
        setActiveFilter(defaultValueFilters)
    }

    const saveFilters = () => {
        window.localStorage.setItem('filter-orders', JSON.stringify(activeFilter))
        setRender()
        hidePopUp()
    }

    const hidePopUp = (e) => {
        const area = document.querySelector('#filter-zone-popup')
        area.classList.remove('filter-zone-popup__active')
    }

    useEffect(() => {
        let set = new Set()
        filters.forEach(item => set.add(item.group))
        setGroup(Array.from(set))
    // eslint-disable-next-line
    },[activeFilter])

    return (
        <div id="filter-zone-popup">
            <div className='filter-zone-popup-background'>
                <div className='filter-zone-popup-top'>
                    <span>Фильтры</span>
                    <div id='filter-zone-popup__close' onClick={e => hidePopUp()}></div>
                </div>
                <div className='filter-zone-popup-center'>
                    <div className='filter-zone-popup-center__left'>
                        {
                            filters.filter(item => activeFilter.includes(item.id)).map(e => {
                            return (
                                <div className="filter-popup-active-element" key={Math.random()}>
                                    <span>{ e.name }</span>
                                    <div data-value={ e.id } onClick={(e) => handleChange(e)}></div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className='filter-zone-popup-center__right'>
                        {
                            group.map(item => {
                                let array = filters.filter(element => element.group === item)
                                array.unshift({
                                    code: <div className="filter-popup-head" key={ Math.random() }>{ item }</div>
                                })
                                return array.map(item => item.code)
                            })
                        }
                    </div>
                </div>
                <div className='filter-zone-popup-bottom'>
                    <div className='filter-zone-popup-button-save' onClick={() => saveFilters() }>Сохранить</div>
                    <span className='filter-zone-popup-button-reset' onClick={() => resetDefault() }>Сбросить</span>
                </div>
            </div>
        </div>
    )
})

export default FilterPopUp;