import { observer } from 'mobx-react-lite';
import React from 'react'
import './MenuFirstElement.css';
import { Link } from "react-router-dom";
import orderImg from './img/order.svg';
import menuStore from '../../../storage/menu';
import { useEffect } from 'react';

export const showNotifications = () => {
    const contantContainerLeft = document.querySelector('.ContantContainer-left')
    const contantLeft = document.querySelector('#container-notification')

    if (contantContainerLeft.classList.contains('ContantContainer-left__active')) {
        contantContainerLeft.classList.remove('ContantContainer-left__active')
        contantLeft.classList.remove('Contant-left__active')
    } else {
        contantContainerLeft.classList.add('ContantContainer-left__active')
        contantLeft.classList.add('Contant-left__active')
        contantContainerLeft.addEventListener('click', () => {
            contantContainerLeft.classList.remove('ContantContainer-left__active')
            contantLeft.classList.remove('Contant-left__active')
        })
    }
}

export const showRequest = () => {
    const contantContainerLeft = document.querySelector('.ContantContainer-left')
    const contantLeft = document.querySelector('#container-request')

    contantContainerLeft.classList.add('ContantContainer-left__active')
    contantLeft.classList.add('Contant-left__active')
    contantContainerLeft.addEventListener('click', () => {
        contantContainerLeft.classList.remove('ContantContainer-left__active')
        contantLeft.classList.remove('Contant-left__active')
    })
}

export const showProfile = () => {
    const contantContainerLeft = document.querySelector('.ContantContainer-left')
    const contantLeft = document.querySelector('#container-profile')

    contantContainerLeft.classList.add('ContantContainer-left__active')
    contantLeft.classList.add('Contant-left__active')
    contantContainerLeft.addEventListener('click', () => {
        contantContainerLeft.classList.remove('ContantContainer-left__active')
        contantLeft.classList.remove('Contant-left__active')
    })
}

export const hideSecondMenu = () => {

    // eslint-disable-next-line
    const { secondMenuIsShow, changeStateSecondMenu } = menuStore;

    let menuSecond = document.querySelector('.MenuSecond');
    const hideButton = document.querySelector('#ContantContainer__hide-button');
    const contantContainer = document.querySelector('.ContantContainer');
    const root = document.querySelector('#root');

    if (secondMenuIsShow) {
        menuSecond.classList.remove('MenuSecond__active');
    }
    hideButton.classList.add('d-none')
    contantContainer.classList.add('ContantContainer-along')
    root.classList.add('root-dark')
}

const MenuFirstElement = observer(({name, id, img}) => {
    const { activeFirstMenu, chooseFirstMenu, secondMenuIsShow, changeStateSecondMenu } = menuStore;

    let isActive = false;
    if (activeFirstMenu === id) isActive = true;

    let elementClass = isActive ? "MenuFirstElement MenuFirstElement__active" : "MenuFirstElement";
    const containerClass = isActive ? "MenuFirstElement__container-active" : "MenuFirstElement__container";
    const iconClass = isActive ? "MenuFirstElement__icon-active" : "MenuFirstElement__icon-inactive";

    const elementLink = (id) => {
        if (id !== 'notifications' &&
            id !== 'requests' &&
            id !== 'profile') {
                chooseFirstMenu(id);
                if (!secondMenuIsShow &&
                    id !== 'tasks' &&
                    id !== 'chat' &&
                    id !== 'mail' &&
                    id !== "chinaOrders" &&
                    id !== 'user') {
                        changeStateSecondMenu();
                }
        }
    }

    useEffect(() => {
            let menuSecond = document.querySelector('.MenuSecond');

            const menuContainer = document.querySelector('#menu-' + id)

            menuContainer.addEventListener('mouseover', () => {
                let helper = document.querySelector('#helper-' + id);
                if (helper !== null) helper.classList.add('MenuFirstElement__helper-active')
            })

            menuContainer.addEventListener('mouseout', () => {
                let helper = document.querySelector('#helper-' + id);
                if (helper !== null) helper.classList.remove('MenuFirstElement__helper-active')
            })

            // eslint-disable-next-line
            menuContainer.addEventListener('click', () => {
                const hideButton = document.querySelector('#ContantContainer__hide-button');
                const contantContainer = document.querySelector('.ContantContainer');
                const root = document.querySelector('#root');

                if (id === 'tasks' ||
                    id === 'chat' ||
                    id === 'mail' ||
                    id === 'chinaOrders' ) {
                        hideSecondMenu()
                        hideButton.classList.add('MenuSecond__hide')
                } else if (id === 'notifications') {
                    showNotifications();
                } else if (id === 'requests') {
                    showRequest();
                } else if (id === 'profile') {
                    showProfile();
                } else {
                    if (secondMenuIsShow) menuSecond.classList.add('MenuSecond__active');
                    hideButton.classList.remove('MenuSecond__hide')
                    contantContainer.classList.remove('ContantContainer-along')
                    root.classList.remove('root-dark')
                }
            }
        )

    // eslint-disable-next-line
    }, [secondMenuIsShow])
    switch (id) {
        case 'orders':
        case 'analytics':
        case 'notifications':
        case 'requests':
        case 'settings':
        case 'profile':
        case 'more':
            return (
                <div
                    className={elementClass} 
                    id={'menu_element-' + id}
                    onClick={() => elementLink(id)}>
                    <div className={containerClass} id={"menu-" + id}>
                        <Link to={ id === 'orders' ? 'sales/orders' : id === 'analytics' ? 'analytics/orders' : 'settings/users' }>
                        <svg className="MenuFirstElement__icon">
                            <use href={orderImg + img} className={iconClass}></use>
                        </svg>
                        </Link>
                        <div className="MenuFirstElement__helper" id={"helper-" + id}>{name}</div>
                    </div>
                </div>
            )
        default:
            return (
                <Link
                    to={id} 
                    className={elementClass} 
                    id={'menu_element-' + id}
                    onClick={() => elementLink(id)}>
                    <div className={containerClass} id={"menu-" + id}>
                        <svg className="MenuFirstElement__icon">
                            <use href={orderImg + img} className={iconClass}></use>
                        </svg>
                        <div className="MenuFirstElement__helper" id={"helper-" + id}>{name}</div>
                    </div>
                </Link>
            )
    }
})

export default MenuFirstElement;