import './Customer.css';
import React, { useEffect, useState } from 'react'
import Head from "../../common/marking/head"
import Contant from "../../common/marking/contant"
import api from "../../../services/api"
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import ButtonAdd from "../../common/elements/button-add"
import FilterButton from '../../common/elements/filter-button';
import TableCustomers from '../../common/table/TableCustomers'
import CustomerFilter from '../../common/elements/filters/customer-filter';
import customerStorage from '../../../storage/customer-filter'
import { compareArray } from "../../common/elements/filters/service"
import { useNavigate } from 'react-router-dom';

const Customers = observer(() => {

    const [ users, setUsers ] = useState(null)
    const [ orders, setOrders ] = useState(null)

    const navigate = useNavigate()

    const { 
        render,
        name,
        email,
        phone,
        manager,
        totalOrder,
        averageOrder,
        emailSubscription,
        sex,
        countOrder
    } = customerStorage;

    useEffect(() => {
        const getData = async () => {
            await api.post('/customer/get-customers', {})
                .then(res => {
                    let array = []
                    let nameData;
                    let emailData;
                    let phoneData;
                    let managerData;
                    let emailSubscriptionData;
                    let sexData;
                    let countOrderData;
                    let totalOrderData;
                    let averageOrderData;

                    if (name !== null && name !== "") nameData = res.data.filter(item => String(item.firstname).toLowerCase().includes(name.toLowerCase()) || String(item.surname).toLowerCase().includes(name.toLowerCase()) || String(item.patronymic).toLowerCase().includes(name.toLowerCase()))
                    else nameData = res.data

                    if (email !== null && email !== "") emailData = res.data.filter(item => String(item.email).toLowerCase().includes(email.toLowerCase()))
                    else emailData = res.data

                    if (phone !== null && phone !== "") phoneData = res.data.filter(item => String(item.phone).toLowerCase().includes(phone.toLowerCase()))
                    else phoneData = res.data

                    if (manager !== null && manager !== "all") managerData = res.data.filter(item => item.users !== null ? String(item.users.id) === manager : false)
                    else managerData = res.data
                
                    if (emailSubscription !== null && emailSubscription !== "all") emailSubscriptionData = res.data.filter(item => String(item.emailSubscription) === String(emailSubscription))
                    else emailSubscriptionData = res.data

                    if (sex !== null && sex !== "all") sexData = res.data.filter(item => String(item.sex.id) === sex)
                    else sexData = res.data

                    if (countOrder !== null && (countOrder.min !== 0 && countOrder.max !== 0)) {
                        countOrderData = res.data.filter(element => {
                            const userCountOrder = String(orders
                                                            .filter(item => String(item.customers.id) === String(element.id))
                                                            .reduce((acc, item) => acc + 1, 0))
                            if (Number(userCountOrder) >= Number(countOrder.min) && Number(userCountOrder) <= Number(countOrder.max)) return true;
                            else return false

                        });
                    } else countOrderData = res.data

                    if (totalOrder !== null && (totalOrder.min !== 0 && totalOrder.max !== 0)) {
                        totalOrderData = res.data.filter(element => {
                            const userCountOrder = String(orders
                                                            .filter(item => String(item.customers.id) === String(element.id))
                                                            .reduce((acc, item) => {
                                                                return acc + (item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0)) - item.discount
                                                            }, 0))
                            
                            if (Number(userCountOrder) >= Number(totalOrder.min) && Number(userCountOrder) <= Number(totalOrder.max)) return true;
                            else return false;

                        });
                    } else totalOrderData = res.data

                    if (averageOrder !== null && (averageOrder.min !== 0 && averageOrder.max !== 0)) {
                        averageOrderData = res.data.filter(element => {
                            const userCountOrderTotal = String(orders
                                                            .filter(item => String(item.customers.id) === String(element.id))
                                                            .reduce((acc, item) => {
                                                                return acc + (item.shopCard.reduce((a, i) => a + (i.count * i.goods.price), 0)) - item.discount
                                                            }, 0))
                            const userCountOrderCount = String(orders
                                                            .filter(item => String(item.customers.id) === String(element.id))
                                                            .reduce((acc, item) => acc + 1, 0))
                            let userCountOrder = userCountOrderTotal / userCountOrderCount
                            if (Number(userCountOrder) >= Number(averageOrder.min) && Number(userCountOrder) <= Number(averageOrder.max)) return true;
                            else return false;

                        });
                    } else averageOrderData = res.data

                    array.push(nameData)
                    array.push(emailData)
                    array.push(phoneData)
                    array.push(managerData)
                    array.push(emailSubscriptionData)
                    array.push(sexData)
                    array.push(countOrderData)
                    array.push(totalOrderData)
                    array.push(averageOrderData)
                    
                    setUsers(compareArray(array))
                })
                .catch(e => console.log(e))

        }
        getData()
    // eslint-disable-next-line
    },[render])

    useEffect(() => {
        const getData = async () => {
            await api.post('/customer/get-customers', {})
                .then(res => setUsers(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-orders', {})
                .then(res => setOrders(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (
        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Список клиентов</span>
                            <FilterButton />
                        </div>
                        <ButtonAdd method={() => navigate('/sales/customers/new')}/>
                    </div>
                    <CustomerFilter />
                </div> }/>
            <Contant contant={ 
                <div className="marking-contant__body">
                    { orders !== null && users !== null ? <TableCustomers users={ users } orders={ orders }/> : "" }
                </div> 
            }/>
        </div>
    )
})

export default Customers;