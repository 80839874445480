import { makeAutoObservable } from 'mobx';

class OrderStorage {
    
    render = 0;
    goodsInCard = [];

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setGoodsInCardArray = (array) => {
        this.goodsInCard = array
    }

    setGoodsInCard = (id) => {
        this.goodsInCard.push(id)
    }

    clearGoodsInCard = () => {
        this.goodsInCard = []
    }

    
}

const data = new OrderStorage();
export default data;