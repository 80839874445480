import './products.css';
import '../element.css';
import React, { useEffect, useState } from 'react'
import InputH from '../../elements/inpute/InputH';

const ProductCharacteristic = ({value, method, indexElement}) => {

    const [ state, setState ] = useState(null)
    const [ name, setName ] = useState("")
    const [ field, setField ] = useState("")


    useEffect(() => {
        setState(value)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state != null) {
            setName(state[0])
            setField(state[1])
        }
    }, [state])

    useEffect(() => {
        if (indexElement != null) {
            method([name, field, indexElement])
        }
    // eslint-disable-next-line
    }, [name, field])

    return (
        <div className='product-characterictic'>
            {
                state != null ?
                <div>
                    <InputH label={ "Название" } value={ name } method={ setName } />
                    <InputH label={ "Значение" } value={ field } method={ setField } />
                </div>
                : ""
            }
        </div>      
    )
}

export default ProductCharacteristic;