import "./elements.css"
import React from "react"

const ButtonNewOrder = ({ method }) => {

    return (
        <div className="button-add-order" onClick={ method }>Новый заказ</div>
    )
}

export default ButtonNewOrder