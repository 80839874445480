import './products.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import ButtonAdd from '../../common/elements/button-add';
import TableProducts from '../../common/table/TableProducts';
import api from "../../../services/api"
import { useNavigate } from 'react-router-dom';
import { COUNT_ELEMENT_ON_PAGE } from '../../../settings';
import PopupButton from '../../elements/popup/Popup-button';
import GoodFilter from '../../common/elements/filters/good-filter';

const Products = observer(() => {

    const [products, setProducts] = useState(null)
    const [goodCount, setGoodCount] = useState(0)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ shops, setShops ] = useState(null)
    const [ vat, setVat ] = useState(null)

    const [ filter, setFilter ] = useState({
        groupGoods: "all",
        shop: "all",
        active: "all",
        name: ""
    })

    const navigate = useNavigate()

    useEffect(() => {
        const getCountGoods = async() => {
            await api.post('/goods/count-goods')
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))

            let data = new FormData()
            data.append('offset', 0)
            data.append('limit', COUNT_ELEMENT_ON_PAGE)
            data.append('groupGoods', filter.groupGoods !== "all" ? Number(filter.groupGoods) : null)
            data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
            data.append('active', filter.active !== "all" ? filter.active : null)
            data.append('name', filter.name !== "" ? filter.name : null)

            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            await api.post("/orders/get-shops")
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
            await api.post("/goods/get-vats")
                .then(res => setVat(res.data))
                .catch(e => console.log(e))
        }
        getCountGoods()
    // eslint-disable-next-line
    },[])

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('groupGoods', null)
        data.append('shop', null)
        data.append('active', null)
        data.append('name', null)
        const getData = async () => {
            setIsLoading(true)
            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [currentPage])

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('groupGoods', filter.groupGoods !== "all" ? Number(filter.groupGoods) : null)
        data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
        data.append('active', filter.active !== "all" ? filter.active : null)
        data.append('name', filter.name !== "" ? filter.name : null )
        const getData = async () => {
            setIsLoading(true)
            await api.post('/goods/count-goods', data)
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))
            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [filter])

    return (
        products == null ?
            <div className='preloader-block'><div className='loader'></div></div> :

            <div className="GroupUser">
                <Head contant={ 
                    <div className='head-container'>
                        <div className='head-first-line'>
                            <div>
                                <span>Товары</span>
                                <FilterButton />
                            </div>
                            { shops != null && vat != null ? <PopupButton url={'/sales/products/import'} vat={ vat } buttonName={ 'Импорт товаров' } fields={[
                                    {name:'Название товара', value:'name'}, 
                                    {name:'Описание', value:'discription'}, 
                                    {name:'Производитель', value:'manufacturies'},
                                    {name:'URL', value:'url'},
                                    {name:'OpenCart Группа товаров', value:'openCartGroupId'},
                                    {name:'Цена', value:'price'},
                                    {name:'Магазины',value:"shops"}, 
                                    {name:'Ставка НДС',value:"vat"}, 
                                    {name:'OpenCard ID', value:'openCartCode'}
                                ]} shops={ shops } /> : '' }
                            <ButtonAdd method={() => navigate('/sales/products/new')}/>
                        </div>
                        <GoodFilter state={filter} method={setFilter}/>
                    </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        {
                            isLoading ?
                            <div className='preloader-block__fix'><div className='loader'></div></div> :
                            ''
                        }
                        { 
                            products !== null ? 
                            <TableProducts products={ products } countElements={goodCount} method={ setCurrentPage } /> 
                            : ""  }
                    </div> 
                }/>
            </div>
    )
})

export default Products;