import { Link } from "react-router-dom"
import "./elements.css"
import React from "react"

const InputLableDisabled = ({ lableText, inputId, inputeValue, href, text }) => {


    return (
        <div className="input-label">
            <label>{ lableText }</label>
            <input className="input-label-disabled" id={ inputId } value={ inputeValue } onChange={ () => {} } />
            <Link className="input-label__link" to={ href }>{ text }</Link>
        </div>
    )
}

export default InputLableDisabled