import './style.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableOrdersEasy from '../../common/table/TableOrdersEasy';
import TableProductsNeeds from '../../common/table/TableProductsNeeds';

const SupplyOrder = observer(() => {

    const mode = {
        ORDERS: 'orders',
        GOODS: 'goods'
    }

    // eslint-disable-next-line
    const [ state, setState ] = useState(mode.GOODS)
    const [ orders, setOrders ] = useState(null)
    const [ dictinary, setDictinary ] = useState([]);
    const [ products, setProducts ] = useState(null)

    useEffect(() => {
        const getData = async () => {
            let saldo = new Map();
            await api.post("/goods/get-good-saldo-reservation", [])
                .then(res => saldo = new Map(Object.entries(res.data)))
                .catch(e => console.log(e))
            await api.post("/orders/get-orders")
                .then(res => setOrders(res.data.filter(item => Number(item.orderStatus.id) === 6)))
                .catch(e => console.log(e))
            await api.post('/orders/get-order-status', {})
                .then(res => setDictinary(res.data.map(e => e.name)))
                .catch(e => console.log(e))
            await api.post("/goods/get-goods")
                .then(res => {
                    const list = []
                    Array.from(res.data).forEach(item => {
                        if (saldo.has(String(item.id))) {
                            list.push({
                                count: saldo.get(String(item.id)),
                                goods: item
                            })
                        } else {
                            list.push({
                                count: 0,
                                goods: item
                            })
                        }
                    })
                    setProducts(list.filter(item => item.count < 0))
                })
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (

        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Заказы к обеспечению</span>
                            {/* <FilterButton /> */}
                        </div>
                        {/* <div className='supply-top'>
                            <div 
                                className={ state === mode.ORDERS ? 'supply-button supply-button__active' : 'supply-button' } 
                                onClick={ () => setState(mode.ORDERS) } >Заказы</div>
                            <div 
                                className={ state === mode.GOODS ? 'supply-button supply-button__active' : 'supply-button' }
                                onClick={ () => setState(mode.GOODS) } >Товары</div>
                        </div> */}
                    </div>
                    {/* <CustomerFilter /> */}
                </div> }/>
            <Contant contant={ 
                <div className="supply-table">
                    {
                        state === mode.GOODS ?
                        <div className="marking-contant__body">
                            { products !== null ? <TableProductsNeeds products={ products }/> : "" }
                        </div> :
                        <div className="marking-contant__body">
                            { orders !== null ? <TableOrdersEasy orders={ orders } dictinary={ dictinary } /> : "" }
                        </div> 
                    }
                </div> 
            }/>
        </div>
    )
})

export default SupplyOrder;