import './style.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableOrdersReservation from '../../common/table/TableOrdersReservation';
import TableProductsReservation from '../../common/table/TableProductsReservation';

const GoodsReservation = observer(() => {

    const mode = {
        ORDERS: 'orders',
        GOODS: 'goods'
    }

    const [ state, setState ] = useState(mode.GOODS)
    const [ orders, setOrders ] = useState(null)
    const [ dictinary, setDictinary ] = useState([]);
    const [ products, setProducts ] = useState(null)

    useEffect(() => {
        const getData = async () => {
            await api.post("/orders/get-orders")
                .then(res => {
                    setOrders(res.data.filter(item => item.reservation === true))

                    const goodMaps = new Map()
                    res.data.filter(order => order.reservation === true)
                        .forEach(element => {
                            element.shopCard.forEach(item => {
                                if (goodMaps.has(Number(item.goods.id))) {
                                    const object = goodMaps.get(item.goods.id)
                                    const value = Number(object.count)
                                    const currentObject = {
                                        count: value + Number(item.count),
                                        goods: item.goods
                                    }
                                    goodMaps.set(Number(item.goods.id), currentObject)
                                } else {
                                    const currentObject = {
                                        count: Number(item.count),
                                        goods: item.goods
                                    }
                                    goodMaps.set(Number(item.goods.id), currentObject)
                                }
                            })
                        });
                    setProducts(Array.from(goodMaps.values()))
                })
                .catch(e => console.log(e))
            await api.post('/orders/get-order-status', {})
                .then(res => setDictinary(res.data.map(e => e.name)))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (

        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Резерв товаров</span>
                            {/* <FilterButton /> */}
                        </div>
                        <div className='supply-top'>
                            <div 
                                className={ state === mode.ORDERS ? 'supply-button supply-button__active' : 'supply-button' } 
                                onClick={ () => setState(mode.ORDERS) } >Заказы</div>
                            <div 
                                className={ state === mode.GOODS ? 'supply-button supply-button__active' : 'supply-button' }
                                onClick={ () => setState(mode.GOODS) } >Товары</div>
                        </div>
                    </div>
                    {/* <CustomerFilter /> */}
                </div> }/>
            <Contant contant={ 
                <div className="supply-table">
                    {
                        state === mode.GOODS ?
                        <div className="marking-contant__body">
                            { products !== null ? <TableProductsReservation products={ products } /> : "" }
                        </div> :
                        <div className="marking-contant__body">
                            { orders !== null ? <TableOrdersReservation orders={ orders } dictinary={ dictinary } /> : "" }
                        </div> 
                    }
                </div> 
            }/>
        </div>
    )
})

export default GoodsReservation;