import "./elements.css"
import React, { useEffect } from "react"
import api from "../../../services/api"
import { observer } from "mobx-react-lite"
import dataStorage from "../../../storage/data"

const InputPhoto = observer(({ lableText, userValue }) => {

    const { setReloader } = dataStorage;

    const deletePhoto = async (id) => {
        let dataFile = new FormData()
            dataFile.append('id', userValue.id)

            await api.post("user/delete-user-image", dataFile)
                .then(res => setReloader())
                .catch(e => console.log(e))
    }

    useEffect(() => {
        const input = document.querySelector('#input-file')

        input.addEventListener('change', async (event) => {
            let dataFile = new FormData()
            dataFile.append('file', event.target.files[0])
            dataFile.append('id', userValue.id)

            await api.post("user/change-user-image", dataFile)
                .then(res => setReloader())
                .catch(e => console.log(e))
        })
    // eslint-disable-next-line
    }, [])

    return (
        <div className="input-label">
            <label>{ lableText }</label>
            <div className="input-photo" style={{  backgroundImage: "url('.." + userValue.photo + "')" }}></div>
            <div className={ userValue.photo != null ? "input-delete" : "input-delete v-none" } onClick={ () => deletePhoto(userValue.id) }></div>
            <label htmlFor="input-file" id="upload-photo">Загрузить фотографию</label>
            <input type="file" id="input-file" accept="image/png, image/jpg, image/jpeg" onChange={ () => {} } />
        </div>
    )
})

export default InputPhoto