import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const CustomerFilterName = observer(({ change }) => {

    const [state, setState ] = useState(null)
    const [min, setMin ] = useState(0)
    const [max, setMax ] = useState(0)

    useEffect(() => {
        setState({
            min: min,
            max: max
        })
    }, [min, max])

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element' data-order-filter="4">
            <label>Количество заказов</label>
            <div className='filter-element-row'>
                <input id="count-order-min" type="number" onChange={(e) => e.target.value !== "" ? setMin(e.target.value) : setMin(0)} />
                <input id="count-order-max" type="number" onChange={(e) => e.target.value !== "" ? setMax(e.target.value) : setMax(0)} />
            </div>
        </div>
        
    )
})

export default CustomerFilterName;