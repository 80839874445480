import './popup.css';
import React, { useEffect, useState } from 'react'
import api from '../../../services/api'
import { useLocation, useNavigate } from 'react-router-dom';
import ImportedDataGood from './ImportedDataGood';

const PopupGood = () => {

    let DATA_ELEMENT = null

    const navigator = useNavigate()

    const location = useLocation();
    const fields = location.state.fields;
    const shops = location.state.shops;
    const vat = location.state.vat;

    const [ data , setData ] = useState([])
    const [ fileName , setFileName ] = useState("")
    const [ map, setMap ] = useState(new Map())
    const [ render, setRender ] = useState(1)
    const [ checkedShops, setCheckedShops ] = useState([]);
    const [ importData, setImportData ] = useState(null)
    const [ vatState, setVatState ] = useState(0)

    const showArea = () => {
        navigator('/sales/products')
    }

    const parseCSV = (file) => {
        const lines = file.replace(/\r/g, "").split("\n")
        lines.pop()

        const array = Array.from(lines).map(item => item.split(";"))

        return array
    }

    const uploadFile = (e) => {
        const reader = new FileReader()
        reader.readAsText(e.target.files[0])
        setFileName(e.target.files[0].name)

        reader.onload = () => setData(parseCSV(reader.result))
        reader.onerror = () => console.log(reader.error)
    }

    const changeStage = (id) => {
        const areaStageOne = document.querySelector('#area_stage_1')
        const areaStageTwo = document.querySelector('#area_stage_2')
        const areaStageThree = document.querySelector('#area_stage_3')

        switch (id) {
            case 1:
                areaStageOne.classList.add('d-none')
                areaStageTwo.classList.remove('d-none')
                areaStageThree.classList.add('d-none')
                break
            case 2:
                areaStageOne.classList.remove('d-none')
                areaStageTwo.classList.add('d-none')
                areaStageThree.classList.add('d-none')
                break
            case 3:
                areaStageOne.classList.add('d-none')
                areaStageTwo.classList.add('d-none')
                areaStageThree.classList.remove('d-none')
                break
                        
            default:
                break
        }
    }

    const dragAndDrop = (element) => {
        if (DATA_ELEMENT != null) {
            const index = DATA_ELEMENT.dataset.value.lastIndexOf("_")
            const target = DATA_ELEMENT.dataset.value.substring(index + 1,)
            const drag = element.innerHTML
            map.set(target, drag)
            setRender(render + 1)
        }
    }

    const isMapInsert = () => {
        const keys = Array.from(map.keys())
        let isOk= true
        keys.forEach(item => {
            if(map.get(item) == null) isOk = false
        })
        return isOk
    }

    const chengeCheckboxShops = (item) => {
        let arr = checkedShops
        if (arr.includes(item)) {
            let index = arr.indexOf(item)
            delete arr[index]
        } else {
            arr.push(item)
        }
        map.set('shops', arr.filter(Number))
        setCheckedShops(arr)
        setRender(render + 1)
    }

    const chengeCheckboxVat = (id) => {
        setVatState(id)
        map.set('vat', 1)
        setRender(render + 1)
    }

    const checkShops = (item) => {
        if (checkedShops.includes(item)) return true
        else return false
    }

    const checkImport = () => {
        changeStage(3)

        let indexArray = Array.from(map.keys())
        indexArray.forEach(item => {
            if (typeof map.get(item) === 'string') {
                let index = data[0].indexOf(map.get(item))
                map.set(item, index)
            }
        })
        let resultArray = []
        data.forEach((item, index) => {
            if (index !== 0) {
                let obj = {}
                indexArray.forEach(element => obj[element] = item[map.get(element)])
                resultArray.push(obj)
            }
        })

        console.log(resultArray)

        setImportData(null)
        const getData = async () => {
            await api.post('/goods/check-product', JSON.stringify(resultArray))
                .then(res => setImportData(res.data))
                .catch(e => console.log(e))
        }
        getData()
    }

    useEffect(() => {
        if (data.length > 0) {
            const lableTitle = document.querySelector('#file-upload')
            const btnNext = document.querySelector('#area_btn_1')

            let isCorrect = true
            let countColumns = data[0].length

            data.forEach(item => {
                // console.log(item.length !== countColumns)
                // if (item.length !== countColumns) console.log(item)
                if (item.length !== countColumns) isCorrect = false
            })

            if (isCorrect) {
                btnNext.classList.remove('d-none')
                lableTitle.innerHTML = fileName
            } else {
                btnNext.classList.add('d-none')
                lableTitle.innerHTML = "Ошибка в файле, нарушена структура"
            }
        }
    // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        if (fields != null) {
            let tempMap = map
            fields.forEach(item => tempMap.set(item.value, null))
            setMap(tempMap)
        }
    // eslint-disable-next-line
    }, [])

    return (
        <div className='popup'>
            <div className='popup-area' id='popup-area'>
                <div className='popup-area__form'>
                    <div className='popup-area-form__top'>
                        <div className='popup-area-form-top__button' onClick={() => showArea()}>
                            <svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><line x1="16" y1="16" x2="48" y2="48"></line><line x1="48" y1="16" x2="16" y2="48"></line></g></svg>
                        </div>
                    </div>
                    <div className='popup-area-form__middle' id="area_stage_1">
                        <div className='popup-area-form-middle__area'>
                            <div className='popup-area-form-middle__title'>Выбор файла</div>
                            <div className='popup-area-form-middle__description'>Загрузите свой файл и сопоставьте поля.<br />Поддерживается формат .CSV в кодировке UTF-8 с разделителем ";"</div>
                            <div className='popup-area-form-middle__input'><label htmlFor="file-upload" id="file-upload">Выберете файл</label><input type='file' name="file-upload" accept=".csv, .CSV" onChange={(e) => uploadFile(e)}/></div>
                            <div className='popup-area-form-middle__next d-none' id="area_btn_1" onClick={ () => changeStage(1) }>Продолжить</div>
                        </div>
                    </div>
                    <div className='popup-area-form__middle d-none' id="area_stage_2">
                        <div className='popup-area-form-middle__area'>
                            <div className='popup-area-form-middle__title'>Сопоставление полей</div>
                            <div className='popup-area-form-middle__table'>
                                <div className='popup-area-form-middle__col1'>
                                    {
                                        fields.map(field => {
                                            return(
                                                <div className='popup-area-form-middle-col__field-row' key={ Math.random() }>
                                                    <div className='popup-area-form-middle-col__field' data-name={ "name_" + field.value }>{ field.name }</div>
                                                    <div 
                                                        className={ map.get(field.value) != null ? 'popup-area-form-middle-col__field popup-area-form-middle-col-field__active' : 'popup-area-form-middle-col__field' } 
                                                        data-value={ "value_" + field.value } 
                                                        // Zone
                                                        onDragEnter={ e => DATA_ELEMENT = e.target }
                                                        onDragOver={ e => e.preventDefault() }
                                                        onDragLeave={ e => DATA_ELEMENT = null }
                                                        >{ 
                                                            field.value !== 'shops' ? 
                                                            ( field.value !== vat ? 
                                                                (map.get(field.value) != null ? map.get(field.value) : 'нет' ) : 
                                                                (map.get('vat'))) : 
                                                                    map.get('shops') != null ? 
                                                                    map.get('shops').join(',') : 
                                                                    '' 
                                                        }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='popup-area-form-middle__col2'>
                                    <div className='popup-area-form-middle__dict'>
                                        <div className=''>Магазины</div>
                                        {
                                            shops.map( item => <div className='popup-area-form-middle-dict__row' key={ Math.random() }>
                                                <input type="checkbox" checked={ checkShops(item.id) } value={ item.id } onChange={ () => chengeCheckboxShops(item.id) } />
                                                <label>{ item.name }</label>
                                            </div> )
                                        }
                                        <div className=''>Ставка НДС</div>
                                        {
                                            vat.map( item => <div className='popup-area-form-middle-dict__row' key={ Math.random() }>
                                                <input type="radio" name="vat" value={item.id} checked={vatState === item.id} onChange={ () => chengeCheckboxVat(item.id) } />
                                                <label>{ item.name }</label>
                                            </div> )
                                        }
                                    </div>
                                    <div className='popup-area-form-middle__area-fields'>
                                        {
                                            data[0] != null ? data[0].map(element => <div 
                                                key={ Math.random() }
                                                className='popup-area-form-middle-col__element' 
                                                draggable="true" 
                                                // Element
                                                onDragStart={ e => e.dataTransfer.setData('element', element) }
                                                onDragEnd={ e => dragAndDrop(e.target) }
                                                >{ element }</div>)
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='popup-area-form-middle__next' id="area_btn_2" onClick={ () => changeStage(2) }>Назад</div>
                            { isMapInsert() ? <div className='popup-area-form-middle__next' onClick={ () => checkImport() }>Импортировать</div> : '' }
                        </div>
                    </div>
                    <div className='popup-area-form__middle d-none' id="area_stage_3">
                        <ImportedDataGood data={importData} backMethod={changeStage} importData={setImportData}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupGood;