import './MenuSecondLinks.css'
import orderImg from './img/order.svg';
import { Link } from "react-router-dom";
import menuStore from '../../../storage/menu';
import React from 'react'
import { showNotifications, showRequest, showProfile, hideSecondMenu } from '../MenuFirstElement/MenuFirstElement';

const MenuSecondLinks = ({data}) => {

    // eslint-disable-next-line
    const { activeFirstMenu, chooseFirstMenu, secondMenuShowBlocks, showSecondMenuBlock, hideSecondMenuBlock } = menuStore;

    const chooseSecondMenu = (id) => {
        let subMenuLinks = Array.from(document.querySelectorAll('.MenuSecondLink'))
        subMenuLinks.forEach(element => {
            if (element.classList.contains('MenuSecondLink__active')) {
                element.classList.remove('MenuSecondLink__active')
            }
        })

        const div = document.querySelector('[id="' + id + '"]')
        div.classList.add('MenuSecondLink__active')
    }

    const showBlock = (event, blockName) => {
        let blockId;
        switch (event.target.nodeName) {
            case 'use':
                blockId = event.target.parentElement.parentElement.parentElement.parentElement.id;
                break
            case 'DIV':
                blockId = event.target.parentElement.parentElement.id;
                break;
            default:
                blockId = event.target.parentElement.parentElement.parentElement.id;
                break;
        }

        const block = Array.from(document.querySelectorAll('[data-block="' + blockName + '"]'))
        let div = document.querySelector('[id="' + blockId + '"]');
        let svg = div.querySelector('svg');

        block.forEach(element => {
            if (element.classList.contains('MenuSecondSubLink__active')) {
                element.classList.remove('MenuSecondSubLink__active')
            } else {
                element.classList.add('MenuSecondSubLink__active')
            }
        })
        
        if (secondMenuShowBlocks.has(blockId)) {
            div.classList.remove('MenuSecondBlock__active')
            svg.setAttribute("transform", "rotate(0)")
            hideSecondMenuBlock(blockId)
        } else {
            div.classList.add('MenuSecondBlock__active')
            svg.setAttribute("transform", "rotate(180)")
            showSecondMenuBlock(blockId)
        }
    }

    return (
        <div className="MenuSecondLinks">
            {
                data.contant.map(({classN, url, name, attach}) => {
                    if (classN == null) {
                        if ({attach}.attach.length > 0) {
                            let blockName = url;
                            return (
                                <div 
                                    className="MenuSecondBlock" 
                                    key={"block_" + url} 
                                    id={"block_" + url}
                                    onClick={() => {}}>
                                    <div className="MenuSecondLink MenuSecondHeaderBlock">
                                        <div className="MenuSecondHeader" key={url}>{name}</div>
                                        <div 
                                            className="MenuSecondHeaderLink"
                                            onClick={(event) => showBlock(event, url)}>
                                            <svg>
                                                <use href={orderImg + "#caret_down"} />
                                            </svg>
                                        </div>
                                    </div>
                                    {
                                        attach.map(({url, name}) => {
                                            return <Link 
                                                to={url} 
                                                className="MenuSecondLink MenuSecondSubLink" 
                                                id={url}
                                                key={"menu_" + url} 
                                                data-block={blockName}
                                                onClick={() => chooseSecondMenu(url)}>{name}</Link>
                                        })
                                    }
                                </div>
                            )
                        } else {
                            return <Link 
                                to={url} 
                                className="MenuSecondLink" 
                                key={url}
                                id={url}
                                onClick={() => chooseSecondMenu(url)}>{name}</Link>
                        }
                    } else {
                        const id = classN.replace('menu_element-', '');
                        switch (id) {
                            case 'notifications':
                                return <div className={"MenuSecondLink " + classN} key={classN} onClick={() => {
                                    showNotifications()
                                }}>{name}</div>
                            case 'requests':
                                return <div className={"MenuSecondLink " + classN} key={classN} onClick={() => {
                                    showRequest()
                                }}>{name}</div>
                            case 'profile':
                                return <div className={"MenuSecondLink " + classN} key={classN} onClick={() => {
                                    showProfile()
                                }}>{name}</div>
                            case 'analytics':
                            case 'settings':
                                return <div className={"MenuSecondLink " + classN} key={classN} onClick={() => {
                                    chooseFirstMenu(id)
                                }}>{name}</div>
                            default: 
                                return <Link 
                                    to={id}
                                    className={"MenuSecondLink " + classN} 
                                    key={classN} 
                                    onClick={() => {
                                    chooseFirstMenu(id)
                                    hideSecondMenu()
                                }}>{name}</Link>
                        }
                        
                    }
                })
            }
        </div>
    )
}

export default MenuSecondLinks;