import './filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import customerStorage from "../../../../storage/customer-filter"
import api from "../../../../services/api"
import FilterPopUpCustomer from './filter-popup-customer';
import CustomerFilterName from './customer-filters/name'
import CustomerFilterEmail from './customer-filters/email'
import CustomerFilterPhone from './customer-filters/phone'
import CustomerFilterSex from './customer-filters/sex'
import CustomerFilterEmailSubscription from './customer-filters/email-subscription'
import CustomerFilterManager from './customer-filters/managers'
import CustomerFilterTotalOrder from './customer-filters/total-order'
import CustomerFilterAverageOrder from './customer-filters/average-order'
import CustomerFilterCountOrder from './customer-filters/count-order'

const CustomerFilter = observer(() => {

    const { render, setRender,
        setName,
        setEmail,
        setPhone,
        setManager,
        setTotalOrder,
        setAverageOrder,
        setEmailSubscription,
        setSex,
        setCountOrder
    } = customerStorage;

    const [ managers, setManagers ] = useState(null)

    const applyFilter = () => {
        setRender()
    }

    const selectChangeColor = () => {
        const selects = document.querySelectorAll('select')
        selects.forEach(item => {
            if (item.value === "") item.classList.add('filter-select_placeholder')
            else item.classList.remove('filter-select_placeholder')
        })
    }

    const showPopUp = () => {
        const area = document.querySelector('#filter-zone-popup')
        area.classList.add('filter-zone-popup__active')
    }

    useEffect(() => {

        const getData = async() => {
            await api.post('/orders/get-managers', {})
                .then(res => setManagers(res.data.map(item => {
                    return {
                        id: item.id,
                        name: `${item.username !== null ? item.username : ''} ${item.surname !== null ? item.surname : ''} ${item.patronymic !== null ? item.patronymic : ''}`
                    }
                })))
                .catch(e => console.log(e))
        }

        getData();

        selectChangeColor()
    }, [render])

    useEffect(() => {
        let filterOrder = JSON.parse(window.localStorage.getItem('filter-customer'))
        let dataFilters = document.querySelectorAll('[data-order-filter]')
        if (filterOrder !== null) {
            dataFilters.forEach(item => {
                if (filterOrder.includes(Number(item.dataset.orderFilter)))
                    item.classList.remove('d-none')
                else
                    item.classList.add('d-none')
            })
        }
    })

    return (
        <div>
            <FilterPopUpCustomer />
            <div className='filter-zone' id="filter-zone">
                    <div className='filter-zone-row'>

                        <CustomerFilterName change={ setName } />
                        <CustomerFilterEmail change={ setEmail } />
                        <CustomerFilterPhone change={ setPhone } />
                        { managers !== null ? <CustomerFilterManager data={ managers } selectChangeColor={ selectChangeColor } change={ setManager } /> : ''}
                        <CustomerFilterTotalOrder change={ setTotalOrder } />
                        <CustomerFilterAverageOrder change={ setAverageOrder } />
                        <CustomerFilterEmailSubscription selectChangeColor={ selectChangeColor } change={ setEmailSubscription } />
                        <CustomerFilterSex selectChangeColor={ selectChangeColor } change={ setSex } />
                        <CustomerFilterCountOrder change={ setCountOrder } />

                    </div>
                <div className='filter-btn' onClick={ applyFilter }>Применить</div>
                <div className='filter-btn-settings' onClick={() => showPopUp()}></div>
            </div>
        </div>
    )
})

export default CustomerFilter;