import './filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import ordersStorage from "../../../../storage/orders"
import OrdersFilterNumber from "./orders-filters/number"
import OrdersFilterTypesOrder from './orders-filters/types_order';
import OrdersFilterCustomer from './orders-filters/customer';
import OrdersFilterManager from "./orders-filters/managers"
import OrdersFilterStatusPaymant from "./orders-filters/status_paymant"
import OrdersFilterGoods from "./orders-filters/goods"
import OrdersFilterShops from "./orders-filters/shops"
import OrdersFilterTypesPayment from "./orders-filters/types_payment"
import OrdersFilterTypesDelivery from "./orders-filters/types_delivery"
import FilterPopUp from './filter-popup';
import api from "../../../../services/api"
import ordersFilterStorage from "../../../../storage/orders-filter"

const OrdersFilter = observer(() => {

    const { render, setRender } = ordersStorage;

    const [ typeOrder, setTypeOrder ] = useState(null)
    const [ managers, setManagers ] = useState(null)
    const [ statusPayment, setStatusPayment ] = useState(null)
    const [ shops, setShops ] = useState(null)
    const [ typePayment, setTypePayment ] = useState(null)
    const [ typeDelivery, setTypeDelivery ] = useState(null)

    const { setRenderFilter,
        setId, 
        setOrderType, 
        setCustomer, 
        setManager, 
        setPaymentStatus, 
        setGoods,
        setShopsFilter,
        setPaymentType,
        setDeliveryType } = ordersFilterStorage;

    const applyFilter = () => {
        setRenderFilter()
        setRender()
    }

    const selectChangeColor = () => {
        const selects = document.querySelectorAll('select')
        selects.forEach(item => {
            if (item.value === "") item.classList.add('filter-select_placeholder')
            else item.classList.remove('filter-select_placeholder')
        })
    }

    const showPopUp = () => {
        const area = document.querySelector('#filter-zone-popup')
        area.classList.add('filter-zone-popup__active')
    }

    useEffect(() => {
        const getData = async() => {
            await api.post('orders/get-orders-filter', {})
                .then(res => {
                    setManagers(res.data.managers.map(item => {
                        return {
                            id: item.id,
                            name: `${item.username !== null ? item.username : ''} ${item.surname !== null ? item.surname : ''} ${item.patronymic !== null ? item.patronymic : ''}`
                        }
                    }))
                    setTypeOrder(res.data.typeOrders)
                    setStatusPayment(res.data.paymentStatuses)
                    setShops(res.data.shops)
                    setTypePayment(res.data.typePayments)
                    setTypeDelivery(res.data.typeDeliveries)
                })
        }
        getData();
        selectChangeColor()
    }, [render])

    useEffect(() => {
        let filterOrder = JSON.parse(window.localStorage.getItem('filter-orders'))
        let dataFilters = document.querySelectorAll('[data-order-filter]')
        if (filterOrder !== null) {
            dataFilters.forEach(item => {
                if (filterOrder.includes(Number(item.dataset.orderFilter)))
                    item.classList.remove('d-none')
                else
                    item.classList.add('d-none')
            })
        }
    })

    return (
        <div>
            <FilterPopUp />
            <div className='filter-zone' id="filter-zone">
                    <div className='filter-zone-row'>
                        <OrdersFilterNumber change={ setId } />
                        { typeOrder !== null ? <OrdersFilterTypesOrder data={ typeOrder } selectChangeColor={ selectChangeColor } change={ setOrderType } /> : ''}
                        <OrdersFilterCustomer change={ setCustomer } />
                        { managers !== null ? <OrdersFilterManager data={ managers } selectChangeColor={ selectChangeColor } change={ setManager } /> : ''}
                        { statusPayment !== null ? <OrdersFilterStatusPaymant data={ statusPayment } selectChangeColor={ selectChangeColor } change={ setPaymentStatus } /> : ''}
                        <OrdersFilterGoods change={ setGoods } />
                        { shops !== null ? <OrdersFilterShops data={ shops } selectChangeColor={ selectChangeColor } change={ setShopsFilter } /> : ''}
                        { typePayment !== null ? <OrdersFilterTypesPayment data={ typePayment } selectChangeColor={ selectChangeColor } change={ setPaymentType } /> : ''}
                        { typeDelivery !== null ? <OrdersFilterTypesDelivery data={ typeDelivery } selectChangeColor={ selectChangeColor } change={ setDeliveryType } /> : ''}
                    </div>
                <div className='filter-btn' onClick={ applyFilter }>Применить</div>
                <div className='filter-btn-settings' onClick={() => showPopUp()}></div>
            </div>
        </div>
    )
})

export default OrdersFilter;