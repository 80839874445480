import './MenuSecondTitle.css'
import React from 'react'

const MenuSecondTitle = ({title}) => {
    return (
        <div className="MenuSecondTitle">
            <div>{title}</div>
        </div>
    )
}

export default MenuSecondTitle;