export const orderMenu = {
    name: "Продажи",
    contant: [{
        url: '/sales/orders',
        name: 'Заказы',
        attach: []
    },
    {
        url: '/sales/customers',
        name: 'Клиенты',
        attach: [
            {
                url: '/sales/customers',
                name: 'Список'
            }
            // {
            //     url: '/sales/customers-corporate',
            //     name: 'Корпоративные'
            // },
            // {
            //     url: '/sales/loyalty',
            //     name: 'Программа лояльность'
            // },
            // {
            //     url: '/sales/letters',
            //     name: 'Письма'
            // },
            // {
            //     url: '/sales/sms',
            //     name: 'SMS'
            // }
        ]
    },
    {
        url: 'sales/products',
        name: 'Товары и склад',
        attach: [
            {
                url: 'sales/products',
                name: 'Товары'
            },
            {
                url: 'sales/product-groups',
                name: 'Товарные группы'
            },
            {
                url: 'goods/provider',
                name: 'Поставщики'
            },
            {
                url: 'goods/receipt',
                name: 'Поступление товара'
            },
            {
                url: 'goods/reservation',
                name: 'Резерв товаров'
            },
            // {
            //     url: 'sales/movements',
            //     name: 'Перемещения товаров'
            // },
            // {
            //     url: 'sales/inventories',
            //     name: 'Инвентаризация'
            // },
            {
                url: 'sales/saldo',
                name: 'Остатки'
            }
        ]
    },
    {
        url: 'sales/supply',
        name: 'Управление заказами',
        attach: [
            {
                url: 'sales/supply',
                name: 'Заказы к обеспечению'
            },
            {
                url: 'sales/moving',
                name: 'Отгрузка заказов самовывозом'
            },
            {
                url: 'sales/shipments',
                name: 'Отгрузка заказов в службу доставки'
            }
        ]
    },
    {
        url: 'sales/managers',
        name: 'Менеджеры',
        attach: []
    },
    // {
    //     url: 'sales/costs',
    //     name: 'Финансы',
    //     attach: []
    // }
    ]
}

export const analyticMenu = {
    name: "Аналитика",
    contant: [{
        url: '/analytics/orders',
        name: 'Заказы',
        attach: []
    },
    {
        url: 'analytics/customers',
        name: 'Клиенты',
        attach: []
    },
    {
        url: 'analytics/products',
        name: 'Товары',
        attach: [{
            url: 'analytics/products',
            name: 'Виджеты'
        },
        {
            url: 'analytics/report',
            name: 'Отчеты по продажам'
        }]
    }]
}

export const settingsMenu = {
    name: "Настройки",
    contant: [{
        url: 'settings/users',
        name: 'Пользователи',
        attach: [{
            url: 'settings/users',
            name: 'Пользователи'
        },
        {
            url: 'settings/group',
            name: 'Группы'
        }]
    },
    {
        url: 'settings/shops',
        name: 'Магазины',
        attach: [{
            url: 'settings/shops',
            name: 'Магазины'
        },
        {
            url: 'settings/warehouse',
            name: 'Склады'
        }]
    },
    {
        url: 'settings/order-types',
        name: 'Справочники',
        attach: [{
            url: 'settings/order-types',
            name: 'Типы заказов'
        },
        {
            url: 'settings/lead-types',
            name: 'Способы оформления'
        },
        {
            url: 'settings/delivery-types',
            name: 'Типы доставок'
        },
        {
            url: 'settings/payment-types',
            name: 'Типы оплат'
        },
        {
            url: 'settings/payment-status',
            name: 'Статусы оплат'
        }]
    },
    {
        url: 'settings/order-status',
        name: 'Статусы',
        attach: [{
            url: 'settings/order-status',
            name: 'Статусы'
        },
        {
            url: 'settings/order-status-group',
            name: 'Группы статусов'
        },
        {
            url: 'settings/order-status-way',
            name: 'Переходы статусов'
        }]
    },
    {
        url: 'settings/common',
        name: 'Общие',
        attach: [{
            url: 'settings/common',
            name: 'Общие настройки'
        }]
    }]
}

export const tasksMenu = {
    name: "",
    contant: []
}

export const chatMenu = {
    name: "",
    contant: []
}

export const mailMenu = {
    name: "",
    contant: []
}

export const chinaOrdersMenu = {
    name: "",
    contant: []
}

export const notificationsMenu = {
    name: "",
    contant: []
}

export const requestsMenu = {
    name: "",
    contant: []
}

export let moreMenu = {
    name: "Меню",
    contant: []
}