import './popup.css';
import React, { useState } from 'react'
import PreLoader from '../preloader/Preloader';
import api from '../../../services/api'

const ImportedDataGood = ({ data, backMethod, importData }) => {

    const [ result, setResult ] = useState(null)

    const importArray = () => {
        importData(null)
        const getData = async () => {
            await api.post('/goods/import-good', JSON.stringify(data))
                .then(res => setResult(res.data))
                .catch(e => console.log(e))
        }
        getData()
    }

    return (
        <div className='popup-area-form-middle__area'>
            {   
                data == null ? <PreLoader /> : ''
            }
            {
                result == null ? '' : <PreLoader />
            }
            <div className='popup-area-form-middle__title'>Данные, которые будут загружены</div>
                <div className='imported-data-buttons'>
                <div className='popup-area-form-middle__next imported-data-button__left' onClick={ () => backMethod(1) }>Назад</div>
                <div className='popup-area-form-middle__next imported-data-button__right' onClick={ () => importArray() }>Импортировать</div>
                </div>

                <div className='imported-data-table'>
                    <div className='imported-data-row'>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>ID</div>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>Название</div>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>Описание</div>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>Производитель</div>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>Группа</div>
                        <div className='imported-data-row__column-good imported-data-row-column__head'>URL</div>
                    </div>
                </div>

            {
                data != null ?
                <div className='imported-data-table'>
                    { data.map(item => <div className="imported-data-row" key={ Math.random() }>
                        <div className='imported-data-row__column-good'>{ item.openCartCode }</div>
                        <div className='imported-data-row__column-good'>{ item.name }</div>
                        <div className='imported-data-row__column-good'>{ item.discription }</div>
                        <div className='imported-data-row__column-good'>{ item.manufacturies }</div>
                        <div className='imported-data-row__column-good'>{ item.openCartGroupId }</div>
                        <div className='imported-data-row__column-good'>{ item.url }</div>
                    </div> 
                    )}
                </div> :
                ''
            }
        </div>
    )
}

export default ImportedDataGood;