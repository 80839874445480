import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Head from '../../common/marking/head';
import FilterButton from '../../common/elements/filter-button';
import ShopFilter from '../../common/elements/filters/shop-filter';
// eslint-disable-next-line
import ButtonAdd from '../../common/elements/button-add';
import { useNavigate } from 'react-router-dom';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import shopFilterStorage from "../../../storage/shop-filter"
import { compareArray } from "../../common/elements/filters/service"
import TableStatusGroup from '../../common/table/TableStatusGroup';

const StatusesGroup = observer(() => {

    // eslint-disable-next-line
    const navigator = useNavigate()
    const [ shops, setShops ] = useState(null)
    const { status, name, render } = shopFilterStorage;

    useEffect(() => {

        const getData = async () => {
            await api.post('/orders/get-order-status-group', {})
                .then(res => {
                    let sortRes = res.data.sort((a, b) => a.priority - b.priority)

                    console.log(sortRes)

                    let array = []
                    let statusData;
                    let nameData;

                    if (status !== null && status !== "all") statusData = sortRes.filter(item => String(item.status) === status)
                    else statusData = sortRes

                    if (name !== null && name !== "") nameData = sortRes.filter(item => item.name.toLowerCase().includes(name.toLowerCase()))
                    else nameData = sortRes

                    array.push(statusData)
                    array.push(nameData)

                    setShops(compareArray(array))

                })
                .catch(e => console.log(e))
        }

        getData()
    // eslint-disable-next-line
    },[render])

    return (
        <div className="Component">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Группы статусов</span>
                            <FilterButton />
                        </div>
                        {/* <ButtonAdd method={() => {
                            navigator('/settings/order-status-group/new')
                        }}/> */}
                    </div>
                    <ShopFilter />
                </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        { shops !== null ? <TableStatusGroup data={ shops } /> : '' }
                    </div> 
            }/>
        </div>
    )
})

export default StatusesGroup;