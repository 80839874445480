import './Customer.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from "../../../services/api"
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';
import Row from '../../common/marking/row';
import Orders from './Orders';
import Notes from './Notes';
import Tasks from './Tasks';
import ButtonNewOrder from '../../common/elements/button-new-order';
import ButtonEdit from '../../common/elements/button-edit';

const CustomerEdit = observer(() => {

    const [ customer, setCustomer ] = useState(null)
    const [ orders, setOrders ] = useState(null)
    const [ task, setTask ] = useState(null)
    let filteredOrders = [];
    let countOrders = 0;
    let totalPriceOrders = 0;

    const { id } = useParams();
    const navigator = useNavigate();

    useEffect(() => {
        const getCustomer = async (id) => {
            let data = new FormData()
            data.append('id', id)

            await api.post('/customer/get-customer', data)
                .then(res => setCustomer(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-orders', {})
                .then(res => setOrders(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-tasks', {})
                .then(res => setTask(res.data))
                .catch(e => console.log(e))
        }
        getCustomer(id)
        // eslint-disable-next-line
    }, [])
    
    if (orders !== null) {
        filteredOrders = orders.filter(item => String(item.customers.id) === String(customer.id));
        countOrders = filteredOrders.length;
        totalPriceOrders = filteredOrders.reduce((acc, item) => acc + (item.shopCard.reduce((a, i) => a + i.count * i.goods.price, 0) - item.discount), 0);
    }

    return (
        <div className='GroupUser'>
            { customer === null ? <div className='preloader-block'><div className='loader'></div></div> :
                <div className='head-container'>
                    <div className="Order">
                        <div className='order-top'>
                            <div className='order-links'>
                                <Link to={'/'}>Главная</Link>
                                <span>→</span>
                                <Link to={'/sales/customers'}>Список клиентов</Link>
                            </div>
                            <div className='order-h1-btn'>
                                <span className='customers-column customers-column__right'>
                                    { customer.firstname !== null ? customer.firstname : "" } { customer.surname !== null ? customer.surname : "" } { customer.patronymic !== null ? customer.patronymic : "" }
                                </span>
                                <div className='customers-column__btn'>
                                    <ButtonNewOrder method={() => {
                                        navigator('/sales/orders/new', { state: { key: customer.id } })
                                    }}/>
                                    <ButtonEdit method={() => {
                                        navigator('/sales/customers/edit/3')
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <div className="customers-column customers-column__right">

                            <div className="CustomerContainer">
                                <div className="customer-container">
                                    <div className="customer-container__lable"><div>ОСНОВНЫЕ ДАННЫЕ</div></div>
                                    <Row contant={ 
                                        <div>
                                            <div className='profile-contant__top'>
                                                <div className='profile-img'><img src={ customer.photo } alt='' /></div>
                                                <div className='profile-text-customer'>
                                                    <h5>{ customer.surname !== null ? customer.surname : "" } { customer.firstname !== null ? customer.firstname : "" } { customer.patronymic !== null ? customer.patronymic : "" }</h5>
                                                    <span>{ customer.sex !== null ? customer.sex.id === 1 ? 'Муж.' : 'Жен.' : 'Неопределен' }</span>
                                                </div>
                                            </div>
                                            <div className='profile-contant__top'>
                                                { customer.customerTag.map(item => item.id).includes(1) ? <div className='customers-icon customers-icon__active'><span className='customers-icon__vip'></span></div> : <div className='customers-icon'><span className='customers-icon__vip'></span></div> }
                                                { customer.customerTag.map(item => item.id).includes(2) ? <div className='customers-icon customers-icon__active'><span className='customers-icon__bad'></span></div> : <div className='customers-icon'><span className='customers-icon__bad'></span></div> }
                                            </div>
                                            <div className='customers-tag'>
                                                
                                            </div>
                                            <div className='customers-bottom'>
                                                <span className='customers-bottom__left'>Дата регистрации: </span>
                                                <span className='customers-bottom__right'>{ new Date(customer.registration).toLocaleDateString() }</span>
                                            </div>
                                        </div>
                                    } />
                                </div>
                            </div>

                            <div className="CustomerContainer">
                                <div className="customer-container">
                                    <div className="customer-container__lable"><div>КЛЮЧЕВЫЕ ПОКАЗАТЕЛИ</div></div>
                                    <Row contant={ 
                                        <div>
                                            <div className='profile-contant__top-data'>

                                                <div className='customers-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ orders !== null ? totalPriceOrders : " " }</span>
                                                        <span className='customers-data-rub'>₽</span>
                                                    </div>
                                                    <div className='customers-data-description'>Общая сумма заказов</div>
                                                </div>

                                                <div className='customers-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ orders !== null ? totalPriceOrders / countOrders : "" }</span>
                                                        <span className='customers-data-rub'>₽</span>  
                                                    </div>
                                                    <div className='customers-data-description'>Средний чек</div>
                                                </div>

                                                <div className='customers-data-container'>
                                                    <div className='customers-data'>
                                                        <span className='customers-data-value'>{ orders !== null ? countOrders : "" }</span>
                                                    </div>
                                                    <div className='customers-data-description'>Количество заказов</div>
                                                </div>

                                            </div>
                                                
                                            <div className='customers-bottom'>
                                                <span className='customers-bottom__left'>Первый заказ: </span>
                                                <span className='customers-bottom__right'>{ orders !== null ? new Date(Math.min(...filteredOrders.map(item => new Date(item.dateCreated).getTime()))).toLocaleDateString() : "" }</span>
                                                <span className='customers-bottom__left'>Последний заказ: </span>
                                                <span className='customers-bottom__right'>{ orders !== null ? new Date(Math.max(...filteredOrders.map(item => new Date(item.dateCreated).getTime()))).toLocaleDateString() : "" }</span>
                                            </div>
                                        </div>
                                    } />
                                </div>
                            </div>

                        </div>
                        <div className="customers-column">
                            
                            <div className="CustomerContainer__notices">
                                <div className="customer-container">
                                    <Row contant={ 
                                        <div>
                                            <div>
                                                <div className='profile-contant__top-v2'>
                                                    <div className='customer-notises-head'>
                                                        <span className='customer-notises-head__label-active'>Заметки</span>
                                                    </div>
                                                    <div className='customer-notises-head'>
                                                        <span className='customer-notises-head__label'>Задачи</span>
                                                    </div>
                                                </div>
                                                <div className='profile-contant__top'>
                                                    <textarea className='customer-notises-textarea'></textarea>
                                                </div>
                                                <div className='customer-notices-buttom'>
                                                    <ButtonSave text="Сохранить" method={ ()=> {  } }/>
                                                </div>
                                            </div>
                                        </div>                                        
                                    } />
                                </div>
                            </div>

                            <div className='customer-lable'>Заказы в работе</div>

                            {
                                orders !== null && customer !== null && task !== null ?
                                <div>
                                    <Orders filteredOrders={ filteredOrders } />
                                    <Notes customer={ customer } /> 
                                    <Tasks customer={ customer } /> 
                                </div>
                                : ""
                            }

                        </div>
                        
                    </div>
                    <Foother contant={ 
                        <div className="user-foother">
                            <ButtonSave text="Сохранить" method={ ()=> {  } }/>
                            <div id="user-save__success">Сохранено</div>
                            <div id="user-save__wrong">Ошибка сохранения</div>
                        </div>
                    } />
                </div>
            }
        </div>
    )
})

export default CustomerEdit;