import { useNavigate } from 'react-router-dom';
import './popup.css';
import React from 'react'

const PopupButton = ({ buttonName, fields, shops, url, vat }) => {

    const navigate = useNavigate();

    return (
        <div className='head-first-line__button-group'>
            {
                vat == null ?
                <div className='popup-button' onClick={() => navigate(url, {state: {fields: fields, shops: shops}})}>{ buttonName }</div> :
                <div className='popup-button' onClick={() => navigate(url, {state: {fields: fields, shops: shops, vat: vat}})}>{ buttonName }</div>
            }
        </div>
    )
}

export default PopupButton;