import './User.css';
import React, { useEffect, useState } from 'react'
import Head from "../../common/marking/head"
import ContantUser from "../../common/marking/contant-user"
import Foother from "../../common/marking/foother"
import ButtonSave from "../../common/elements/button-save"
import UserIDSetting from "../../contents/User/UserIDSetting"
import userStorage from "../../../storage/user"
import dataStorage from "../../../storage/data"
import api from "../../../services/api"
import { observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';

const User = observer(() => {

    const { id } = useParams();
    const [ user, setUser ] = useState(null);
    const [ groups, setGroups ] = useState(null);
    const { userSettings, setUserSettingsPrimary } = userStorage;
    const { reloader } = dataStorage;
    let navigate = useNavigate()

    const removeStatus = () => {
        setTimeout(() => {
            const success = document.querySelector('#user-save__success')
            const wrong = document.querySelector('#user-save__wrong')
            if (success != null) success.classList.remove('user-save__active')
            if (wrong != null) wrong.classList.remove('user-save__active')
        }, 2000)
    }

    const editUser = async (data) => {

        const success = document.querySelector('#user-save__success')
        const wrong = document.querySelector('#user-save__wrong')

        let user = {
            id: data.id,
            username: data.username,
            surname: data.surname,
            patronymic: data.patronymic,
            jobTitle: data.jobTitle,
            phone: data.phone,
            roles: data.roles
        }

        await api.post('user/edit-user', user)
            .then(response => {
                success.classList.add('user-save__active')
                wrong.classList.remove('user-save__active')
                removeStatus()

            })
            .catch(e => {
                success.classList.remove('user-save__active')
                wrong.classList.add('user-save__active')
                removeStatus()
            })
    }

    useEffect(() => {
        const getUser = async(id) => {

            let data = new FormData()
            data.append('id', id)

            await api.post('/user/get-user', data)
                .then(res => {
                    setUser(res.data)
                    setUserSettingsPrimary(res.data)
                })
                .catch(e => console.log(e))

            await api.post('user/get-roles', {})
                .then(result => {
                    setGroups(result.data.roles.map(item => {
                        return{
                            name: item.name,
                            fullName: item.fullName
                        }
                    }))
                })
                .catch(e => console.log(e))
        }

        getUser(id)

    // eslint-disable-next-line
    },[reloader])

    return (
        <div className="User">
            <Head contant={ <div><span><span>Пользователь&nbsp;</span><span>{ user !== null ? user.username : ''}</span></span></div> } link={ <Link className="marking-head_link" onClick={ () => navigate(-1) }>Назад</Link> }/>
            <ContantUser contant={ 
                <div className="marking-contant__body">
                    { user !== null && groups !== null ? <UserIDSetting user={ user } groups={ groups } /> : ''}
                </div> 
            }/>
            <Foother contant={
                <div className="user-foother">
                    <ButtonSave text="Сохранить" method={ ()=> { editUser(userSettings) } }/>
                    <div id="user-save__success">Сохранено</div>
                    <div id="user-save__wrong">Ошибка сохранения</div>
                </div>
            }/>
        </div>
    )
})

export default User;