import './products.css';
import '../element.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import api from '../../../services/api'
import ContentBlock from '../../common/elements/content-block';
import InputH from '../../elements/inpute/InputH';
import InputHSelect from '../../elements/inpute/InputHSelect';
import Foother from '../../common/marking/foother';
import ButtonSaveData from '../../elements/button/Save';
import InputHDate from '../../elements/inpute/InputHDate';
import TableCardShopProvider from '../../common/table/TableCardShopProvider';
import CardShopProvider from '../Orders/card-shop-provider';
import orderStorage from '../../../storage/order'

const GoodsReceiptNew = observer(() => {

    // eslint-disable-next-line
    const { goodsInCard, setGoodsInCardArray, clearGoodsInCard, render, setRender } = orderStorage;

    const { id } = useParams()
    const [ receipt, setReceipt ] = useState(null)

    const [ providers, setProviders ] = useState(null)
    const [ shops, setShops ] = useState(null)
    const [ warehouse, setWarehouse ] = useState(null)
    const [ goods, setGoods ] = useState(null)
    const [ goodsGroup, setGoodsGroup ] = useState(null)
    const [ goodsTag, setGoodsTag ] = useState(null)
    // eslint-disable-next-line
    const [ order, setOrder ] = useState(null)

    // fields values
    const [ fieldNumber, setFieldNumber ] = useState(null)
    const [ fieldDate, setFieldDate ] = useState(null)
    const [ fieldProvider, setFieldProvider ] = useState(null)
    const [ fieldShop, setFieldShop ] = useState(null)
    const [ fieldWarehouse, setFieldWarehouse ] = useState(null)
    const [ shopCard, setShopCard ] = useState(null)

    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
          if (typeof object[key] !== 'object') formData.append(key, object[key])
          else formData.append(key, JSON.stringify(object[key]))
        })
        return formData;
    }
    let dataGoodObject = {
        id: id != null ? id : 0,
        number: fieldNumber != null ? String(fieldNumber) : "",
        date: fieldDate != null ? String(fieldDate) : "",
        provider: fieldProvider != null ? Number(fieldProvider) : "",
        shop:fieldShop != null ? Number(fieldShop) : "",
        warehouse: fieldWarehouse != null ? Number(fieldWarehouse) : "",
        shopCard: goodsInCard.map(item => {
            return {
                id: Number(item.id),
                count: Number(item.count)
            }
        })
    }

    let dataGood = getFormData(dataGoodObject)

    useEffect(() => {
        clearGoodsInCard()

        const getGood = async () => {
            let data = new FormData()
            data.append("id", Number(id))
            await api.post('/goods/get-good-receipt', data)
                .then(res => {
                    setReceipt(res.data)
                })
                .catch(e => console.log(e))
        }
        const getData = async () => {
            await api.post('/goods/get-providers', {})
                .then(res => setProviders(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-shops', {})
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-warehouse', {})
                .then(res => setWarehouse(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-goods', {})
                .then(res => setGoods(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-goods-group', {})
                .then(res => setGoodsGroup(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-goods-tag', {})
                .then(res => setGoodsTag(res.data))
                .catch(e => console.log(e))
        }
        if (id != null) {
            getGood()
        }
        getData()
    // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (receipt != null) {
            setFieldNumber(receipt.number != null ? receipt.number : "")
            setFieldDate(receipt.date != null ? receipt.date : "")
            setFieldProvider(receipt.provider != null ? receipt.provider.id : "")
            setFieldShop(receipt.shop != null ? receipt.shop.id : "")
            setFieldWarehouse(receipt.warehouse != null ? receipt.warehouse.id : "")
            setShopCard(receipt.shopCards != null ? receipt.shopCards : [])
        }
    // eslint-disable-next-line
    }, [receipt])

    return (
        <div>
            <div>
                <CardShopProvider 
                    warehouseFilter={warehouse} 
                    shopsFilter={shops}
                    goodsGroupFilter={goodsGroup}
                    goodsTagFilter={goodsTag}
                    goodsFilter={goods}
                    value={shopCard}
                    method={setShopCard}
                    />
                <div className="Element">
                    <div className='element-top'>
                        <div className='element-links'>
                            <Link to={'/'}>Главная</Link>
                            <span>→</span>
                            <Link to={'/goods/receipt'}>Поступление товара</Link>
                        </div>
                        <div className='element-h1'>{ id != null ? "Накладная № " + fieldNumber : 'Новая поставка' }</div>
                    </div>
                    <ContentBlock title="Данные документа" name="main" defaultState={true} button={null} content={
                        <div className='ContentBlockBody'>
                            <div className='content-body-padding-70'>
                                <InputH label={'Номер документа'} value={fieldNumber != null ? fieldNumber : ""} method={setFieldNumber} marginY={25} />
                                <InputHDate label={'Дата документа'} value={fieldDate != null ? fieldDate : ""} method={setFieldDate} marginY={25} />
                                { providers !== null ? <InputHSelect label={'Поставщик'} marginY={25} data={ providers } value={ fieldProvider != null ? fieldProvider : "" } method={ setFieldProvider } required={true} /> : "" }
                                { shops !== null ? <InputHSelect label={'Магазин'} marginY={25} data={ shops } value={ fieldShop != null ? fieldShop : "" } method={ setFieldShop } required={true} /> : "" }
                                { warehouse !== null ? <InputHSelect label={'Склад'} marginY={25} data={ warehouse } value={ fieldWarehouse != null ? fieldWarehouse : "" } method={ setFieldWarehouse } required={true} /> : "" }
                            </div>
                        </div>
                    }/>
                    <ContentBlock title="Состав заказа" name="card-shop" defaultState={true} button={
                        <div className='table-goods-top-btn'>Добавить товар</div>
                        } content={
                            <div className='ContentBlockBody'>
                                { goodsGroup !== null && goods !== null ? <TableCardShopProvider goods={ goods } activeGoods={shopCard != null ? shopCard : []} edit={ id != null ? true : false } /> : '' }
                            </div>
                        }/>
                    <Foother left={true} contant={
                        <div className="element-foother">
                            <ButtonSaveData text="Сохранить" url={'/goods/save-goods-receipt'} params={dataGood}/>
                            <div id="element-save__success" className="d-none">Сохранено</div>
                            <div id="element-save__wrong" className="d-none">Ошибка сохранения</div>
                        </div>
                    }/>
                </div>
            </div>
        </div>
    )
})

export default GoodsReceiptNew;