import '../orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const InputType2 = observer(({ title, name, choose }) => {

    const [state, setState] = useState(null);

    useEffect(() => {
        choose(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='order-element-type-2'>
            <label htmlFor={name}>{ title }</label>
            <input id={name} onChange={e => setState(e.target.value)}/>
        </div>
        
    )
})

export default InputType2;