import { makeAutoObservable } from 'mobx';

class UserFilterStorage {
    name = ''
    email = ''
    group = ''
    render = 0

    constructor() {
        makeAutoObservable(this);
    }

    setName = (name) => {
        this.name = name;
    }

    setEmail = (email) => {
        this.email = email;
    }

    setGroup = (group) => {
        this.group = group;
    }

    setRender = () => {
        this.render++
    }
    
}

const data = new UserFilterStorage();
export default data;