import './inputes.css';
import React, { useEffect, useState } from 'react'

const InputHTextarea = ({
    marginY,        // Margin top and bottom : Integer
    label,          // Label's title : String
    classInput,     // Class for input
    inputId,        // Textarea's id : String
    value,          // Textarea's value : String
    size,           // Textarea's size : String ('small', 'big'), Integer
    placeholder,    // Textarea's placeholder : String
    method,         // Textarea's onChange method : setState()
    disabled,        // Textarea's disabled : Boolean
    required        // Required field or not
}) => {
    
    const [ state, setState ] = useState( value != null ? value : '' )

    useEffect(() => {
        if (method != null && typeof method === 'function')
            method( state )
    // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        setState(value)
    }, [value])

    return (
        
        <div 
            className='input-horizontal' 
            style={{ 
                marginBottom: marginY != null ? marginY + 'px' : 10 + 'px',
                marginTop: marginY != null ? marginY + 'px' : 10 + 'px' }} >
            <label className='input-horizontal__label'>{ label !== null ? label : '' }</label>
            <textarea 
                id={ inputId }
                className={ `input input-horizontal__textarea ${ classInput != null ? classInput : '' }` }
                style={ 
                    size != null ?
                        typeof size === 'string' ?
                            size === 'big' ?
                                { height: '98px' }
                            : size === 'small' ?
                                { height: '40px' }
                            : 
                                { }
                        : typeof size === 'number' ?
                                { height: size + 'px' }
                            :
                                { } 
                    :   
                        { }
                }
                placeholder={ placeholder !== null ? placeholder : '' }
                value={ state }
                onChange={ e => setState(e.target.value) }
                disabled={ disabled ? true : false }
                data-required={ required != null ? true : false } />
        </div>
        
    )
}

export default InputHTextarea;