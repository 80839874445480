import '../orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const Input = observer(({ title, name, value, change, marginY }) => {
    
    const [ state, setState ] = useState(null)

    useEffect(() => {
        change( state )
    // eslint-disable-next-line
    }, [state])

    return (
        
        <div className='order-element' style={{ marginBottom: marginY !== null ? marginY + 'px' : 0 + 'px', marginTop: marginY !== null ? marginY + 'px' : 0 + 'px' }}>
            <label htmlFor={name}>{ title }</label>
            <input id={name} value={ value } onChange={e => setState(e.target.value)}/>
        </div>
        
    )
})

export default Input;