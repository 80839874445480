import "./elements.css"
import React, { useState } from "react"

const ContentBlock = ({ title, defaultState, content, name, button }) => {

    const [state, setState] = useState(defaultState)

    const showGoods = () => {
        const area = document.querySelector('#card-shop')
        area.classList.add('card-shop__active')
    }

    const hideMenu = () => {
        const body = document.querySelector(`#order-content_${name}`)
        const btnClose = document.querySelector(`#order-close_${name}`)

        if (state) {
            body.classList.add('content-block-content__hide')
            btnClose.classList.add('content-block-close__active')
        } else {
            body.classList.remove('content-block-content__hide')
            btnClose.classList.remove('content-block-close__active')
        }

        setState(!state);
    }

    return (
        <div className="ContentBlock" id={ `content-${name}` }>
            <div className="content-block-top">
                <div className="content-block-title">{ title }</div>
                <div className="content-block-div">
                    { button !== null ? <div className="table-goods-top-btn" onClick={() => showGoods()}>Добавить товар</div> : '' }
                    <div className="content-block-close" id={'order-close_' + name} onClick={() => hideMenu()}></div>
                </div>
            </div>
            <div className="content-block-content" id={'order-content_' + name}>{ content }</div>
        </div>
    )
}

export default ContentBlock