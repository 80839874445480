import './products.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import ButtonAdd from '../../common/elements/button-add';
import TableProductsGroup from '../../common/table/TableProductsGroup';
import api from "../../../services/api"
import { useNavigate } from 'react-router-dom';
import PopupButton from '../../elements/popup/Popup-button';
import { COUNT_ELEMENT_ON_PAGE } from '../../../settings';
import GroupGoodFilter from '../../common/elements/filters/group-good-filter';

const ProductsGroup = observer(() => {

    const [products, setProducts] = useState(null)
    const [groupGoods, setGroupGoods] = useState(null)
    const [ shops, setShops ] = useState(null)
    const [goodCount, setGoodCount] = useState(0)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ isLoading, setIsLoading ] = useState(false)

    const [ filter, setFilter ] = useState({
        parent: "all",
        shop: "all",
        active: "all",
        name: ""
    })

    const navigate = useNavigate()

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('parent', filter.parent !== "all" ? Number(filter.parent) : null)
        data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
        data.append('active', filter.active !== "all" ? filter.active : null)
        data.append('name', filter.name !== "" ? filter.name : null)
        const getData = async () => {
            setIsLoading(true)
            await api.post("/goods/get-group-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [currentPage])

    useEffect(() => {
        const getData = async () => {
            let data = new FormData()
            data.append('offset', 0)
            data.append('limit', COUNT_ELEMENT_ON_PAGE)
            data.append('parent', null)
            data.append('shop', null)
            data.append('active', null)
            data.append('name', null)

            await api.post('/goods/count-group-goods', data)
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))

            await api.post("/goods/get-group-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))

            await api.post("/goods/get-good-count")
                .then(res => setGroupGoods(res.data))
                .catch(e => console.log(e))
            await api.post("/orders/get-shops")
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('parent', filter.parent !== "all" ? Number(filter.parent) : null)
        data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
        data.append('active', filter.active !== "all" ? filter.active : null)
        data.append('name', filter.name !== "" ? filter.name : null )
        const getData = async () => {
            setIsLoading(true)
            await api.post('/goods/count-group-goods', data)
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))
            await api.post("/goods/get-group-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [filter])

    return (
        groupGoods == null ?
            <div className='preloader-block'><div className='loader'></div></div> :
            <div className="GroupUser">
                <Head contant={ 
                    <div className='head-container'>
                        <div className='head-first-line'>
                            <div>
                                <span>Товарные группы</span>
                                <FilterButton />
                            </div>
                            <div className='head-first-line__button-group'>
                                { shops != null ? <PopupButton url={'/sales/product-groups/import'} buttonName={ 'Импорт групп' } fields={[{name:'Название группы', value:'name'}, {name:'Магазины',value:"shops"}, {name:'OpenCard ID', value:'openCartCode'}, {name:'OpenCard ParentID', value:'openCartParent'}, {name:'URL', value:'url'}]} shops={ shops } /> : '' }
                                <ButtonAdd method={() => navigate('/sales/product-groups/new')}/>
                            </div>
                        </div>
                        <GroupGoodFilter state={filter} method={setFilter}/>
                    </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        {
                            isLoading ?
                            <div className='preloader-block__fix'><div className='loader'></div></div> :
                            ''
                        }
                        { 
                            products !== null && 
                            groupGoods !== null ? 
                            <TableProductsGroup group={ products } groupGoods={ groupGoods } countElements={goodCount} method={ setCurrentPage }/> : "" }
                    </div> 
                }/>
            </div>
    )
})

export default ProductsGroup;