import { observer } from 'mobx-react-lite';
import React from 'react';
import './MenuSecond.css';
import MenuSecondTitle from '../MenuSecondTitle/MenuSecondTitle'
import MenuSecondLinks from '../MenuSecondLinks/MenuSecondLinks'
import menuStore from '../../../storage/menu';
import { useEffect, useMemo, useState } from 'react';
import { orderMenu,
    analyticMenu,
    tasksMenu,
    chatMenu,
    mailMenu,
    chinaOrdersMenu,
    notificationsMenu,
    requestsMenu,
    settingsMenu,
    moreMenu } from './MenuData';

const MenuSecond = observer( () => {

    const { activeFirstMenu } = menuStore;
    const [ secondMenu, setSecondMenu ] = useState(orderMenu);
    const [height, setHeight] = useState(window.innerHeight);

    const addHiddenelement = () => {
        const menus = Array.from(document.querySelectorAll('.MenuFirstElement'));
        moreMenu.contant = []

        let hiddenMenu = menus.filter(element => window.getComputedStyle(element).display === 'none')

        hiddenMenu.forEach(element => {
            let object = {
                id: element.querySelector('div').id,
                classN: 'menu_element-' + element.querySelector('div').querySelector('div').id.replace('helper-', ''),
                url: '#',
                name: element.querySelector('div').querySelector('div').innerHTML,
                attach: []
            }

            if( element.querySelector('div').querySelector('div').innerHTML !== 'Показать') {
                moreMenu.contant.push(object)
            }
        })
    }

    useMemo(() => {

        switch(activeFirstMenu) {
            case "orders":
                setSecondMenu(orderMenu);
                break;
            case "analytics":
                setSecondMenu(analyticMenu);
                break;
            case "tasks":
                setSecondMenu(tasksMenu);
                break;
            case "chat":
                setSecondMenu(chatMenu);
                break;
            case "mail":
                setSecondMenu(mailMenu);
                break;
            case "chinaOrders":
                setSecondMenu(chinaOrdersMenu);
                break;
            case "notifications":
                setSecondMenu(notificationsMenu);
                break;
            case "requests":
                setSecondMenu(requestsMenu);
                break;
            case "settings":
                setSecondMenu(settingsMenu);
                break;
            case "profile":
                setSecondMenu(analyticMenu);
                break;
            case 'more':
                setSecondMenu(moreMenu);
                break;
            default:
                setSecondMenu(orderMenu);
                break;

                
        }
    // eslint-disable-next-line
    }, [activeFirstMenu]);

    useMemo(() => {
        addHiddenelement();
    // eslint-disable-next-line 
    }, [height])

    useEffect(() => {
        const handleResize = (event) => {
            setHeight(event.target.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
        };
    }, []);

    return (
        <div className="MenuSecond">
            <div id="containerMenu">
                <MenuSecondTitle title={secondMenu.name} />
                <div className="MenuSecondContainer">
                    <MenuSecondLinks data={secondMenu} />
                </div>
            </div>
        </div>
    )
})

export default MenuSecond;