import { observer } from 'mobx-react-lite';
import './filters.css';
import React, { useEffect, useState } from 'react'
import managersStorage from "../../../../storage/managers-filter"

const FilterPopUpCustomer = observer(() => {

    const defaultValueFilters = [1, 2, 3, 4]
    const { setRender } = managersStorage;

    let filterOrder = JSON.parse(window.localStorage.getItem('filter-customer'))

    const [ activeFilter, setActiveFilter ] = useState(
        filterOrder !== null ? filterOrder : defaultValueFilters
    );
    const [ group, setGroup ] = useState([])

    const filters = [
            {id: 1, name: 'ФИО', group: 'Персональные данные',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='1' checked={activeFilter.includes(1)} onChange={(e) => handleChange(e)}/><label>ФИО</label></div>},
            {id: 2, name: 'Сумма заказов', group: 'Показатели', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='2' checked={activeFilter.includes(2)} onChange={(e) => handleChange(e)}/><label>Сумма заказов</label></div>},
            {id: 3, name: 'Средний чек', group: 'Показатели', 
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='3' checked={activeFilter.includes(3)} onChange={(e) => handleChange(e)}/><label>Средний чек</label></div>},
            {id: 4, name: 'Количество продаж', group: 'Показатели',
                code: <div className="filter-popup-element" key={Math.random()}>
                    <input type="checkbox" data-value='4' checked={activeFilter.includes(4)} onChange={(e) => handleChange(e)}/><label>Количество продаж</label></div>}
    ]

    const handleChange = (e) => {
        activeFilter.includes(Number(e.target.dataset.value))
            ? setActiveFilter(activeFilter.filter(x => x !== Number(e.target.dataset.value)))
            : setActiveFilter([...activeFilter, Number(e.target.dataset.value)])
    }

    const resetDefault = () => {
        setActiveFilter(defaultValueFilters)
    }

    const saveFilters = () => {
        window.localStorage.setItem('filter-customer', JSON.stringify(activeFilter))
        setRender()
        hidePopUp()
    }

    const hidePopUp = (e) => {
        const area = document.querySelector('#filter-zone-popup')
        area.classList.remove('filter-zone-popup__active')
    }

    useEffect(() => {
        let set = new Set()
        filters.forEach(item => set.add(item.group))
        setGroup(Array.from(set))
    // eslint-disable-next-line
    },[activeFilter])

    return (
        <div id="filter-zone-popup">
            <div className='filter-zone-popup-background'>
                <div className='filter-zone-popup-top'>
                    <span>Фильтры</span>
                    <div id='filter-zone-popup__close' onClick={e => hidePopUp()}></div>
                </div>
                <div className='filter-zone-popup-center'>
                    <div className='filter-zone-popup-center__left'>
                        {
                            filters.filter(item => activeFilter.includes(item.id)).map(e => {
                            return (
                                <div className="filter-popup-active-element" key={Math.random()}>
                                    <span>{ e.name }</span>
                                    <div data-value={ e.id } onClick={(e) => handleChange(e)}></div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className='filter-zone-popup-center__right'>
                        {
                            group.map(item => {
                                let array = filters.filter(element => element.group === item)
                                array.unshift({
                                    code: <div className="filter-popup-head" key={ Math.random() }>{ item }</div>
                                })
                                return array.map(item => item.code)
                            })
                        }
                    </div>
                </div>
                <div className='filter-zone-popup-bottom'>
                    <div className='filter-zone-popup-button-save' onClick={() => saveFilters() }>Сохранить</div>
                    <span className='filter-zone-popup-button-reset' onClick={() => resetDefault() }>Сбросить</span>
                </div>
            </div>
        </div>
    )
})

export default FilterPopUpCustomer;