import './filters.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import usersFilterStorage from "../../../../storage/users-filter"
import dataStorage from "../../../../storage/data"

const UsersFilter = observer(() => {

    const { roles } = dataStorage;
    const { setName, setEmail, setGroup, setRender } = usersFilterStorage;

    const applyFilter = () => {
        setRender()
    }

    return (
        <div>
            <div className='filter-zone' id="filter-zone">
                <div>
                    <div>
                        <label htmlFor="name">ФИО</label>
                        <input id="name" onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="email">E-mail</label>
                        <input id="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="group">Группы</label>
                        <select onChange={(e) => setGroup(e.target.value)}>
                            <option value=""></option>
                            {
                                roles.map(item => <option key={ item[1] } value={ item[0].toLowerCase() }>{ item[0] }</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className='filter-btn' onClick={ applyFilter }>Применить</div>
            </div>
        </div>
    )
})

export default UsersFilter;