import "./marking.css"
import React from 'react'

const Column = ({ contant }) => {

    return (
        <div className="container__column">
            { contant }
        </div>
    )
}

export default Column;