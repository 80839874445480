import './preloader.css';
import React from 'react'

const PreLoader = () => {
    return (  
        <div className='preloader-block__fix'>
            <div className='loader'></div>
        </div>
    )
}

export default PreLoader;
