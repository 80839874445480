import '../orders.css';
import React, { useEffect, useMemo, useState } from 'react'
import Column from '../../../common/marking/column';
import api from "../../../../services/api"
import { useMask } from '@react-input/mask';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeRu from 'air-datepicker/locale/ru';

const DeliveryType = ({ value, method }) => {

    const [ catalogTypeDelivery, setCatalogTypeDelivery ] = useState(null)
    const [ min, setMin ] = useState(null)
    const [ max, setMax ] = useState(null)
    const [ deliveryType, setDeliveryType ] = useState(null)
    const [ deliveryDate, setDeliveryDate ] = useState(null)
    const [ deliveryPrice, setDeliveryPrice ] = useState(null)
    const [ deliverySupplierPrice, setDeliverySupplierPrice ] = useState(null)

    const maskMin = useMask({ mask: '__:__', replacement: { _: /\d/ } })
    const maskMax = useMask({ mask: '__:__', replacement: { _: /\d/ } })

    useMemo(() => {
        setDeliveryDate(value.deliveryDate != null ? typeof value.deliveryDate === 'object' ? `${value.deliveryDate[2] < 10 ? `0${value.deliveryDate[2]}` : value.deliveryDate[2]}.${value.deliveryDate[1] < 10 ? `0${value.deliveryDate[1]}` : value.deliveryDate[1]}.${value.deliveryDate[0]}` : value.deliveryDate : '')
        setDeliveryType(value.deliveryMode != null ? value.deliveryMode : 'all')
        setDeliveryPrice(value.deliveryPrice != null ? value.deliveryPrice : '')
        setDeliverySupplierPrice(value.deliverySupplierPrice != null ? value.deliverySupplierPrice : '')
        setMin(value.deliveryStartTime != null ? value.deliveryStartTime : '')
        setMax(value.deliveryEndTime != null ? value.deliveryEndTime : '')
    // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        const state = {
            deliveryMode: deliveryType,
            deliveryDate: deliveryDate,
            deliveryStartTime: min,
            deliveryEndTime: max,
            deliveryPrice: deliveryPrice,
            deliverySupplierPrice: deliverySupplierPrice
        }
        method(state)
    // eslint-disable-next-line
    }, [deliveryType, deliveryDate, min, max, deliveryPrice, deliverySupplierPrice])

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-type-delivery', {})
                .then(res => setCatalogTypeDelivery(res.data))
                .catch(e => console.log(e))
        }
        getData()
        new AirDatepicker('#delivery-date', {
            locale: localeRu,
            onSelect: e => setDeliveryDate(e.formattedDate)
        })
    // eslint-disable-next-line
    }, [])

    return (
        <div>
             <Column contant={
                <div>
                    {/* <InputHRange label={'Время доставки'} method={ e => setTime(e.target.value) } placeholderMin={'__:__'} placeholderMax={'__:__'} classInput={'delivery-input'} maskMin={{mask: '__:__', replacement: { _: /\d/ }}} maskMax={{mask: '__:__', replacement: { _: /\d/ }}} /> */}

                    <div className="input-label">
                        <label>Тип доставки</label>
                        <select id="delivery-type" name='delivery' value={ deliveryType != null ? deliveryType : 'all' } onChange={ (e) => setDeliveryType(e.target.value) }>
                            <option value="all">Выберите значение</option>
                            {
                                catalogTypeDelivery != null ?
                                catalogTypeDelivery.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                                : ''
                            }
                        </select>
                    </div>

                    <div className="input-label">
                        <label>Дата отгрузки</label>
                        <input id="delivery-date" name='delivery' defaultValue={ deliveryDate != null ? deliveryDate : '' }/>
                    </div>

                    <div className="input-label">
                        <label>Время доставки</label>
                        <div className={ 'container-range' }>
                            <input 
                                id="delivery-tite-min"
                                ref={ maskMin }
                                placeholder={ '__:__' }
                                className='input-range'
                                value={ min != null ? min : '' }
                                onChange={ e => setMin(e.target.value) } />
                            <input 
                                id="delivery-tite-min"
                                ref={ maskMax }
                                placeholder={ '__:__' }
                                className='input-range'
                                value={ max != null ? max : '' }
                                onChange={ e => setMax(e.target.value) } />
                        </div>
                    </div>

                    <div className="input-label">
                        <label>Стоимость доставки</label>
                        <input id="delivery-price" name='delivery' value={ deliveryPrice != null ? deliveryPrice : '' } onChange={ (e) => setDeliveryPrice(e.target.value) }/>
                    </div>

                    <div className="input-label">
                        <label>Себестоимость доставки</label>
                        <input id="delivery-supplier-price" name='delivery' value={ deliverySupplierPrice != null ? deliverySupplierPrice : '' } onChange={ (e) => setDeliverySupplierPrice(e.target.value) }/>
                    </div>

                </div>
             }/>
        </div>
    )
}

export default DeliveryType;