import '../setting.css';
import React from 'react'
import { observer } from 'mobx-react-lite';

const Textarea = observer(({ title, name }) => {

    return (

        <div className='setting-element'>
            <label htmlFor={name}>{ title }</label>
            <textarea id={name} />
        </div>
        
    )
})

export default Textarea;