import './Customer.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import Row from '../../common/marking/row';

const Notes = observer(({ customer }) => {

    return (
        customer.notes.map(customerItem => {
            return (
                <div key={ Math.random() } className="CustomerContainer">
                    <div className="customer-container">
                        <Row contant={
                            <div key={ Math.random() }>
                                <div className='customer-order-top'>
                                    <div className='customer-order-top__column'>
                                        <span>Заметка</span>
                                    </div>
                                    <div className='customer-order-top__column-right'>{ new Date(customerItem.dateCreated).toLocaleString() }</div>
                                </div>
                                <div className='customer-order-body'>
                                    <div className='customer-order-body__good'>
                                        <div className='customer-order-body__good-note'>{ customerItem.note }</div>
                                        <div className='customer-order-body__good-note-close' onClick={() => {}}></div>
                                    </div>
                                </div>
                            </div>
                        }/>
                    </div>
                </div>
            )
        })
    )
})

export default Notes;