import '../orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const Checkbox = observer(({ data, choose }) => {

    const [dataArray, setDataArray] = useState([])

    const handleChange = (e) => {
        dataArray.includes( Number(e.target.name) )
            ? setDataArray( dataArray.filter(x => x !== Number(e.target.name)) )
            : setDataArray( [...dataArray, Number(e.target.name)] )
    }

    useEffect(() => {
        choose(dataArray)
    // eslint-disable-next-line
    },[ dataArray ])

    return (

        <div className='order-element-type-2'>
            {
                data.map(item => {
                    return (
                        <div className='order-element-checkbox-container' key={Math.random()}>
                            <input  className='order-element-checkbox'
                                    type='checkbox' 
                                    name={ item.id }
                                    checked={ dataArray.includes(item.id) }
                                    onChange={e => handleChange(e)} />
                            <label htmlFor={item.id}>{ item.name }</label>
                        </div>
                    )
                })
            }
        </div>
        
    )
})

export default Checkbox;