import '../setting.css';
import React from 'react'
import { observer } from 'mobx-react-lite';

const InputBoolean = observer(({ title, name, checked }) => {

    return (

        <div className='setting-element'>
            <label htmlFor={name}>{ title }</label>
            <input className="setting-element-boolean" id={name} type="checkbox" checked={checked} onChange={() => {}}/>
        </div>
        
    )
})

export default InputBoolean;