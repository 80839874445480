import "./marking.css";
import React from 'react';

const Contant = ({ contant }) => {

    return (
        <div className="marking_contant" id="marking_contant">
            { contant }
        </div>
    )
}

export default Contant;