import { makeAutoObservable } from 'mobx';

class OrdersFilterStorage {

    render = 0
    id = null
    orderType = null
    customer = null
    manager = null
    paymentStatus = null
    goods = null
    shops = null
    paymentType = null
    deliveryType = null

    constructor() {
        makeAutoObservable(this);
    }

    setRenderFilter = () => {
        this.render++
    }

    setId = (data) => {
        this.id = data
    }

    setOrderType = (data) => {
        this.orderType = data
    }

    setCustomer = (data) => {
        this.customer = data
    }

    setManager = (data) => {
        this.manager = data
    }

    setPaymentStatus = (data) => {
        this.paymentStatus = data
    }

    setGoods = (data) => {
        this.goods = data
    }

    setShopsFilter = (data) => {
        this.shops = data
    }

    setPaymentType = (data) => {
        this.paymentType = data
    }

    setDeliveryType = (data) => {
        this.deliveryType = data
    }
    
}

const data = new OrdersFilterStorage();
export default data;