import './products.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import ButtonAdd from '../../common/elements/button-add';
import api from "../../../services/api"
import { useNavigate } from 'react-router-dom';
import TableProviders from '../../common/table/TableProviders';

const Provider = observer(() => {

    const [providers, setProviders] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            await api.post("/goods/get-providers")
                .then(res => setProviders(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (

        providers == null ?
            <div className='preloader-block'><div className='loader'></div></div> :
            <div className="GroupUser">
                <Head contant={ 
                    <div className='head-container'>
                        <div className='head-first-line'>
                            <div>
                                <span>Поставщики</span>
                                {/* <FilterButton /> */}
                            </div>
                            <ButtonAdd method={() => navigate('/goods/provider/new')}/>
                        </div>
                        {/* <CustomerFilter /> */}
                    </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        { providers !== null ? <TableProviders providers={ providers }/> : "" }
                    </div> 
                }/>
            </div>
    )
})

export default Provider;