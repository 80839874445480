import './table.css';
import React, { useState } from 'react'

const ShopCardDiscontBlock = ({ shopCard, setShopCard, id, price, count, discontValue }) => {

    const [ data, setData ] = useState({
        oldPrice: price,
        newPrice: price,
        oldTotalPrice: price * count,
        newTotalPrice: price * count,
        discont: discontValue,
        discontPercent: discontValue != null ? (discontValue / price * count).toFixed(2) : null
    })

    const hideBlock = (id) => {
        let block = document.querySelector(`#discont-block_${id}`)
        block.classList.remove('discont-block__active')
        let newData = global.structuredClone(data)
        newData.newPrice = price
        newData.discont = 0
        newData.discontPercent = 0
        newData.newTotalPrice = price * count
        setData(newData)
    }

    const saveDiscont = (id) => {
        console.log(shopCard)
        let shopCardClone = global.structuredClone(shopCard)
        shopCardClone.forEach(item => {
            if (item.id === Number(id)) {
                item.totalPrice = data.newTotalPrice
            }
        })
        setShopCard(shopCardClone)
    }

    const changeValue = (value, type) => {
        let newData = global.structuredClone(data)
        let nValue = Number(value)
        switch(type) {
            case 'newPrice':
                newData.newPrice = nValue
                newData.discont = (price * count) - (nValue * count)
                newData.discontPercent = ((price - nValue) / price).toFixed(2) * 100
                newData.newTotalPrice = nValue * count
                break;
            case 'discont':
                newData.newPrice = (((price * count) - nValue) / count).toFixed(2)
                newData.discont = nValue
                newData.discontPercent = (nValue / (price * count)).toFixed(2) * 100
                newData.newTotalPrice = price * count - nValue
                break;
            case 'discontPercent':
                newData.newPrice = price - (nValue / 100 * price).toFixed(2)
                newData.discont = ((price * count) * nValue / 100).toFixed(2)
                newData.discontPercent = nValue
                newData.newTotalPrice = (price * count) - ((price * count) * nValue / 100).toFixed(2)
                break;
            case 'newTotalPrice':
                newData.newPrice = (nValue / count).toFixed(2)
                newData.discont = price * count - nValue
                newData.discontPercent = (((price * count - nValue) / (price * count)) * 100).toFixed(2)
                newData.newTotalPrice = nValue
                break;
            default:
                break
        }
        setData(newData)
    }

    return(
        <div id={'discont-block_' + id} className='discont-block'>
            <div className='discont-block-element'>
                <label htmlFor=''>Прежняя цена</label>
                <input name='' defaultValue={ data.oldPrice } disabled/>
            </div>
            <div className='discont-block-element'>
                <label htmlFor=''>Цена со скидкой</label>
                <input name='' value={ data.newPrice != null ? data.newPrice : 0 } onChange={ (e) => changeValue(e.target.value, 'newPrice') } />
            </div>
            <div className='discont-block-element'>
                <label htmlFor=''>Прежняя стоимость</label>
                <input name='' defaultValue={ data.oldTotalPrice } disabled/>
            </div>
            <div className='discont-block-element'>
                <label htmlFor=''>Стоимость со скидкой</label>
                <input name='' value={ data.newTotalPrice != null ? data.newTotalPrice : 0 } onChange={ (e) => changeValue(e.target.value, 'newTotalPrice') } />
            </div>
            <div className='discont-block-element'>
                <label htmlFor=''>Скидка, руб</label>
                <input name='' value={ data.discont != null ? data.discont : 0 } onChange={ (e) => changeValue(e.target.value, 'discont') } />
            </div>
            <div className='discont-block-element'>
                <label htmlFor=''>Скидка, %</label>
                <input name='' value={ data.discontPercent != null ? data.discontPercent : 0 } onChange={ (e) => changeValue(e.target.value, 'discontPercent') } />
            </div>
            <div className='discont-block-element'>
                <div className='discont-block-element__btn-save' onClick={ e => hideBlock(id)}>Отменить</div>
            </div>
            <div className='discont-block-element'>
                <div className='discont-block-element__btn-save' onClick={ e => saveDiscont(id) }>Сохранить</div>
            </div>
        </div>
    )
}

export default ShopCardDiscontBlock;