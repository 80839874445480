import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import usersFilterStorage from "../../../storage/users-filter"
import api from "../../../services/api"
import { useNavigate } from 'react-router-dom';
import imgTrue from "./img/true.svg"

const TableUser = observer(() => {

    const { name, email, group, render } = usersFilterStorage;
    const navigate = useNavigate()
   

    const linkToUser = (id) => {
        navigate('/user/' + id);
    }

    const filterTable = (element) => {
        let firstName = element.username !== null ? element.username.toLowerCase() : ''
        let surName = element.surname !== null ? element.surname.toLowerCase() : ''
        let patronymicName = element.patronymic !== null ? element.patronymic.toLowerCase() : ''

        if ( ( firstName.includes(name.toLowerCase()) || surName.includes(name.toLowerCase()) || patronymicName.includes(name.toLowerCase()) ) &&
            element.email.toLowerCase().includes(email.toLowerCase()) && 
            element.roles.filter(e => e.fullName.toLowerCase().includes(group.toLowerCase())).length > 0 ) return true
        

        return false
    }

    useEffect(() => {
        const getUsers = async () => {
            const body = document.querySelector('#table-body')

            await api.post('/user/get-users', {})
                    .then(res => {
                            let html = ""
                            if (res.data.filter(filterTable).length > 0) {
                                res.data.filter(filterTable).forEach(element => {
                                    html += `
                                        <tr>
                                            <td class='table-users_space table-users-col-1'>
                                                <div class='table-users_left-span'>
                                                    ${ element.photo !== null ? `<img class='table-users_img' src=${element.photo} alt="" />` : `<div class='table-users_img'></div>` }
                                                    <span><a src="#" id="link-user_${element.id}">${ element.surname !== null ? element.surname : '' } ${ element.username !== null ? element.username : ''} ${ element.patronymic !== null ? element.patronymic : '' }</a></span>
                                                </div>
                                                <span class=${
                                                    element.status === 1 ? 'table-users_status-1' : 
                                                    element.status === 2 ? 'table-users_status-2' : 
                                                    element.status === 3 ? 'table-users_status-3' : 'table-users_status-4'}>${ 
                                                        element.status === 1 ? 'cвободен' : 
                                                        element.status === 2 ? 'занят' : 
                                                        element.status === 3 ? 'на обеде' : 'перерыв' }</span></td>
                                            <td class='table-users-col-2'>${ element.email }</td>
                                            <td class='table-users-col-3'>${ new Date(element.lastTokenDate).toLocaleDateString()}</td>
                                            <td class='table-users-col-4'></td>
                                            <td class='table-users-col-5'>${ element.roles.filter(item => item.name === 'ROLE_ADMIN').length > 0 ? `<img src=${ imgTrue } alt="" width="20px" height="20px" />` : '' }</td>
                                            <td class='table-users-col-6'><ul>${ 
                                                element.roles.length < 4 ?
                                                element.roles.map(item => '<li>' + item.fullName + '</li>').join('') : 
                                                element.roles.map((item, index) => {
                                                    if (index < 2)
                                                        return '<li>' + item.fullName + '</li>'
                                                    if (index === 2) 
                                                        return '<li>...</li>'
                                                    return ''
                                                }).join('')
                                            }</ul></td>
                                            <td class='table-users-col-7'>${ new Date(element.dateCreated).toLocaleDateString()}</td>
                                        </tr>
                                    `
                                })
                            } else {
                                html += `<tr class="table-empty-data">
                                            <td>Нет данных</td>
                                         </tr>`
                            }
                            
                        body.innerHTML = html;
                        res.data.filter(filterTable).forEach(element => {
                            let link = document.querySelector('#link-user_' + element.id)
                            if (link !== null)
                                link.addEventListener('click', () => linkToUser(element.id))
                        })
                    })
                    .catch(e => console.log(e))
        }

        getUsers()
    // eslint-disable-next-line
    }, [render])

    return (
        <table className="Table">
            <thead id="table-head">
                <tr>
                    <th className='table-users-col-1'><span>ФИО</span></th>
                    <th className='table-users-col-2'><span>E-mail</span></th>
                    <th className='table-users-col-3'><span>Последний визит</span></th>
                    <th className='table-users-col-4'><span>Активность</span></th>
                    <th className='table-users-col-5'><span>Администратор</span></th>
                    <th className='table-users-col-6'><span>Группа</span></th>
                    <th className='table-users-col-7'><span>Создан</span></th>
                </tr>
            </thead>
            <tbody id="table-body"></tbody>
        </table>
    )
})

export default TableUser;