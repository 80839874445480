import '../setting.css';
import React from 'react'
import { observer } from 'mobx-react-lite';

const Select = observer(({ title, data, name }) => {

    return (

        <div className='setting-element'>
            <label htmlFor={name}>{ title }</label>
            <select id={name} defaultValue="">
                <option value="">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default Select;