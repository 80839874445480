import { observer } from "mobx-react-lite"
import "./elements.css"
import React, { useEffect, useState } from "react"
import userStorage from "../../../storage/user"
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeRu from 'air-datepicker/locale/ru';

const InputLableDate = observer(({ lableText, inputId, inputeValue, isRequired, settingsName }) => {

    const [ value, setValue ] = useState(inputeValue)
    const { setUserSettings } = userStorage;

    const changeValue = () => {
        const input = document.querySelector(`#${inputId}`)
        setValue(input.value)
        setUserSettings(settingsName, input.value)
    }

    useEffect(() => {
        new AirDatepicker(`#${ inputId }`, {
            locale: localeRu
        })
    // eslint-disable-next-line
    }, [])

    return (
        <div className="input-label">
            <label className={ isRequired ? 'input-label-requared' : '' }>{ lableText }</label>
            <input id={ inputId } value={ value } onChange={ () => changeValue() } placeholder="Выберете дату"/>
        </div>
    )
})

export default InputLableDate