import './products.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line
import FilterButton from '../../common/elements/filter-button';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableProductsSaldo from '../../common/table/TableProductsSaldo';

const ProductsSaldo = observer(() => {

    const [products, setProducts] = useState(null)
    const [reservation, setReservation] = useState(null)

    useEffect(() => {
        const getData = async () => {
            await api.post("/goods/get-saldo")
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))

            await api.post("/orders/get-orders")
                .then(res => {
                    const goodMaps = new Map()
                    res.data.filter(order => order.reservation === true)
                        .forEach(element => {
                            element.shopCard.forEach(item => {
                                if (goodMaps.has(Number(item.goods.id))) {
                                    const object = goodMaps.get(item.goods.id)
                                    const value = Number(object.count)
                                    const currentObject = {
                                        count: value + Number(item.count),
                                        goods: item.goods
                                    }
                                    goodMaps.set(Number(item.goods.id), currentObject)
                                } else {
                                    const currentObject = {
                                        count: Number(item.count),
                                        goods: item.goods
                                    }
                                    goodMaps.set(Number(item.goods.id), currentObject)
                                }
                            })
                        });
                    setReservation(Array.from(goodMaps.values()))
                })
                .catch(e => console.log(e))
                
        }
        getData()
    // eslint-disable-next-line
    },[])

    return (

        <div className="GroupUser">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Остатки товаров</span>
                            {/* <FilterButton /> */}
                        </div>
                    </div>
                    {/* <CustomerFilter /> */}
                </div> }/>
            <Contant contant={ 
                <div className="marking-contant__body">
                    { products != null && reservation != null? <TableProductsSaldo products={ products } reservation={ reservation } /> : "" }
                </div> 
            }/>
        </div>
    )
})

export default ProductsSaldo;