import { observer } from 'mobx-react-lite'
import React from 'react'
import './FixContainer.css'


const ContantContainer = observer(({ contant }) => {

    return (
        <div className="FixContainer">
            { contant }
        </div>
    )
    
})

export default ContantContainer;