import './products.css';
import '../element.css';
import React from 'react'

const ProductCharacteristicButton = ({method}) => {

    return (
        <div className='product-characterictic-area'>
            <div className='product-characterictic-button' onClick={method}>Добавить</div>
        </div>      
    )
}

export default ProductCharacteristicButton;