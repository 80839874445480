import "./marking.css"
import React from 'react'

const Head = ({ contant, link }) => {

    return (
        <div className="marking_head" id="marking_head">
            { contant }
            { link }
        </div>
    )
}

export default Head;