import './filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Status from "./shops-filter/status"
import Name from "./shops-filter/name"
import SelectType from "./shops-filter/select-type"
import api from "../../../../services/api"
import warehouseFilterStorage from "../../../../storage/warehouse-filter"

const WarehouseFilter = observer(() => {

    const [ types, setTypes ] = useState([]);
    const { setStatus, setName, setRender, setType } = warehouseFilterStorage;

    const applyFilter = () => {
        setRender()
    }

    useEffect(() => {
        const getDate = async () => {
            await api.post('/orders/get-warehouse-type', {})
                .then(res => setTypes(res.data))
                .catch(e => console.log(e))
        }
        getDate()
    // eslint-disable-next-line
    }, [])

    return (
        <div className='filter-zone' id="filter-zone">
                <div className='filter-zone-row'>
                    <SelectType data={ types } change={ setType } />
                    <Status change={ setStatus }/>
                    <Name change={ setName }/>
                </div>
            <div className='filter-btn' onClick={ applyFilter }>Применить</div>
        </div>
    )
})

export default WarehouseFilter;