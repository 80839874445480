import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:8080/api",
  baseURL: "https://vinsa.ru:8080/api",
  headers: {
    "Content-Type": "application/json"
  },
});

export default instance;

export const dadataToken = '0f1aaf1827d5e9c07b04006403bb5075c88495ff'
export const dadataSecret = '4562680a1426f4cd0756f6903bd5a213c571faf5'
export const yandexMap = 'eb6e5096-87de-4732-a30c-010839f9120a'