import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Head from '../../common/marking/head';
import FilterButton from '../../common/elements/filter-button';
import ButtonAdd from '../../common/elements/button-add';
import { useNavigate } from 'react-router-dom';
import Contant from '../../common/marking/contant';
import api from "../../../services/api"
import TableSetting from '../../common/table/TableSetting';
import WarehouseFilter from '../../common/elements/filters/warehouse-filter';
import warehouseFilterStorage from "../../../storage/warehouse-filter"
import { compareArray } from "../../common/elements/filters/service"

const WarehouseSetting = observer(() => {

    const navigator = useNavigate()
    const [ warehouse, setWarehouse ] = useState(null)
    const { status, name, type, render } = warehouseFilterStorage;


    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-warehouse', {})
                .then(res => {
                    let array = []
                    let statusData;
                    let nameData;
                    let typeData;

                    if (status !== null && status !== "all") statusData = res.data.filter(item => String(item.status) === status)
                    else statusData = res.data

                    if (name !== null && name !== "") nameData = res.data.filter(item => item.name.toLowerCase().includes(name.toLowerCase()))
                    else nameData = res.data

                    if (type !== null && status !== "" && status !== "all") typeData = res.data.filter(item => String(item.warehouseType.id) === type)
                    else typeData = res.data

                    array.push(statusData)
                    array.push(nameData)
                    array.push(typeData)

                    setWarehouse(compareArray(array))

                })
                .catch(e => console.log(e))
        }

        getData()
    // eslint-disable-next-line
    },[render])

    return (
        <div className="Component">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Склады</span>
                            <FilterButton />
                        </div>
                        <ButtonAdd method={() => {
                            navigator('/settings/warehouse/new')
                        }}/>
                    </div>
                    <WarehouseFilter />
                </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        { warehouse !== null ? <TableSetting data={ warehouse } /> : '' }
                    </div> 
            }/>
        </div>
    )
})

export default WarehouseSetting;