import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const OrdersFilterNumber = observer(({ change }) => {

    const [state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element' data-order-filter="1">
            <label htmlFor="number">Номер заказа</label>
            <input id="number" onChange={(e) => setState(e.target.value)} />
        </div>
        
    )
})

export default OrdersFilterNumber;