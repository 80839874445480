import "./UserSetting.css"
import React from 'react'
import InputLable from "../../common/elements/input-lable"
import InputLableDisabled from "../../common/elements/input-lable-disabled"
import InputPhoto from "../../common/elements/input-photo"
import InputCheckbox from "../../common/elements/input-checkbox"

const UserSetting = ({ user, groups }) => {

    return (
        <div className="UserSetting">
            <div className="user-setting-container user-setting-container__not-border">
                <div className="user-setting-container__lable"><div className="user-settin__description">ОСНОВНЫЕ ДАННЫЕ</div></div>
                <div className="user-setting-row_line">
                    <InputLable lableText="Имя" inputId="user_firstname" inputeValue={ user.username !== null ? user.username : "" } isRequired={ true } settingsName="username" />
                    <InputLable lableText="Фамилия" inputId="user_surname" inputeValue={ user.surname !== null ? user.surname : "" } isRequired={ false } settingsName="surname" />
                    <InputLable lableText="Отчество" inputId="user_patronymic" inputeValue={ user.patronymic !== null ? user.patronymic : "" } isRequired={ false } settingsName="patronymic" />
                    <InputLableDisabled lableText="Email" inputId="user_email" inputeValue={ user.email !== null ? user.email : "" } href="/email" text="Изменить email" settingsName="email" />
                    <div className='user-setting-email-box'>Подтвержден</div>
                    <InputLableDisabled lableText="Пароль" inputId="user_password" inputeValue="" href={ "/password/" + user.id } text="Изменить пароль" />
                    <InputPhoto lableText="Фотография" userValue={ user } />
                </div>
            </div>
            <div className="user-setting-container">
                <div className="user-setting-container__lable"><div>ИНФОРМАЦИЯ</div></div>
                <div className="user-setting-row_line">
                    <InputLable lableText="Должность" inputId="user_job_title" inputeValue={ user.jobTitle !== null ? user.jobTitle : "" } isRequired={ false } settingsName="jobTitle" />
                    <InputLable lableText="Телефон" inputId="user_phone" inputeValue={ user.phone !== null ? user.phone : "" } isRequired={ false } settingsName="phone" />
                </div>
            </div>
            <div className="user-setting-container">
                <div className="user-setting-container__lable"><div>ДОПОЛНИТЕЛЬНЫЕ НАСТРОЙКИ</div></div>
                <div className="user-setting-row_line">
                    <InputCheckbox lableText="Группы" inputId="user_groups" data={ groups } settingsName="roles" user={user} />
                </div>
            </div>
        </div>
    )
}

export default UserSetting;
