import "./elements.css"
import React, { useState } from "react"

const FilterButton = () => {

    const [ state, setState ] = useState(false);

    const filterHide = () => {
        const button = document.querySelector('#button-filter-hide')
        const filterZone = document.querySelector('#filter-zone')
    
        if (state) {
            button.classList.remove('head-first-line_link-active')
            button.innerHTML = 'Свернуть фильтр'
            filterZone.classList.remove('filter-zone__hide')
        } else {
            button.classList.add('head-first-line_link-active')
            button.innerHTML = 'Развернуть фильтр'
            filterZone.classList.add('filter-zone__hide')
        }
        setState(!state)
    }

    return (
        <span className='head-first-line_link' id="button-filter-hide" onClick={filterHide}>Свернуть фильтр</span>
    )
}

export default FilterButton