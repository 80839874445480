import './filters.css';
import React, { useEffect, useState } from 'react'
import api from "../../../../services/api"
import FilterPopUpCustomer from './filter-popup-customer';
import InputHSelect from '../../../elements/inpute/InputHSelect';
import InputH from '../../../elements/inpute/InputH';

const GroupGoodFilter = ({ state, method }) => {

    const [ groupParent, setGroupParent ] = useState(null)
    const [ shops, setShops ] = useState(null)

    const [ name, setName ] = useState("")
    const [ parent, setParent ] = useState("")
    const [ shop, setShop ] = useState("")
    const [ active, setActive ] = useState("")

    const applyFilter = () => {
        method({
            parent: parent,
            shop: shop,
            active: active,
            name: name
        })
    }

    useEffect(() => {
        setParent(state.parent)
        setShop(state.shop)
        setActive(state.active)
        setName(state.name)

        const getData = async   () => {
            await api.post('/goods/get-good-group-parent', {})
                .then(res => setGroupParent(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-shops', {})
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
        }
        getData()
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            <FilterPopUpCustomer />
            <div className='filter-zone' id="filter-zone">
                    <div className='filter-zone-row'>
                        <div className='filter-element-width'>
                            <InputH label={"Название"} value={ name } method={ setName }/>
                        </div>
                        <div className='filter-element-width'>
                            { groupParent != null ? <InputHSelect label={"Родитель"} data={groupParent} value={ parent } method={ setParent } /> : "" }
                        </div>
                        <div className='filter-element-width'>
                            { shops != null ? <InputHSelect label={"Магазин"} data={shops} value={ shop } method={ setShop } /> : "" }
                        </div>
                        <div className='filter-element-width'>
                            <InputHSelect label={"Активность"} data={[{id: true, name: "Активные"}, {id: false, name: "Неактивные"}]} value={ active } method={ setActive } />
                        </div>
                    </div>
                <div className='filter-btn' onClick={ applyFilter }>Применить</div>
            </div>
        </div>
    )
}

export default GroupGoodFilter