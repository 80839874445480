import './User.css';
import React, { useEffect, useState } from 'react'
import Head from "../../common/marking/head"
import Contant from "../../common/marking/contant"
import Foother from "../../common/marking/foother"
import ButtonSave from "../../common/elements/button-save"
import UserSetting from "../../contents/User/UserSetting"
import userStorage from "../../../storage/user"
import dataStorage from "../../../storage/data"
import api from "../../../services/api"
import { observer } from 'mobx-react-lite';

const User = observer(() => {

    const { user, setUser, userSettings, setUserSettingsPrimary } = userStorage;
    const [ groups, setGroups ] = useState(null);
    const { reloader } = dataStorage;

    const removeStatus = () => {
        setTimeout(() => {
            const success = document.querySelector('#user-save__success')
            const wrong = document.querySelector('#user-save__wrong')
            if (success != null) success.classList.remove('user-save__active')
            if (wrong != null) wrong.classList.remove('user-save__active')
        }, 2000)
    }

    const editUser = async (data) => {

        const success = document.querySelector('#user-save__success')
        const wrong = document.querySelector('#user-save__wrong')

        let user = {
            id: data.id,
            username: data.username,
            surname: data.surname,
            patronymic: data.patronymic,
            jobTitle: data.jobTitle,
            phone: data.phone,
            roles: data.roles
        }
        await api.post('user/edit-user', user)
            .then(response => {
                const user = JSON.parse(window.localStorage.getItem('user'))
                for (const property in response.data) {
                    user[property] = response.data[property]
                }
                
                setUser(user)
                window.localStorage.setItem('user', JSON.stringify(user))

                success.classList.add('user-save__active')
                wrong.classList.remove('user-save__active')

                removeStatus()

            })
            .catch(e => {
                success.classList.remove('user-save__active')
                wrong.classList.add('user-save__active')
                removeStatus()
            })
    }

    useEffect(() => {

        const getUser = async () => {

            let data = new FormData()
            data.append('id', user.id)

            await api.post('user/get-user', data)
                .then(result => {
                    setUser(result.data);
                    setUserSettingsPrimary(result.data)
                })
                .catch(e => console.log(e))

            await api.post('user/get-roles', {})
                .then(result => {
                    setGroups(result.data.roles.map(item => {
                        return{
                            name: item.name,
                            fullName: item.fullName
                        }
                    }))
                })
                .catch(e => console.log(e))
        }

        getUser()
       
    // eslint-disable-next-line
    },[reloader])

    return (
        <div className="User">
            <Head contant={ <div><span><span>Пользователь&nbsp;</span><span>{ user.username }</span></span></div> }/>
            <Contant contant={ 
                <div className="marking-contant__body">
                    { user !== null && groups !== null ? <UserSetting user={ user } groups={ groups } /> : '' }
                </div> 
            }/>
            <Foother contant={
                <div className="user-foother">
                    <ButtonSave text="Сохранить" method={ ()=> { editUser(userSettings) } }/>
                    <div id="user-save__success">Сохранено</div>
                    <div id="user-save__wrong">Ошибка сохранения</div>
                </div>
            }/>
        </div>
    )
})

export default User;