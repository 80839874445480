import './PasswordPage.css';
import React from 'react'
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import userStorage from "../../../storage/user"

const EmailPage = observer(() => {

    // eslint-disable-next-line
    const { user } = userStorage;

    return (
        <div className="PasswordPage">
            <div className="password-col-1">
                <div className="password-top">
                    <Link to="/user">Назад</Link>
                </div>
                <div className="password-contant">
                    <div className="password-contant-top">Страница не доступна до активации почтового сервера</div>
                </div>
            </div>
            <div className="password-col-2">
                <div className="password-page-image"></div>
            </div>
        </div>
    )
})

export default EmailPage;