import './orders.css';
import React, { useEffect, useState } from 'react'
// eslint-disable-next-line
import { compareArray } from "../../common/elements/filters/service"
import InputBtn from "./elements/input-btn"
import FootherIn from '../../common/marking/foother-in';
import api from '../../../services/api'
import { COUNT_ELEMENT_ON_PAGE } from '../../../settings';
import GoodFilter from '../../common/elements/filters/good-filter';
import TableShopCardProducts from '../../common/table/TableShopCardProducts';

const CardShop = ({ goodsInOrder, setGoodsInOrder, setRender }) => {

    const [ products, setProducts ] = useState(null)
    const [ goodCount, setGoodCount ] = useState(0)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ isLoading, setIsLoading ] = useState(false)

    const [ filter, setFilter ] = useState({
        groupGoods: "all",
        shop: "all",
        active: "all",
        name: ""
    })

    const hideGoods = () => {
        const area = document.querySelector('#card-shop')
        area.classList.remove('card-shop__active')
        setRender(r => ++r)
    }

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('groupGoods', null)
        data.append('shop', null)
        data.append('active', null)
        data.append('name', null)
        const getData = async () => {
            setIsLoading(true)
            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [currentPage])

    useEffect(() => {
        let offset = currentPage - 1
        let data = new FormData()
        data.append('offset', offset)
        data.append('limit', COUNT_ELEMENT_ON_PAGE)
        data.append('groupGoods', filter.groupGoods !== "all" ? Number(filter.groupGoods) : null)
        data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
        data.append('active', filter.active !== "all" ? filter.active : null)
        data.append('name', filter.name !== "" ? filter.name : null )
        const getData = async () => {
            setIsLoading(true)
            await api.post('/goods/count-goods', data)
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))
            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
            setIsLoading(false)
        }
        getData()
    // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        const getGoods = async () => {
            await api.post('/goods/count-goods')
                .then(res => setGoodCount(res.data))
                .catch(e => console.log(e))

            let data = new FormData()
            data.append('offset', 0)
            data.append('limit', COUNT_ELEMENT_ON_PAGE)
            data.append('groupGoods', filter.groupGoods !== "all" ? Number(filter.groupGoods) : null)
            data.append('shop', filter.shop !== "all" ? Number(filter.shop) : null)
            data.append('active', filter.active !== "all" ? filter.active : null)
            data.append('name', filter.name !== "" ? filter.name : null)

            await api.post("/goods/get-goods", data)
                .then(res => setProducts(res.data))
                .catch(e => console.log(e))
        }
        getGoods()
    // eslint-disable-next-line
    }, [])

    return (

        <div className='card-shop' id="card-shop">
            <div className='card-shop-container '>
                <div className='card-shop-left'>
                    <div>
                        {/* { shopsFilter !== null ? <SelectType2 title="Магазин" name="shop" data={ shopsFilter } choose={setShop}/> : '' }
                        { goodsGroupFilter !== null ? <SelectType2 title="Группа" name="group-goods" data={ goodsGroupFilter } choose={setGroup}/> : '' }
                        { warehouseFilter !== null ? <SelectType2 title="Склад" name="warehouse" data={ warehouseFilter } choose={setWarehouse}/> : '' }
                        <InputType2 title="Производитель" name="manufacture" choose={setManufacture}/>
                        { goodsTagFilter !== null ? <Checkbox data={ goodsTagFilter } choose={setGoodTag}/> : '' } */}
                        <GoodFilter state={filter} method={setFilter}/>

                    </div>
                    <FootherIn contant={ 
                        <div className="user-foother">
                            <div id="user-save__success">Сохранено</div>
                            <div id="user-save__wrong">Ошибка сохранения</div>
                        </div>
                    } />
                </div>
                <div className='card-shop-right'>
                    <div className='card-shop-right-top'>
                        <div className='card-shop-right-top-h1'>Добавление товаров</div>
                        <InputBtn state={ filter } method={ setFilter } placeholder="Название"/>
                        <div className='card-shop-right-top-close' onClick={() => hideGoods()}></div>
                    </div>
                    <div className='card-shop-right-content'>
                        {
                            isLoading ?
                            <div className='preloader-block__fix'><div className='loader'></div></div> :
                            ''
                        }
                        { 
                            products !== null ? 
                            <TableShopCardProducts products={ products } countElements={goodCount} method={ setCurrentPage } goodsInOrder={ goodsInOrder } setGoodsInOrder={ setGoodsInOrder } /> 
                            : ""  
                        }
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default CardShop;