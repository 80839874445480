import '../orders.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

const Customer = observer(({ customer, id, method }) => {

    const navigate = useNavigate();

    return (

        <div className='content-body-padding-70 d-none' id={id}>
            {   customer !== null ? 
                <div className='order-customer-box-container'>
                    <div className='order-customer-box' onClick={() => navigate(`/sales/customers/${customer.id}`)}>{ customer.firstname != null ? customer.firstname : '' } { customer.surname != null ? customer.surname : '' }</div>
                    <div className='order-customer-box-close' onClick={method}></div>
                </div>
                : '' 
            }
        </div>
        
    )
})

export default Customer;