import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import ContentBlock from '../../common/elements/content-block';
import Foother from '../../common/marking/foother';
import ButtonSave from '../../common/elements/button-save';
import api from "../../../services/api"
import Input from './elements/input';
import Select from './elements/select';
import InputBoolean from './elements/input-boolean';
import InputCheckbox from "../../common/elements/filters/shops-filter/input-checkbox"
import InputH from "../../elements/inpute/InputH"

const Shop = observer(() => {

    const [ state, setState ] = useState(null);
    const [ warehouse, setWarehouse ] = useState(null);
    const [ check, setCheck ] = useState("1")

    const changeShop = (event) => {
        setCheck(event.target.value)
        const inShop = document.querySelector('#catalog-in-system')
        if (event.target.value === "2") inShop.classList.remove('d-none')
        else inShop.classList.add('d-none')

    }

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-shops', {})
                .then(res => setState(res.data))
                .catch(e => console.log(e))
            await api.post('/orders/get-warehouse', {})
                .then(res => setWarehouse(res.data))
                .catch(e => console.log(e))
        }

        getData()
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="Order">
                <div className='order-top'>
                    <div className='order-links'>
                        <Link to={'/'}>Главная</Link>
                        <span>→</span>
                        <Link to={'/settings/shops/'}>Магазины</Link>
                    </div>
                    <div className='order-h1'>Новый магазин</div>
                </div>
                <ContentBlock title="Основные" name="main" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <InputH label={'Название'} value={null} marginY={25}/>
                            <InputH label={'URL'} value={null} marginY={25}/>
                            <InputBoolean title="Активность" name="status" checked={true} />
                        </div>
                    </div>
                }/>
                <ContentBlock title="Адрес" name="address" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <Input title="Индекс" name="post-code" />
                            <Input title="Страна" name="country" />
                            <Input title="Район" name="district" />
                            <Input title="Город" name="city" />
                            <Input title="Адрес" name="address" />
                            <Input title="Метро" name="subway" />
                        </div>
                    </div>
                }/>
                <ContentBlock title="Контакты" name="contacts" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            <Input title="Контактное лицо" name="contact-person" />
                            <Input title="Телефон" name="phone" />
                            <Input title="E-mail" name="email" />
                        </div>
                    </div>
                }/>
                <ContentBlock title="Каталог" name="catalog" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            
                        <div className='setting-element'>
                            <label htmlFor="">Каталог</label>
                            <div className="setting-element-radio-group">
                                <div>
                                    <input className="setting-element-radio" name="shop-catalog" id="" type='radio'
                                        value="1"
                                        onChange={changeShop}
                                        checked={ check === "1" ? true : false } />
                                    <h1>Вести в системе</h1>
                                    <h2>Добавляйте и редактируйте товары в разделе Продажи → Товары и склад → Товары</h2>
                                </div>
                                <div>
                                    <input className="setting-element-radio" name="shop-catalog" id="" type='radio'
                                        value="1"
                                        onChange={changeShop}
                                        checked={ check === "1" ? true : false } />
                                    <h1>Импорт OpenCart</h1>
                                    <h2>Импортировать товары из каталога OpenCart</h2>
                                </div>
                                <div>
                                    <input className="setting-element-radio" name="shop-catalog" id="" type='radio'
                                        value="2"
                                        onChange={changeShop}
                                        checked={ check === "2" ? true : false } />
                                    <h1>Использовать из другого магазина</h1>
                                    <h2>Будут использованы товары выбранного магазина</h2>
                                </div>
                            </div>
                        </div>

                        <div className="setting-element d-none" id="catalog-in-system">
                            { state !== null ? <Select title="Магазин" data={ state } name="shops" /> : '' }
                        </div>

                        </div>
                    </div>
                }/>
                <ContentBlock title="Склады" name="contacts" defaultState={true} button={null} content={
                    <div className='ContentBlockBody'>
                        <div className='content-body-padding-70'>
                            { warehouse !== null ? <InputCheckbox lableText="Доступные для магазина склады" dataAll={ warehouse } dataChecked={warehouse} /> : '' }
                        </div>
                    </div>
                }/>
            </div>
            <Foother contant={ 
                <div className="user-foother">
                    <ButtonSave text="Сохранить" method={ ()=> {  } }/>
                    <div id="user-save__success">Сохранено</div>
                    <div id="user-save__wrong">Ошибка сохранения</div>
                </div>
             } />
        </div>
    )
})

export default Shop;