import '../orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const SelectType2 = observer(({ title, data, name, choose }) => {

    const [ state, setState ] = useState(null)

    useEffect(() => {
        choose(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='order-element-type-2'>
            <label htmlFor={name}>{ title }</label>
            <select id={name} onChange={e => setState(e.target.value)} value={state !== null ? state : "all"}>
                <option value="all">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default SelectType2;