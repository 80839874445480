import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { insertPaginator } from '../elements/paginator';

const TableProductsGroup = observer(({ group, groupGoods, countElements, method }) => {

    const [ currentPage, setCurrentPage ] = useState(1)

    // const [ groupData, setGroupData ] = useState([])

    const navigate = useNavigate()

    const linkToGood = (id) => {
        navigate('/sales/product-groups/' + id)
    }

    // eslint-disable-next-line
    const calculateTotalElements = (array) => {
        let count = 0
        array.forEach(item => {
            if (item.children != null) {
                if (item.children.length !== 0) {
                    count += calculateTotalElements(item.children)
                } else {
                    count++
                }
            } else {
                count++
            }
        })
        return count
    }

    // const insertChildren = (parent) => {
    //     const arr = parent.map(item => {
    //         const object = item
    //         const children = group.filter(element => {
    //             if (element.parent == null) return false
    //             else return element.parent.id === item.id
    //         })
    //         object.children = insertChildren(children)
    //         return object
    //     })
    //     return arr
    // }

    // useEffect(() => {
    //     if (group != null) {
    //         const parent = group.filter(item => item.parent == null)
    //         const arr = insertChildren(parent)
    //         setGroupData(arr)
    //     }
    //     console.log(group)
    // // eslint-disable-next-line
    // }, [group])

    useEffect(() => {
        method(currentPage)
    // eslint-disable-next-line
    }, [currentPage])

    // const insertHTML = (array, count) => {
    //     let html = ''

    //     array.forEach(element => {
    //         html += `
    //             <tr class="row-element" id="link-to_${element.id}">
    //                 <td class='table-products-group-col' style="padding-left: ${count * 25}px;">${ element.name }</td>
    //                 <td class='table-products-group-col table-products-group-col__center' style="margin-left: -${count * 25 - 13}px;">${ groupGoods[element.id] }</td>
    //                 <td class='table-products-group-col table-products-group-col__center'>${ element.shops.name }</td>
    //             </tr>
    //         `
    //         if (element.children.length > 0) {
    //             html += insertHTML(element.children, count++)
    //         }
    //     })

    //     return html
    // }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (group.length > 0) {
                group.forEach(element => {
                    html += `
                        <tr class="row-element" id="link-to_${element.id}">
                            <td class='table-products-group-col'>${ element.name }</td>
                            <td class='table-products-group-col table-products-group-col__center'>${ groupGoods[element.id] }</td>
                            <td class='table-products-group-col table-products-group-col__center'>${ element.shops.name }</td>
                        </tr>
                    `
                    // if (element.children.length > 0) {
                    //     html += insertHTML(element.children, 1)
                    // }
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            const area = document.querySelector('#paginator')
            if (area != null) {
                area.innerHTML = ""
            }
            insertPaginator(countElements, currentPage, setCurrentPage)
            const rows = Array.from(document.querySelectorAll('.row-element'))
            rows.forEach(element => {
                const index = element.id.lastIndexOf('_')
                const number = element.id.substring(index + 1,)
                element.addEventListener('click', () => linkToGood(number))
            })

    // eslint-disable-next-line
    }, [group])

    return (
        <div className='table-box__column'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-products-group-col'><span>Группа</span></th>
                        <th className='table-products-group-col table-products-group-col__center'><span>Количество товаров</span></th>
                        <th className='table-products-group-col table-products-group-col__center'><span>Магазин</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
            <div className='table-foother-count'>Всего: { countElements }</div>
            <div className="table-foother" id='paginator'></div>
        </div>
    )
})

export default TableProductsGroup;