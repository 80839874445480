import '../orders.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const Textarea = observer(({ title, name, value, method }) => {

    const [state, setState] = useState(value !== null ? value : "")
    
    useEffect(() => {
        method(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='order-element'>
            <label htmlFor={name}>{ title }</label>
            <textarea id={name} className='order-element-textarea' defaultValue={state} onChange={e => setState(e.target.value)}/>
        </div>
        
    )
})

export default Textarea;