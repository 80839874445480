import './inputes.css';
import React, { useEffect, useRef, useState } from 'react'
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeRu from 'air-datepicker/locale/ru';

const InputHDate = ({
    marginY,        // Margin top and bottom : Integer
    label,          // Label's title : String
    classInput,     // Class for input
    inputId,        // Input's id : String
    value,          // Input's value : String
    placeholder,    // Input's placeholder : String
    method,         // Input's onChange method : setState()
    disabled,        // Input's disabled : Boolean
    required        // Required field or not
}) => {
    
    const [ state, setState ] = useState( value != null ? value : '' )
    let randomId = useRef('input_' + Math.floor(Math.random() * (10000 - 10 + 1)) + 10)

    useEffect(() => {
        if (method != null && typeof method === 'function')
            method( state )
    // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        new AirDatepicker(`#${ inputId != null ? inputId : randomId.current }`, {
            locale: localeRu
        })
    // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     setState(value)
    // }, [value])

    return (
        
        <div 
            className='input-horizontal' 
            style={{ 
                marginBottom: marginY != null ? marginY + 'px' : 10 + 'px',
                marginTop: marginY != null ? marginY + 'px' : 10 + 'px' }} >
            <label className='input-horizontal__label'>{ label !== null ? label : '' }</label>
            {
                <input 
                    id={ inputId != null ? inputId : randomId.current }
                    className={ `input input-horizontal__input ${ classInput != null ? classInput : '' }`} 
                    placeholder={ placeholder != null ? placeholder : '' }
                    value={ state }
                    onChange={ e => setState(e.target.value) }
                    disabled={ disabled ? true : false }
                    data-required={ required != null ? true : false } />
            }
        </div>
        
    )
}

export default InputHDate;