import { makeAutoObservable } from 'mobx';

class UserStorage {
    user = JSON.parse(window.localStorage.getItem('user'));
    userSettings = {}

    constructor() {
        makeAutoObservable(this);
    }

    setUser = (user) => {
        this.user = user;
    }

    setUserSettingsPrimary = (object) => {
        this.userSettings = object
    }

    setUserSettings = (key, value) => {
        this.userSettings[key] = value;
    }

}

const user = new UserStorage();
export default user;