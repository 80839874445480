import './Address.css'
import React, { useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line
import api, { dadataToken, yandexMap } from "../../../services/api"
import Column from '../../common/marking/column';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const AddressModule = ({ value, method }) => {

    const [ state, setState ] = useState(value != null ? value : {
        postCode: '',
        country: '',
        district: '',
        city: '',
        locality: '',
        street: '',
        house: '',
        building: '',
        subway: '',
        comment: '',
        geoLat: '',
        geoLon: ''
    })

    const [ query, setQuery ] = useState(null)
    const [ response, setResponse ] = useState(null)
    const [ address, setAddress ] = useState(null)
    const [ metroResponse, setMetroResponse ] = useState(null)
    const [ handle, setHandle ] = useState(true)

    const [ postalCode, setPostalCode ] = useState("")
    const [ country, setCountry ] = useState("")
    const [ regionWithType, setRegionWithType ] = useState("")
    const [ cityWithType, setCityWithType ] = useState("")
    const [ cityKladrId, setCityKladrId ] = useState("")
    const [ settlementWithType, setSettlementWithType ] = useState("")
    const [ streetWithType, setStreetWithType ] = useState("")
    const [ house, setHouse ] = useState("")
    const [ houseTypeFull, setHouseTypeFull ] = useState("")
    const [ block, setBlock ] = useState("")
    const [ blockTypeFull, setBlockTypeFull ] = useState("")
    const [ room, setRoom ] = useState("")
    const [ roomTypeFull, setRoomTypeFill ] = useState("")
    const [ metro, setMetro ] = useState("")
    const [ geoLat, setGeoLat ] = useState(55.751574)
    const [ geoLon, setGeoLon ] = useState(37.573856)
    const [ zoom, setZoom ] = useState(5)
    const [ comment, setComment ] = useState("")

    useMemo(() => {
        let obj = global.structuredClone(state);
        obj.postCode = postalCode
        obj.country = country
        obj.district = regionWithType
        obj.city = cityWithType
        obj.locality = settlementWithType
        obj.street = streetWithType
        obj.house = houseTypeFull + " " + house + " " + blockTypeFull + "" + block
        obj.building = roomTypeFull + " " + room
        obj.subway = metro
        obj.comment = comment
        obj.geoLat = geoLat
        obj.geoLon = geoLon
        setState(obj)
    // eslint-disable-next-line
    }, [
        postalCode, 
        country, 
        regionWithType, 
        cityWithType, 
        settlementWithType, 
        streetWithType, 
        houseTypeFull, house, blockTypeFull, block, 
        roomTypeFull, room, 
        metro, 
        comment, 
        geoLat, geoLon
    ])

    useEffect(() => {
        method(state)
    // eslint-disable-next-line
    }, [state])

    useMemo(() => {
        setState(value != null ? value : {
            postCode: '',
            country: '',
            district: '',
            city: '',
            locality: '',
            street: '',
            house: '',
            building: '',
            subway: '',
            comment: '',
            geoLat: '',
            geoLon: ''
        })
    // eslint-disable-next-line
    }, [value])

    const addressHandle = () => {
        const addressLink = document.querySelector('#address-link-handle')
        const addressFieldAuto = document.querySelector('#address-auto')
        const addressFields = document.querySelectorAll('[name="address"]')
        const addressArea = document.querySelector('.ymaps-2-1-79-inner-panes')
        
        if (handle) {
            addressLink.innerHTML = 'Ввести адрес вручную'
            addressFields.forEach(item => item.disabled = true)
            addressFieldAuto.disabled = false
            if (addressArea !== null) addressArea.classList.remove('address-map__disabled')
        } else {
            addressLink.innerHTML = 'Поиск адреса автозаполнением'
            addressFields.forEach(item => item.disabled = false)
            addressFieldAuto.disabled = true
            if (addressArea !== null) addressArea.classList.add('address-map__disabled')
        }

        setHandle(!handle)
    }

    const chooseAddress = (address) => {
        setAddress(address)
        setResponse(null)
        setQuery(address.value)
        if ( zoom === 11) setZoom(10)
        if ( zoom === 10) setZoom(11)
        if ( zoom === 5) setZoom(11)
    }

    const chooseMetro = (data) => {
        setMetro(data)
        const getData = async () => {
            var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/metro";

            var options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + dadataToken
                },
                body: JSON.stringify({query: metro})
            }

            await fetch(url, options)
                .then(response => response.text())
                .then(result => setMetroResponse(JSON.parse(result).suggestions.filter(el => el.data.city_kladr_id === cityKladrId)))
                .catch(error => console.log("error", error));
        }
        getData()
    }

    const chooseMetroStation = (data) => {
        setMetroResponse(null)
        setMetro(data.data.name)
    }

    useEffect(() => {
        window.addEventListener('click', e => {
            if (!e.target.closest('address-popup-container')) {
                setResponse(null)
                setMetroResponse(null)
            }
        })
        addressHandle()
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const getData = async () => {
            var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            var token = dadataToken;

            var options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            await fetch(url, options)
                .then(response => response.text())
                .then(result => setResponse(JSON.parse(result)))
                .catch(error => console.log("error", error));

        }
        getData()
    // eslint-disable-next-line
    },[query])
    
    useEffect(() => {
        if (address !== null) {
            if (address.data.postal_code !== null ) setPostalCode(address.data.postal_code)
            if (address.data.country !== null ) setCountry(address.data.country)
            if (address.data.region_with_type !== null ) setRegionWithType(address.data.region_with_type)
            if (address.data.city_with_type !== null ) setCityWithType(address.data.city_with_type)
            if (address.data.city_kladr_id !== null ) setCityKladrId(address.data.city_kladr_id)
            if (address.data.settlement_with_type !== null ) setSettlementWithType(address.data.settlement_with_type)
            if (address.data.street_with_type !== null ) setStreetWithType(address.data.street_with_type)
            if (address.data.house !== null ) setHouse(address.data.house)
            if (address.data.house_type_full !== null ) setHouseTypeFull(address.data.house_type_full)
            if (address.data.block !== null ) setBlock(address.data.block)
            if (address.data.block_type_full !== null ) setBlockTypeFull(address.data.block_type_full)
            if (address.data.room !== null ) setRoom(address.data.room)
            if (address.data.room_type_full !== null ) setRoomTypeFill(address.data.room_type_full)
            if (address.data.metro !== null ) setMetro(address.data.metro)
            if (address.data.geo_lat !== null ) setGeoLat(address.data.geo_lat)
            if (address.data.geo_lon !== null ) setGeoLon(address.data.geo_lon)

            const getData = async () => {
                await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${yandexMap}&format=json&geocode=${address.value}`)
                    .then(async res => {
                        const reader = res.body.getReader();

                        while(true) {
                            const {done, value} = await reader.read();
                          
                            if (done) {
                              break;
                            }
                          
                            let coordinates = String(JSON.parse(String.fromCharCode(...value))
                                .response
                                .GeoObjectCollection
                                .featureMember[0]
                                .GeoObject
                                .Point
                                .pos).split(" ")

                            setGeoLat(coordinates[1])
                            setGeoLon(coordinates[0])
                          }
                    })
            }
            getData()
        }
    }, [address])

    return (
        <div>
            <Column contant={ 
                <div className="input-label">
                <label>Найти адресс</label>
                <input value={ query !== null ? query : "" } id="address-auto" onChange={ e => setQuery(e.target.value) } />
                { 
                    response !== null ?
                        response.suggestions.length > 0 ?
                            <div className='address-popup-container'>
                                { response.suggestions.map(item => <div key={ Math.random() } className='address-popup-element' onClick={() => chooseAddress(item)}>{ item.value }</div>) }
                            </div>
                        : ""
                    : "" 
                }
    
                <div className="input-label input-label__right">
                    <div id="address-link-handle" onClick={() => addressHandle()}>Ввести адресс вручную</div>
                </div>

                <div className="input-label">
                    <label>Почтовый индекс</label>
                    <input id="address-postalCode" name='address' data-address="" value={ state.postCode } onChange={ (e) => setPostalCode(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Страна</label>
                    <input id="address-postalCode" name='address' value={ state.country } onChange={ (e) => setCountry(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Регион</label>
                    <input id="address-postalCode" name='address' value={ state.district } onChange={ (e) => setRegionWithType(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Город</label>
                    <input id="address-postalCode" name='address' value={ state.city } onChange={ (e) => setCityWithType(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Населенный пункт</label>
                    <input id="address-postalCode" name='address' value={ state.locality } onChange={ (e) => setSettlementWithType(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Улица</label>
                    <input id="address-postalCode" name='address' value={ state.street } onChange={ (e) => setStreetWithType(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Дом</label>
                    <input id="address-postalCode" name='address' value={ state.house } onChange={ (e) => setHouse(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Помещение</label>
                    <input id="address-postalCode" name='address' value={ state.building } onChange={ (e) => setRoom(e.target.value) }/>
                </div>
    
                <div className="input-label">
                    <label>Метро</label>
                    <input id="address-postalCode" value={ state.subway } onChange={ (e) => chooseMetro(e.target.value) }/>
                </div>
                { 
                    metroResponse !== null ?
                        metroResponse.length > 0 ?
                            <div className='address-popup-container'>
                                { metroResponse.map(item => <div key={ Math.random() } className='address-popup-element' onClick={() => chooseMetroStation(item)}><span className='address-metro-line' style={{ background: `#${item.data.color}` }}></span>{ item.data.name }</div>) }
                            </div>
                        : ""
                    : "" 
                }

                <div className="input-label">
                    <label>Примечание</label>
                    <textarea id="address-postalCode" value={ state.comment } onChange={ (e) => setComment(e.target.value) }/>
                </div>
    
            </div>
            }/>
            <Column contant={ 
                <div className='address-map' id='address-area'>
                    <YMaps query={{
                        apikey: yandexMap,
                        lang: 'ru_RU'
                    }}>
                        <Map width='480px'
                            height='615px' 
                            modules={["control.ZoomControl"]}
                            state={{
                                center: [ geoLat, geoLon ],
                                zoom: zoom
                            }}>
                            <Placemark geometry={[ geoLat, geoLon ]} />
                        </Map>
                    </YMaps>
                </div>
            }/>
        </div>
    )
}

export default AddressModule;