export const HOME_URL = 'localhost:8081'

export const COUNT_ELEMENT_ON_PAGE = 50

export const COMPANY = {
    nameShort: 'ООО "Империумлофт"',
    inn: '7724484486',
    kpp: '775101001',
    orgn: '1197746467423',
    address: '108811,  г Москва, км Киевское шоссе 22-й (п Московский), 4 / стр 5, Блок Е, этаж 9 офис 927е',
    phone: '+7 (499) 229-57-07',
    email: 'imperiumloft@yandex.ru',
    bankInn: '',
    bankKpp: '',
    bankName: 'АО «Альфа-Банк», г. Москва',
    bankAddress: 'ул. Каланчевская, д. 27, г Москва, Москва',
    bankBik: '044525593',
    bankAccount: '40702810102010002771',
    bankAccountKor: '30101810200000000593',
    seoDiscription: 'Генеральный директор',
    seoShortName: 'Табан Вадим Сергеевич',
    chiefAccountantDiscription: 'Генеральный директор',
    chiefAccountantName: 'Табан Вадим Сергеевич'
}