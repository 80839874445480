import './setting.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Head from '../../common/marking/head';
import Contant from '../../common/marking/contant';
import InputHSelect from '../../elements/inpute/InputHSelect';
import api from '../../../services/api';

const CommonSetting = observer(() => {

    const [ statuses, setStatuses ] = useState(null)

    const [ status, setStatus ] = useState(null)

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-order-status', [])
                .then(res => setStatuses(res.data))
                .catch(e => console.log(e))

            const dataSetting = new FormData()
            dataSetting.append('key', 'reservation-step-status')

            await api.post('/settings/get-setting', dataSetting)
                .then(res => setStatus(res.data.value))
                .catch(e => console.log(e))
        }
        getData()
    }, [])

    return (
        <div className="Component">
            <Head contant={ 
                <div className='head-container'>
                    <div className='head-first-line'>
                        <div>
                            <span>Общие настройки программы</span>
                        </div>
                    </div>
                </div> }/>
                <Contant contant={ 
                    <div className="marking-contant__body">
                        <div className='ContentBlockBody setting-padding'>
                            { statuses != null && status != null ? <InputHSelect label="Резервирование товара" value={ status } method={ setStatus } data={ statuses } /> : '' }
                        </div>
                    </div> 
            }/>
        </div>
    )
})

export default CommonSetting;