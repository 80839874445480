import './products.css';
import '../element.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import api from '../../../services/api'
import ContentBlock from '../../common/elements/content-block';
import InputH from '../../elements/inpute/InputH';
import InputHSelect from '../../elements/inpute/InputHSelect';
import Foother from '../../common/marking/foother';
import ButtonSaveData from '../../elements/button/Save';

const ProductGroupNew = observer(() => {

    const { id } = useParams()

    const [ shops, setShops ] = useState(null)
    const [ goodsGroup, setGoodsGroup ] = useState(null)

    // fields values
    const [ fieldName, setFieldName ] = useState('')
    const [ fieldShop, setFieldShop ] = useState('')
    const [ fieldParent, setFieldParent ] = useState('')
    const [ isLoad, setIsLoad ] = useState(false)


    let dataGood = new FormData()
        dataGood.append("id", id != null ? Number(id) : 0)
        dataGood.append("name", fieldName != null ? String(fieldName) : "")
        dataGood.append("shop", fieldShop != null ? Number(fieldShop) : "")
        dataGood.append("parent", fieldParent != null ? fieldParent : "");

    useEffect(() => {
        let dataGood = new FormData()
        dataGood.append('id', Number(id))
        const getGood = async () => {
            await api.post('/goods/get-good-group', dataGood)
                .then(res => {
                    setFieldName(res.data.name != null ? res.data.name : "")
                    setFieldParent(res.data.parent != null ? res.data.parent.id : "")
                    setFieldShop(res.data.shops.id != null ? res.data.shops.id : "")
                    setIsLoad(true)
                })
                .catch(e => console.log(e))
        }
        const getData = async () => {
            await api.post('/orders/get-shops', {})
                .then(res => setShops(res.data))
                .catch(e => console.log(e))
            await api.post('/goods/get-group-goods-all', {})
                .then(res => setGoodsGroup(res.data))
                .catch(e => console.log(e))
        }
        if (id != null) {
            getGood()
        } else {
            setIsLoad(true)
        }
        getData()

    // eslint-disable-next-line
    },[])

    return (
        isLoad ?
        <div className="Element">
            <div className='element-top'>
                <div className='element-links'>
                    <Link to={'/'}>Главная</Link>
                    <span>→</span>
                    <Link to={'/sales/product-groups'}>Товарные группы</Link>
                </div>
                <div className='element-h1'>{ id != null ? fieldName : 'Новая товарная группа' }</div>
            </div>
            <ContentBlock title="Главная информация" name="main" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        <InputH label={'Название'} value={fieldName} method={setFieldName} marginY={25} required={true} />
                        { goodsGroup !== null ? <InputHSelect label={'Родительский элемент'} marginY={25} data={ goodsGroup } value={ fieldParent } method={ setFieldParent } /> : "" }
                        { shops !== null ? <InputHSelect label={'Магазин'} marginY={25} data={ shops } value={fieldShop} method={ setFieldShop } required={true} /> : "" }
                    </div>
                </div>
            }/>
            <Foother left={true} contant={
                <div className="element-foother">
                    <ButtonSaveData text="Сохранить" url={'/goods/save-good-group'} params={dataGood}/>
                    <div id="element-save__success" className="d-none">Сохранено</div>
                    <div id="element-save__wrong" className="d-none">Ошибка сохранения</div>
                </div>
            }/>
        </div> :
        <div className='preloader-block'><div className='loader'></div></div>
    )
})

export default ProductGroupNew;