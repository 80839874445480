import "./elements.css"
import React from "react"

const ButtonEdit = ({ method }) => {

    return (
        <div className="button-edit" onClick={ method }>Редактировать</div>
    )
}

export default ButtonEdit