import '../filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';

const CustomerFilterEmailSubscription = observer(({ selectChangeColor, change }) => {

    const [state, setState ] = useState(null)

    useEffect(() => {
        change(state)
    // eslint-disable-next-line
    }, [state])

    return (

        <div className='filter-element' data-order-filter="5">
            <label htmlFor="managers">Email подписка</label>
            <select id="managers" value={state !== null ? state : "all"} onChange={(e) => {
                    selectChangeColor()
                    setState(e.target.value)
                }}>
                <option value="all">Выберите значение</option>
                <option value={ true }>Да</option>
                <option value={ false }>Нет</option>
            </select>
        </div>
        
    )
})

export default CustomerFilterEmailSubscription;