import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect, useState } from 'react'
import SelectStep from '../elements/select-step';
import statusStepStorage from '../../../storage/status-step';

const TableStatusStepSetting = observer(({ statusStep, orderType, method }) => {

    const { orderTypeStep } = statusStepStorage;
    const [ steps, setSteps ] = useState([])

    const sortStatusGroup = (a, b) => {
        if (a.status.statusGroup.priority - b.status.statusGroup.priority === 0) return 0
        if (a.status.statusGroup.priority - b.status.statusGroup.priority < 0) return -1
        else return 1
    }
    const sortStatusPriority = (a, b) => {
        if (a.status.statusGroup.priority - b.status.statusGroup.priority === 0) {
            if (a.status.priority === b.status.priority) return 0;
            if (a.status.priority < b.status.priority) return -1;
            else return 1;
        }
    }

    useEffect(() => {
        const body = document.querySelector('#table-body')
        let html = '';
        if (orderTypeStep !== null && orderTypeStep !== "all") {
            statusStep
                .filter(item => String(item.typeOrder.id) === String(orderTypeStep))
                .sort(sortStatusGroup)
                .sort(sortStatusPriority)
                .forEach((element, indexRow) => {                
                    let tableRow = '';
                    let nextStatus = element.nextStatus;

                    statusStep
                        .filter(item => String(item.typeOrder.id) === String(orderTypeStep))
                        .sort(sortStatusGroup)
                        .sort(sortStatusPriority)
                        .forEach((column, indexColumn) => {
                            let isContain = nextStatus.map(e => e.id).includes(column.status.id)
                            if (isContain) {
                                setSteps(steps.push(
                                    {
                                        status_step_id: column.id,
                                        next_status_id: column.nextStatus.id
                                    }
                                ))
                            }
                            tableRow += 
                                `<td class="table-status-step-col table-status-step-col__center">${indexColumn === indexRow ? "" :
                                isContain ? 
                                    `<input type='checkbox' data-status-start=${column.id} data-status-end=${column.id} checked />` : 
                                    `<input type='checkbox' data-status-start=${column.id} data-status-end=${column.id} />` }
                                <td>`
                        })
                    method(steps)
                    html += `<tr id="shop-id_${ element.id }" class="table-status-step-row">
                        <td class="table-status-step-col" style="background: #${element.status.statusGroup.backgroundColor}; color: #${element.status.statusGroup.color}">${ element.status.name }</td>
                        ${tableRow}
                    </tr>`
                });
            body.innerHTML = html
        }

    // eslint-disable-next-line
    }, [orderTypeStep])
    
    return (
        <div className='table-status-stem__markup'>
            { orderType !== null ? <SelectStep data={ orderType } lable="Тип заказа" /> : "" }
            { orderTypeStep !== null && orderTypeStep !== "all" ?
                <table className="Table">
                    <thead id="table-head">
                        <tr className='table-status-step-row'>
                            <th className='table-status-step-col table-status-step-col__status'><span>Статусы</span></th>
                            {
                                statusStep.filter((item) => String(item.typeOrder.id) === String(orderTypeStep))
                                    .sort(sortStatusGroup)
                                    .sort(sortStatusPriority)
                                    .map(element => <th 
                                                        key={Math.random()} 
                                                        className='table-status-step-col' 
                                                        style={{background: `#${element.status.statusGroup.backgroundColor}`, color: `#${element.status.statusGroup.color}`}}>
                                                            <span>{ element.status.name }</span>
                                                    </th>)
                            }
                        </tr>
                    </thead>
                    <tbody id="table-body"></tbody>
                </table>
            : ""
            }
        </div>
    )
})

export default TableStatusStepSetting;