import ShopCardDiscontBlock from './ShopCardDiscontBlock';
import './table.css';
import React, { useEffect, useState } from 'react'
import api from '../../../services/api'
// eslint-disable-next-line
import { applyMiddleware } from 'redux';
import toast from 'react-hot-toast';


const TableCardShop = ({ goods, discauntEnter, setShopCardData, deliveryState, orderId, methodOrderDiscont, methodOrderTotalPrice }) => {

    // eslint-disable-next-line
    const [ discauntValue, setDiscauntValue ] = useState('')
    const [ discauntPercentage, setDiscauntPercentage ] = useState('')
    const [ reservationGoodsInOrder, setReservationGoodsInOrder ] = useState([]);

    const [ shopCard, setShopCard ] = useState([])

    const [ calcTotal, setCalcTotal ] = useState(0)
    const [ calcDiscont, setCalcDiscont ] = useState(0)
    const [ orderDiscount, seOrderDiscount ] = useState(0)
    const [ calcDelivery, setCalcDelivery ] = useState(0)
    const [ calcTotalPrice, setCalcTotalPrice ] = useState(0)

    const deleteGoods = (id) => {
        let tempCard = goods.filter(item => Number(item.id) !== Number(id))
        setShopCardData(JSON.parse(JSON.stringify(tempCard)))
        calculate()
    }

    const reservation = (goodId, count) => {
        let data = new FormData();
        data.append("goodId", goodId);
        data.append("count", count);
        data.append("orderId", orderId);
        const makeReservation = async () => {
            await api.post('goods/make-reservation', data)
                .then(res => {
                    if(res.data) {
                        toast.success("Зарезервировано")
                        getReservationGoods()
                    } else
                        toast.error("Ошибка")
                })
                .catch(e => toast.error("Ошибка"))
        }
        const cancelReservation = async () => {
            await api.post('goods/cancel-reservation', data)
                .then(res => {
                    if(res.data) {
                        toast.success("Резерв отменён")    
                        getReservationGoods() 
                    } else
                        toast.error("Ошибка")
                })
                .catch(e => toast.error("Ошибка"))
        }

        if (reservationGoodsInOrder.includes(goodId))
            cancelReservation() 
        else
            makeReservation()
    }

    const reservationName = (goodId) => {
        if(reservationGoodsInOrder.includes(goodId))
            return "Снять"
        else
            return "Резерв"
    }

    const calculate = () => {
        let total = 0;
        let discontGoods = 0;
        shopCard.forEach(item => {
            total += Number(item.totalPrice)
            discontGoods += Number(item.count) * Number(item.goods.price) - Number(item.totalPrice)
        })
        setCalcTotal(total)
        let discont = discauntValue !== '' ? Number(discauntValue) : discauntPercentage !== '' ? Number(discauntPercentage) / 100 * Number(total) : 0
        seOrderDiscount(Number(discont))
        discont += discontGoods
        setCalcDiscont(discont <= total ? discont : total)
        let delivery = deliveryState != null && deliveryState.deliveryPrice !== '' && !isNaN(deliveryState.deliveryPrice) ? deliveryState.deliveryPrice : 0
        setCalcDelivery(delivery)
        setCalcTotalPrice(Number(total) - Number(discont) + Number(delivery))
    }

    const changeCount = (id, count) => {
        let tempCard = goods.map(item => {
            if(Number(item.id) === Number(id)) {
                item.count = Number(count)
            }
            return item
        })
        setShopCardData(JSON.parse(JSON.stringify(tempCard)))
        calculate()
    }

    const makeDiscont = (id) => {
        const block = document.querySelector(`#discont-block_${id}`)
        block.classList.add('discont-block__active')
    }

    useEffect(() => {
        setShopCardData(shopCard)
        calculate()
    // eslint-disable-next-line
    }, [shopCard])

    useEffect(() => {
        calculate()
    // eslint-disable-next-line
    }, [deliveryState])

    useEffect(() => {
        methodOrderDiscont(orderDiscount)
    // eslint-disable-next-line
    }, [orderDiscount])

    useEffect(() => {
        methodOrderTotalPrice(calcTotalPrice)
    // eslint-disable-next-line
    }, [calcTotalPrice])

    const getReservationGoods = () => {
        if(orderId != null) {
            let dataReservation = new FormData()
            dataReservation.append('id', orderId)
            let getReservedGoods = async () => {
                await api.post('goods/get-reservation-goods', dataReservation)
                    .then(res => setReservationGoodsInOrder(res.data))
                    .catch(e => console.log(e))
            }
            getReservedGoods()
        }
    }

    useEffect(() => {
        getReservationGoods()
        let fieldDiscountPersentage = document.querySelector('#order-discaunt-percentage')
        if (fieldDiscountPersentage !== null) {
            fieldDiscountPersentage.addEventListener('keyup', (e) => {
                if(Number(e.target.value) > 100) {
                    fieldDiscountPersentage.value = 100
                }
            })
        }
    // eslint-disable-next-line    
    }, [])

    useEffect(() => {
        if (goods != null && goods.length > 0)
            setShopCard(goods)
        calculate()
    // eslint-disable-next-line
    }, [goods])

    useEffect(() => {
        if (shopCard !== null) {
            // setGoodsInCardArray(activeGoods.map(item => item))
            if (discauntEnter !== null) setDiscauntValue(discauntEnter);
        }
    // eslint-disable-next-line
    }, [shopCard, discauntEnter])

    useEffect(() => {
        let fieldDiscountValue = document.querySelector('#order-discaunt-value')
        let fieldDiscountPersentage = document.querySelector('#order-discaunt-percentage')

        if (discauntValue === '' && discauntPercentage === '') {
            fieldDiscountValue.disabled = false
            fieldDiscountPersentage.disabled = false
        }
        if (discauntPercentage !== '') {
            fieldDiscountValue.disabled = true
            fieldDiscountPersentage.disabled = false
        }
        if (discauntValue !== '') {
            fieldDiscountValue.disabled = false
            fieldDiscountPersentage.disabled = true
        }
        calculate()
    // eslint-disable-next-line
    }, [discauntValue, discauntPercentage])

    return (
        <div className='table-order-conteiner'>
            <table className="Table">
                <thead id="table-head">
                    <tr>
                        <th className='table-orders-col1 table-orders-col__center'><span></span></th>
                        <th className='table-orders-col1 table-orders-col__center'><span>№</span></th>
                        <th className='table-orders-col2 table-orders-col__center'><span>Товар</span></th>
                        <th className='table-orders-col table-orders-col__center'><span>Количество</span></th>
                        <th className='table-orders-col table-orders-col__center'><span>Цена</span></th>
                        <th className='table-orders-col table-orders-col__center'><span>Стоимость</span></th>
                        <th className='table-orders-col table-orders-col__center'><span>Скидка</span></th>
                        <th className='table-orders-col table-orders-col__center'><span>Итого</span></th>
                        <th className='table-orders-col table-orders-col__center'><span></span></th>
                    </tr>
                </thead>
                <tbody id="table-body">
                    {
                        goods.length !== 0 ?
                        goods.map((item, index) => {
                            return(
                                <tr key={Math.random()}>
                                    {/* Удалить товар */}
                                    <td className='table-orders-col1 table-orders-col__center'><span className="table-orders-delete" data-delete-goods={item.goods.id} onClick={() => deleteGoods(item.id)}></span></td>
                                    {/* Номер товара */}
                                    <td className='table-orders-col1 table-orders-col__center'><span>{ index + 1 }</span></td>
                                    {/* Название товара */}
                                    <td className='table-orders-col2'><span>{ item.goods.name }</span></td>
                                    {/* Количество товара */}
                                    <td className='table-orders-col table-orders-col__center'><span><input type="number" value={ item.count !== null ? item.count : 1 } onChange={e => changeCount(item.id, e.target.value)} id={ "count-id_" + item.id }/></span></td>
                                    {/* Цена товара */}
                                    <td className='table-orders-col table-orders-col__center'><span id={"price-id_" + item.id}>{ item.goods.price }</span></td>
                                    {/* Стоимость товара */}
                                    <td className='table-orders-col table-orders-col__center'><span name="total-price" id={"total-id_" + item.id}>{ item.count !== null ? item.goods.price * item.count : item.goods.price }</span></td>
                                    {/* Скидка */}
                                    <td className='table-orders-col table-orders-col__center'><ShopCardDiscontBlock shopCard={ shopCard } setShopCard={ setShopCard } id={ item.id } price={ Number(item.goods.price) } count={ Number(item.count) } discontValue={item.totalPrice !== null ? (Number(item.count) * Number(item.goods.price)) - Number(item.totalPrice) : null} /><span className='good-discount-cell' name="good-discount" id={"good-discount-id_" + item.id} onClick={ e => makeDiscont(item.id) }>{item.totalPrice != null ? item.goods.price * item.count - item.totalPrice : 0}</span></td>
                                    {/* Стоимость с учётом скидки */}
                                    <td className='table-orders-col table-orders-col__center'><span name="good-total-price" id={"good-total_" + item.id}>{item.totalPrice != null ? item.totalPrice : item.count !== null ? item.goods.price * item.count : item.goods.price}</span></td>
                                    {/* Резерв */}
                                    <td className='table-orders-col table-orders-col__center'><span name="reservation" id={"reservation_" + item.id} className='reservation-btn' onClick={ () => reservation(item.goods.id, item.count) }>{ reservationName(item.goods.id) }</span></td>
                                </tr>
                            )}) :
                        <tr className="table-orders-null">
                            <td>В заказе еще нет товаров, добавьте их с помощью кнопки «Добавить товар» справа</td>
                        </tr>
                    }
                </tbody>
            </table>
            <div id='table-bottom-head'>
                <div    className='table-bottom-head_h1'>Разовая скидка</div>
                <input  className='table-bottom-head_input' 
                        id="order-discaunt-value" 
                        type="number"
                        step="any"
                        value={discauntValue} 
                        onChange={e => setDiscauntValue(e.target.value)} />
                <label  className='table-bottom-head_label' htmlFor='order-discaunt-value'>₽</label>
                <input  className='table-bottom-head_input' 
                        id="order-discaunt-percentage" 
                        type="number"
                        step="any"
                        value={discauntPercentage}
                        onChange={e => setDiscauntPercentage(e.target.value)} />
                <label  className='table-bottom-head_label' htmlFor='order-discaunt-percentage'>%</label>
            </div>
            <div id='table-bottom'>
                    <div id='table-bottom-total'>
                        <div className='table-bottom-row'>
                            <div className='table-bottom-key'>Стоимость товаров</div>
                            <div className='table-bottom-value' id="card-shop-total">{ calcTotal }</div>
                            <div className='table-bottom-currency'>₽</div>
                        </div>
                        <div className='table-bottom-row'>
                            <div className='table-bottom-key'>Сумма скидок по заказу</div>
                            <div className='table-bottom-value' id="card-shop-sales">{ calcDiscont }</div>
                            <div className='table-bottom-currency'>₽</div>
                        </div>
                        <div className='table-bottom-row'>
                            <div className='table-bottom-key'>Стоимость доставки</div>
                            <div className='table-bottom-value' id="card-shop-delivery">{ calcDelivery }</div>
                            <div className='table-bottom-currency'>₽</div>
                        </div>
                        <div className='table-bottom-row'>
                            <div className='table-bottom-key table-bottom-key__total'>Итого</div>
                            <div className='table-bottom-value table-bottom-key__total' id="card-shop-all">{ calcTotalPrice }</div>
                            <div className='table-bottom-currency table-bottom-key__total'>₽</div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default TableCardShop;