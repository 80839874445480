import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const TableProductsSaldo = observer(({ products, reservation }) => {
    const navigate = useNavigate()

    const linkToGood = (id) => {
        navigate('/sales/products/' + id)
    }

    console.log(reservation)

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (products.length > 0) {
                products.sort((a, b) => {
                    if (a.goods.id === b.goods.id) return 0;
                    if (a.goods.id > b.goods.id) return 1;
                    else return -1;
                }).forEach(element => {

                    let countGood = element.count
                    let reservationGood = reservation != null ? Number(reservation.filter(item => item.goods.id === element.goods.id).map(item => item.count)) : 0
                    let totalGood = element.count - reservationGood

                    html += `
                        <tr id="link-to_${ element.goods.id }">
                            <td class='table-col-40'>${ element.goods.name != null ? element.goods.name : '-' }</td>
                            <td class='table-col-15 table-align-center'>${ element.goods.shops != null ? element.goods.shops.name : '-' }</td>
                            <td class='table-col-10 table-align-center'>${ element.count != null ? countGood : 0 }</td>
                            <td class='table-col-10 table-align-center'>${ reservation != null ? reservationGood : 0 }</td>
                            <td class='table-col-10 table-align-center'>${ element.count != null ? totalGood : 0 }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            products.forEach(element => {
                let link = document.querySelector('#link-to_' + element.goods.id)
                link.addEventListener('click', () => linkToGood(element.goods.id))
            })

    // eslint-disable-next-line
    }, [products])

    return (
        <div className='table-box'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-col-40'><span>Название</span></th>
                        <th className='table-col-15 table-align-center'><span>Магазин</span></th>
                        <th className='table-col-10 table-align-center'><span>Общее количество</span></th>
                        <th className='table-col-10 table-align-center'><span>Резерв товара</span></th>
                        <th className='table-col-10 table-align-center'><span>Свободный остаток</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
    )
})

export default TableProductsSaldo;