import "./UserSetting.css"
import React, { useEffect } from 'react'
import Row from "../../common/marking/row"
import Column from "../../common/marking/column"
import InputLable from "../../common/elements/input-lable"
import InputLableDisabled from "../../common/elements/input-lable-disabled"
import InputPhoto from "../../common/elements/input-photo"
import InputCheckbox from "../../common/elements/input-checkbox"
import { observer } from "mobx-react-lite"

const UserSetting = observer(( { user, groups } ) => {

    useEffect(() => {
        const secondMenu = document.querySelectorAll('.MenuSecond')
        const firstMenu = document.querySelectorAll('.MenuFirstElement')
        const iconMenu = document.querySelectorAll('.MenuFirstElement__icon-active')
        const button = document.querySelector('#ContantContainer__hide-button')
        secondMenu[0].classList.remove('MenuSecond__active')
        button.classList.add('MenuSecond__hide')
        firstMenu.forEach(item => item.classList.remove('MenuFirstElement__active'))
        iconMenu.forEach(item => {
            item.classList.remove('MenuFirstElement__icon-active')
            item.classList.add('MenuFirstElement__icon-inactive')
        })

    // eslint-disable-next-line
    },[])

    return (
        <div className="UserSetting">
            <div className="user-setting-container user-setting-container__not-border">
                <div className="user-setting-container__lable"><div>ОСНОВНЫЕ ДАННЫЕ</div></div>
                <Row contant={ 
                    <div>
                        <Column contant={ 
                            <div>
                                <InputLable lableText="Имя" inputId="user_firstname" inputeValue={ user.username !== null ? user.username : "" } isRequired={ true } settingsName="username" />
                                <InputLable lableText="Отчество" inputId="user_patronymic" inputeValue={ user.patronymic !== null ? user.patronymic : "" } isRequired={ false } settingsName="patronymic" />
                                <InputLableDisabled lableText="Email" inputId="user_email" inputeValue={ user.email !== null ? user.email : "" } href="/email" text="Изменить email" settingsName="email" />
                                <div className='user-setting-email-box'>Подтвержден</div>
                                <InputLableDisabled lableText="Пароль" inputId="user_password" inputeValue="" href={ "/password/" + user.id } text="Изменить пароль" />
                            </div>
                         } />
                        <Column contant={
                            <div>
                                <InputLable lableText="Фамилия" inputId="user_surname" inputeValue={ user.surname !== null ? user.surname : "" } isRequired={ false } settingsName="surname" />
                                <InputPhoto lableText="Фотография" userValue={ user } />
                            </div>
                        } />
                    </div>
                } />
            </div>
            <div className="user-setting-container">
                <div className="user-setting-container__lable"><div>ИНФОРМАЦИЯ</div></div>
                <Row contant={ 
                    <div>
                        <Column contant={ 
                            <div>
                                <InputLable lableText="Должность" inputId="user_job_title" inputeValue={ user.jobTitle !== null ? user.jobTitle : "" } isRequired={ false } settingsName="jobTitle" />
                            </div>
                         } />
                        <Column contant={
                            <div>
                                <InputLable lableText="Телефон" inputId="user_phone" inputeValue={ user.phone !== null ? user.phone : "" } isRequired={ false } settingsName="phone" />
                            </div>
                        } />
                    </div>
                } />
            </div>
            <div className="user-setting-container">
                <div className="user-setting-container__lable"><div>ДОПОЛНИТЕЛЬНЫЕ НАСТРОЙКИ</div></div>
                <Row contant={ 
                    <div>
                        <Column contant={ 
                            <div>
                                <InputCheckbox lableText="Группы" inputId="user_groups" data={ groups } settingsName="roles" user={ user } />
                            </div>
                        } />
                    </div>
                } />
            </div>
        </div>
    )
})

export default UserSetting;
