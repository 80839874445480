import '../orders.css';
// eslint-disable-next-line
import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite';
import Popup from "reactjs-popup";
import api from "../../../../services/api";
import { useNavigate } from 'react-router-dom';

const TaskBar = observer(({ order }) => {

    const [ orderData, setOrderData ] = useState(order)
    const [ group, setGroup ] = useState(null)
    const [ step, setStep ] = useState(null)

    const navigator = useNavigate()

    const scrollToElement = (event, link) => {
        event.preventDefault();
        const element = document.querySelector(`#${link}`)
        element.scrollIntoView({
            behavior: 'smooth'
        })
    }

    const sortedByPriority = (a, b) => {
        if (a.priority === b.priority) return 0;
        if (a.priority > b.priority) return 1;
        else return -1;
    }
    
    const changeStatus = (orderId, statusId) => {
        let data = new FormData()
        data.append('order', orderId)
        data.append('status', statusId)
        api.post('/orders/update-status', data)
            .then(res => setOrderData(res.data))
            .catch(e => console.log(e))
    }

    const changeVip = (element) => {
        let dataOrder = new FormData()
        dataOrder.append('id', orderData.id)
        if(!orderData.tagVip) {
            dataOrder.append('status', true)
            api.post('/orders/order-tag-vip', dataOrder)
                .then(res => setOrderData(res.data))
            element.remove('order-icon__vip')
            element.add('order-icon__vip-active')
        } else {
            dataOrder.append('status', false)
            api.post('/orders/order-tag-vip', dataOrder)
                .then(res => setOrderData(res.data))
            element.add('order-icon__vip')
            element.remove('order-icon__vip-active')
        }
    }
    const changeBad = (element) => {
        let dataOrder = new FormData()
        dataOrder.append('id', orderData.id)
        if(!orderData.tagBad) {
            dataOrder.append('status', true)
            api.post('/orders/order-tag-bad', dataOrder)
                .then(res => setOrderData(res.data))
            element.remove('order-icon__bad')
            element.add('order-icon__bad-active')
        } else {
            dataOrder.append('status', false)
            api.post('/orders/order-tag-bad', dataOrder)
                .then(res => setOrderData(res.data))
            element.add('order-icon__bad')
            element.remove('order-icon__bad-active')
        }
    }
    const changePass = (element) => {
        let dataOrder = new FormData()
        dataOrder.append('id', orderData.id)
        if(!orderData.tagPass) {
            dataOrder.append('status', true)
            api.post('/orders/order-tag-pass', dataOrder)
                .then(res => setOrderData(res.data))
            element.remove('order-icon__pass')
            element.add('order-icon__pass-active')
        } else {
            dataOrder.append('status', false)
            api.post('/orders/order-tag-pass', dataOrder)
                .then(res => setOrderData(res.data))
            element.add('order-icon__pass')
            element.remove('order-icon__pass-active')
        }
    }
    const changeCall = (element) => {
        let dataOrder = new FormData()
        dataOrder.append('id', orderData.id)
        if(!orderData.tagCall) {
            dataOrder.append('status', true)
            api.post('/orders/order-tag-call', dataOrder)
                .then(res => setOrderData(res.data))
            element.remove('order-icon__call')
            element.add('order-icon__call-active')
        } else {
            dataOrder.append('status', false)
            api.post('/orders/order-tag-call', dataOrder)
                .then(res => setOrderData(res.data))
            element.add('order-icon__call')
            element.remove('order-icon__call-active')
        }
    }

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-order-status-group', {})
                .then(res => setGroup(res.data))
                .catch(e => console.log(e))
            const statusData = new FormData()
            statusData.append('status', orderData.orderStatus.id)
            await api.post('/orders/get-status-step', statusData)
                .then(res => setStep(res.data))
                .catch(e => console.log(e))
        }
        if (orderData != null && orderData.id !== null) getData()
    // eslint-disable-next-line    
    }, [orderData])

    return (
        
        <div className='order-task-bar'>
            {
                orderData != null && orderData.id != null ?
                <div className='order-task-bar__top'>
                    <div className='order-task-bar__top-left'>
                        <div className={ orderData.tagVip ? 'task-bar__order-icon order-icon__vip-active' : 'task-bar__order-icon order-icon__vip' } onClick={ (e) => changeVip(e.target.classList) }></div>
                        <div className={ orderData.tagBad ? 'task-bar__order-icon order-icon__bad-active' : 'task-bar__order-icon order-icon__bad' } onClick={ (e) => changeBad(e.target.classList) }></div>
                        <div className={ orderData.tagPass ? 'task-bar__order-icon order-icon__big order-icon__pass-active' : 'task-bar__order-icon order-icon__big order-icon__pass' } onClick={ (e) => changePass(e.target.classList) }></div>
                        <div className={ orderData.tagCall ? 'task-bar__order-icon order-icon__big order-icon__call-active' : 'task-bar__order-icon order-icon__big order-icon__call' } onClick={ (e) => changeCall(e.target.classList) }></div>
                        <Popup
                            trigger={
                                <div className="btn-container btn-container-left">
                                    Управление
                                </div>
                            }
                            position={["bottom left"]}
                            closeOnDocumentClick
                        >
                            <div className="popover-content">
                                <Popup
                                    trigger={
                                        <div className="btn-container">
                                            Печать
                                        </div>
                                    }
                                    position={["right"]}
                                    closeOnDocumentClick
                                    on={"hover"}
                                >
                                    <div className="popover-content-link popover-content-right">
                                        <div className="btn-container-link" onMouseDown={() => navigator('/sales/order/torg12/' + orderData.id)}>Накладная</div>
                                        <div className="btn-container-link" onMouseDown={() => navigator('/sales/order/invoice/' + orderData.id)}>Счет на оплату</div>
                                    </div>
                                </Popup>
                            </div>
                        </Popup>
                        <div className='order-task-bar__separator'></div>
                    </div>
                    {
                        orderData != null && orderData.id !== null ?
                        <div className='order-task-bar__status'>
                            <div className='order-task-bar-status__top'>{ group !== null ? 
                            group.sort(sortedByPriority).map(item => {
                                if (orderData.orderStatus.statusGroup.id === item.id)
                                    return <div key={Math.random()} className='status-group-item status-group-item__hide' style={{background: `#${item.backgroundColor}`}}></div>
                                else
                                    return <div key={Math.random()} className='status-group-item' style={{background: `#${item.backgroundColor}`}}></div>
                            })
                            : '' }</div>
                                { 
                                    orderData !== null && orderData.id !== null ? 
                                    <div className='order-task-bar-status__bottom' style={{background: `#${orderData.orderStatus.statusGroup.backgroundColor}`, color: `#${orderData.orderStatus.statusGroup.color}`}}>
                                        <Popup
                                            trigger={
                                                <div>
                                                    { orderData.orderStatus.name }
                                                </div>
                                            }
                                            position={["bottom left"]}
                                            arrow={false}
                                            closeOnDocumentClick
                                        >
                                            <div className="popover-content-status">
                                                {
                                                    step !== null ?
                                                        // Array.from([...new Set(step.nextStatus.map(item => item.statusGroup))])
                                                        //     .map(el => <div className="popover-content-status-group">el.name</div>)
                                                            [...new Map(
                                                                step.nextStatus
                                                                    .map(item => [item.statusGroup.id, item.statusGroup])).values()
                                                            ].map(element => <div key={Math.random()} className="popover-content-status-group" style={{background: `#${element.backgroundColor}`, color: `#${element.color}`}}>
                                                                {
                                                                    step.nextStatus
                                                                        .filter(item => item.statusGroup.id === element.id)
                                                                        .map(item => <div key={Math.random()} className='popover-content-status-item' onClick={() => changeStatus(orderData.id, item.id)}>{ item.name }</div>)
                                                                }
                                                            </div>)
                                                    : ''       
                                                }
                                            </div>
                                        </Popup>
                                    </div>
                                : '' }
                        </div>
                        : ""
                    }
                </div>
                : ""
            }
            <div className='order-task-bar__bottom'>
                <ul>
                    <li onClick={(e) => scrollToElement(e, "content-common")}>Основное</li>
                    <li onClick={(e) => scrollToElement(e, "content-customer")}>Клиент</li>
                    <li onClick={(e) => scrollToElement(e, "content-card-shop")}>Состав заказа</li>
                    <li onClick={(e) => scrollToElement(e, "content-delivery")}>Доставка</li>
                    <li onClick={(e) => scrollToElement(e, "content-comments")}>Комментарии</li>
                </ul>
            </div>
        </div>
        
    )
})

export default TaskBar;