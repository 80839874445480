import './PasswordPage.css';
import React, { useState } from 'react'
import api from "../../../services/api"
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useNavigate } from "react-router-dom";

const PasswordPage = observer(() => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [ oldPassword, setOldPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ rePassword, setRePassword ] = useState('')

    const clearStatus = (data) => {
        setOldPassword('');
        setNewPassword('');
        setRePassword('');

        setTimeout(() => {
            data.success.classList.remove('passowrd-success__active')
            data.wrong.classList.remove('passowrd-wrong__active')
            data.weak.classList.remove('passowrd-wrong__active')
        }, 3000)
    }

    const changePassword = async () => {
        const inputRePassword = document.querySelector('[name="re-password"]')
        const success = document.querySelector('#passowrd-success')
        const wrong = document.querySelector('#passowrd-wrong')
        const weak = document.querySelector('#passowrd-weak')
        const condition = /[0-9a-zA-Z!@#$%^&*]{8,}/g

        if (newPassword === rePassword) {
            if (condition.test(newPassword)) {
                inputRePassword.classList.remove('re-password__wrong')
            
                let data = new FormData()
                data.append('id', id)
                data.append('oldpassword', oldPassword)
                data.append('password', newPassword)
    
                api.post('user/change-password', data)
                    .then(response => {
                        if (response.data.status === "1") {
                            success.classList.remove('passowrd-success__active')
                            wrong.classList.add('passowrd-wrong__active')
                            weak.classList.remove('passowrd-wrong__active')
                        }
                        if (response.data.status === "0") {
                            success.classList.add('passowrd-success__active')
                            wrong.classList.remove('passowrd-wrong__active')
                            weak.classList.remove('passowrd-wrong__active')
                            clearStatus({success: success, wrong: wrong, weak: weak})
                        }
                    })
            } else {
                success.classList.remove('passowrd-success__active')
                wrong.classList.remove('passowrd-wrong__active')
                weak.classList.add('passowrd-wrong__active')
            }
        } else {
            inputRePassword.classList.add('re-password__wrong')
        }
    }

    return (
        <div className="PasswordPage">
            <div className="password-col-1">
                <div className="password-top">
                    <Link onClick={ navigate(-1) }>Назад</Link>
                </div>
                <div className="password-contant">
                    <div className="password-contant-top">Изменить пароль</div>
                    <input name="old-password" placeholder="Старый пароль" value={oldPassword} onChange={ e => { setOldPassword(e.target.value) } } />
                    <input name="new-password" placeholder="Новый пароль" value={newPassword} onChange={ e => setNewPassword(e.target.value) } />
                    <input name="re-password" placeholder="Повторить новый пароль" value={rePassword} onChange={ e => setRePassword(e.target.value) } />
                    <button type="button" id="password-btn" onClick={ () => { changePassword() } }>Изменить</button>
                    <div id="passowrd-success">Пароль сохранен</div>
                    <div id="passowrd-wrong">Проверьте правельность введения старого пароля</div>
                    <div id="passowrd-weak">Пароль должен быть от 8 до 20 символов и состоять из букв английского алфавита, цифр и символов</div>
                </div>
            </div>
            <div className="password-col-2">
                <div className="password-page-image"></div>
            </div>
        </div>
    )
})

export default PasswordPage;