import './inputes.css';
import React, { useEffect, useRef, useState } from 'react'

const InputHSelect = ({
    marginY,        // Margin top and bottom : Integer
    label,          // Label's title : String
    classInput,     // Class for input
    inputId,        // Input's id : String
    value,          // Input's value : String
    method,         // Input's onChange method : setState()
    disabled,       // Input's disabled : Boolean
    data,           // Array of data
    required        // Required field or not
}) => {
    
    const [ state, setState ] = useState( value != null ? value : 'all' )
    let randomId = useRef('input_' + Math.floor(Math.random() * (10000 - 10 + 1)) + 10)

    useEffect(() => {
        let selectBox;
        if (inputId != null) selectBox = document.querySelector('#' + inputId)
        else selectBox = document.querySelector('#' + randomId.current)

        if (state === 'all') selectBox.classList.add('input-horizontal-select__hold')
        else selectBox.classList.remove('input-horizontal-select__hold')

        if (method != null && typeof method === 'function')
            method(state)

    // eslint-disable-next-line
    }, [state])

    // useEffect(() => {
    //     setState(value)
    // }, [value])

    return (
        <div 
            className='input-horizontal' 
            style={{ 
                marginBottom: marginY != null ? marginY + 'px' : 10 + 'px',
                marginTop: marginY != null ? marginY + 'px' : 10 + 'px' }} >
            <label className='input-horizontal__label'>{ label !== null ? label : '' }</label>
            <select
                id={ inputId != null ? inputId : randomId.current }
                className={ `input input-horizontal__select ${ classInput != null ? classInput : '' }` }
                value={ state }
                onChange={ (e) => setState(e.target.value) }
                disabled={ disabled === true ? true : false }
                data-required={ required != null ? true : false } >
                    <option value="all">Выберите значение</option>
                    {
                        data.map(item => {
                            return (
                                <option 
                                    key={ Math.random() } 
                                    value={ item.id }>{ item.name }
                                </option>
                            )
                        })
                    }
            </select>
        </div>
    )
}

export default InputHSelect;