import React from "react"
import './torg12.css'
import { COMPANY } from "../../settings"
import { getString } from '../../services/util'
import { getMonth } from '../../enums/month'

export const TORG12Document = ({ order }) => {

    return(
        <table className="print ttn">
            <tbody>
                <tr><td colSpan="2" className="text-right row-desc"><p>Унифицированная форма № ТОРГ-12 Утверждена постановлением Госкомстата России от 25.12.98 № 132</p></td></tr>
                <tr><td colSpan="2" className="pt-5"></td></tr>
                <tr>
                    <td>
                        <table className="valign-middle" width="100%">
                            <tbody>
                                <tr>
                                    <td colSpan="3"></td>
                                    <td className="b text-center" width="100">Код</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="h17 psm text-right">Форма по ОКУД</td>
                                    <td className="text-center bb2 no-bb" >345678</td>
                                </tr>
                                <tr>
                                <td colSpan="2" className="bb" >{ COMPANY.nameShort }, { COMPANY.orgn }, {COMPANY.address}, <br/>р/с { COMPANY.bankAccount } , в банке { COMPANY.bankName }, БИК { COMPANY.bankBik }, к/с { COMPANY.bankAccountKor }</td>
                                    <td className="psm text-right">по ОКПО</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(организация-грузоотправитель, адрес, телефон, факс, банковские реквизиты)</td>
                                    <td></td>
                                    <td className="b bbl bbr text-center" rowSpan="2" ></td>
                                </tr>
                                <tr><td colSpan="3" className="bb" ></td></tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(структурное подразделение)</td>
                                    <td>Вид деятельности по ОКДП</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td width="105px">Грузополучатель</td>
                                    <td className="bb" >{ order != null ? getString(order.customers.firstname) + ' ' + getString(order.customers.surname) + getString(order.customers.patronymic) : '' }, { order != null ? order.customers.address != null ? order.customers.address.value : '' : '' }</td>
                                    <td className="psm text-right">по ОКПО</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(организация, адрес, телефон, факс, банковские реквизиты)</td>
                                    <td></td>
                                    <td className="b bbl bbr text-center" rowSpan="2" ></td>
                                </tr>
                                <tr>  
                                    <td>Поставщик</td>
                                    <td className="bb" > { COMPANY.nameShort }, { COMPANY.orgn }, { COMPANY.address }, <br/>р/с { COMPANY.bankAccount }, в банке { COMPANY.bankName }, БИК { COMPANY.bankBik }, к/с { COMPANY.bankAccountKor }</td>
                                    <td className="psm text-right">по ОКПО</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(организация, адрес, телефон, факс, банковские реквизиты)</td>
                                    <td></td>
                                    <td className="b bbl bbr text-center" rowSpan="2" ></td>
                                </tr>
                                <tr>
                                    <td>Плательщик</td>
                                    <td className="bb" > { order != null ? getString(order.customers.firstname) + ' ' + getString(order.customers.surname) : '' }, { order != null ? order.customers.address != null ? order.customers.address.value : '' : '' }</td>
                                    <td className="psm text-right">по ОКПО</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(организация, адрес, телефон, факс, банковские реквизиты)</td>
                                    <td className="b text-right psm" rowSpan="2">номер</td>
                                    <td className="b bbl bbr text-center" rowSpan="2" ></td>
                                </tr>
                                <tr>
                                    <td className="col-4 no-bb">Основание</td>
                                    <td className="bb" >–</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="row-desc">(договор, заказ-наряд)</td>
                                    <td className="b h17 text-right psm">дата</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><p className="text-lead text-right psm">Транспортная накладная</p></td>
                                    <td className="b h17 psm text-right">номер</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"></td>
                                    <td className="b h17 text-right psm">дата</td>
                                    <td className="b bbl bbr text-center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="text-right psm">Вид операции</td>
                                    <td className="b bbb bbl bbr h17 text-center" ></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td className="text-lead text-bold text-right psm torg-12-3">ТОВАРНАЯ НАКЛАДНАЯ</td>
                                    <td className="psm">
                                        <table className="text-center table-border table-ttn-date">
                                            <tbody>
                                                <tr>
                                                    <td>Номер документа</td>
                                                    <td>Дата составления</td>
                                                </tr>
                                                <tr>
                                                    <td >{ order != null ? order.id : ''}</td>
                                                    <td >{ new Date(Date.now()).toISOString().slice(0, 10) }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </td>
                </tr>            
                <tr>
                    <td colSpan="2">
                        <table className="table-torg12 table-border" width="100%">
                            <thead>
                                <tr>
                                    <td rowSpan="2" className="text-center">Но-<br/>мер<br/>по по-<br/>рядку</td>
                                    <td colSpan="2">Товар</td>
                                    <td colSpan="2"><nobr>Единица измерения</nobr></td>
                                    <td rowSpan="2">Вид упа-<br/>ковки</td>
                                    <td colSpan="2">Количество</td>
                                    <td rowSpan="2">Масса<br/>брутто</td>
                                    <td rowSpan="2">Коли-<br/>чество<br/>(масса<br/>нетто)</td>
                                    <td rowSpan="2">Цена,<br/>руб. коп.</td>
                                    <td rowSpan="2"> <nobr>Сумма без </nobr><br/>учета НДС,<br/>руб. коп. </td>
                                    <td colSpan="2">НДС</td>
                                    <td rowSpan="2">Сумма с<br/>учетом<br/>НДС,<br/>руб. коп.</td>
                                </tr>
                                <tr>
                                    <td>наименование, характеристика, сорт, артикул товара</td>
                                    <td>код</td>
                                    <td>наиме-<br/>нование</td>
                                    <td>код по ОКЕИ</td>
                                    <td> в<br/>одном<br/>месте </td>
                                    <td> мест, <br/> штук </td>
                                    <td>ставка, %</td>
                                    <td>сумма,<br/>руб. коп.</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                    <td>6</td>
                                    <td>7</td>
                                    <td>8</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>11</td>
                                    <td>12</td>
                                    <td>13</td>
                                    <td>14</td>
                                    <td>15</td>
                                </tr>
                            </thead>
                            <tbody className="torg12-content">
                                {
                                    order != null ?
                                    order.shopCard.map((item, index) => {
                                        return(
                                            <tr key={Math.random()}>
                                                <td className="td1">{ index + 1 }</td>
                                                <td className="td2">{ item.goods.name }</td>
                                                <td className="td3"></td>
                                                <td className="td4">шт</td>
                                                <td className="td5">796</td>
                                                <td className="td6"></td>
                                                <td className="td7"></td>
                                                <td className="td8">{  item.count }</td>
                                                <td className="td9"></td>
                                                <td className="td10">{ item.count }</td>
                                                <td className="td11">{ item.goods.price }</td>
                                                <td className="td12">{ item.goods.price * item.count }</td>
                                                <td className="td13">Без НДС</td>
                                                <td className="td14"></td>
                                                <td className="td15"><nobr>{ item.goods.price * item.count }</nobr></td>
                                            </tr>
                                        )
                                    })
                                    : ''
                                }
                                <tr>
                                <td colSpan="7" align="right" className="no-b psm">Итого </td>
                                <td align="center">{ order != null ? order.shopCard.lenght : '' }</td>
                                <td></td>
                                <td align="center">{ order != null ? order.shopCard.lenght : '' }</td>
                                <td align="center">X</td>
                                <td align="right">{ order != null ? order.shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0) : '' }</td>
                                <td align="center">X</td>
                                <td></td>
                                <td align="right">{ order != null ? order.shopCard.reduce((acc, item) => acc + item.count * item.goods.price, 0) : '' }</td>
                                </tr>
                            </tbody>
                        </table>
                        <table width="85%" align="right">
                            <tbody>
                                <tr>
                                    <td>
                                        <table width="60%">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">Товарная накладная имеет приложение на</td>
                                                    <td className="bb" width="50%" ></td>
                                                    <td>листах</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td>и содержит</td>
                                                    <td className="bb" colSpan="2" width="45%" >{ order != null ? order.shopCard.lenght : '' }</td>
                                                    <td>порядковых номеров записей</td>
                                                </tr>
                                                <tr>
                                                    <td className="row-desc" colSpan="3">(прописью)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width="85%" align="right" className="valign-bottom">
                            <tbody>
                                <tr>
                                    <td colSpan="3" align="right" className="pr-10">Масса груза (нетто)	</td>
                                    <td className="bb"  align="center"></td>
                                    <td className="bb2 h20" width="195" align="center" ></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"></td>
                                    <td className="row-desc">(прописью)</td>
                                    <td rowSpan="2" className="bb2" align="center" ></td>
                                </tr>
                                <tr>
                                    <td width="100">Всего мест</td>
                                    <td className="bb" width="130" ></td>
                                    <td width="150" align="right" className="pr-10">Масса груза (брутто)</td>
                                    <td className="bb"  align="center"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="row-desc">(прописью)</td>
                                    <td></td>
                                    <td className="row-desc">(прописью)</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="50%" className="torg-12-1">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-10">Приложение (паспорта, сертификаты и т.п.) на</td>
                                                    <td className="bb" width="150" ></td>
                                                    <td className="pl-10">листах</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="row-desc">(прописью)</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3" className="text-bold bb">Всего отпущено на сумму<br/></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3" className="row-desc">(прописью)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-10">Отпуск груза разрешил</td>
                                                    <td className="bb" width="120" align="center" >{ COMPANY.seoDiscription }</td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="100"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="140" align="center" >{ COMPANY.seoShortName }</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="row-desc">(должность)</td>
                                                    <td></td>
                                                    <td className="row-desc">(подпись)</td>
                                                    <td></td>
                                                    <td className="row-desc">(расшифровка подписи)</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-10" colSpan="2" align="center">{ COMPANY.chiefAccountantDiscription }</td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="100"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="140" align="center">{ COMPANY.chiefAccountantName }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"></td>
                                                    <td></td>
                                                    <td className="row-desc">(подпись)</td>
                                                    <td></td>
                                                    <td className="row-desc">(расшифровка подписи)</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-10">Отпуск груза произвел</td>
                                                    <td className="bb" width="120" align="center" >{ COMPANY.seoDiscription }</td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="100"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="140" align="center" >{ COMPANY.seoShortName }</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="row-desc">(должность)</td>
                                                    <td></td>
                                                    <td className="row-desc">(подпись)</td>
                                                    <td></td>
                                                    <td className="row-desc">(расшифровка подписи)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table align="right" width="75%" >
                                            <tbody>
                                                <tr>
                                                    <td width="25%">М.П.</td>
                                                    <td>{ `"${ new Date(Date.now()).getDate() }"` }</td>
                                                    <td width="25%" className="bb">{`${ getMonth(new Date(Date.now()).getMonth()) }`}</td>
                                                    <td></td>
                                                    <td width="25%">{`${ new Date(Date.now()).getFullYear() }`}</td>
                                                </tr>
                                            </tbody>  
                                        </table>
                                    </td>
                                    <td className="pl-10">
                                        <table className="valign-bottom">
                                            <tbody>
                                                <tr>
                                                    <td className="pr-10">По доверенности №</td>
                                                    <td className="bb" width="150"></td>
                                                    <td className="psm bb torg-12-2">от</td>
                                                    <td className="bb" width="245"></td>
                                                </tr>
                                                <tr>
                                                    <td>выданной</td>
                                                    <td className="bb h20" colSpan="3"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan="3" className="row-desc">(кем, кому (организация, должность, фамилия, и. о.))</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="bb h20" colSpan="3"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-10">Груз принял</td>
                                                    <td className="bb" width="120"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="100"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="140"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="row-desc">(должность)</td>
                                                    <td></td>
                                                    <td className="row-desc">(подпись)</td>
                                                    <td></td>
                                                    <td className="row-desc">(расшифровка подписи)</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-10">Груз получил грузополучатель</td>
                                                    <td className="bb" width="120"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="100"></td>
                                                    <td className="w20"></td>
                                                    <td className="bb" width="140"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="row-desc">(должность)</td>
                                                    <td></td>
                                                    <td className="row-desc">(подпись)</td>
                                                    <td></td>
                                                    <td className="row-desc">(расшифровка подписи)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table align="right" width="75%" >
                                            <tbody>
                                                <tr>
                                                    <td width="25%">М.П.</td>
                                                    <td className="psm">" &nbsp; &nbsp; "</td>
                                                    <td width="25%" className="bb"></td>
                                                    <td className="psm">20 &nbsp; &nbsp; года</td>
                                                    <td width="25%"></td>
                                                </tr>
                                            </tbody>  
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}