import './products.css';
import '../element.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import api from '../../../services/api'
import ContentBlock from '../../common/elements/content-block';
import InputH from '../../elements/inpute/InputH';
import InputHTextarea from '../../elements/inpute/InputHTextarea';
import Foother from '../../common/marking/foother';
import ButtonSaveData from '../../elements/button/Save';

const ProviderNew = observer(() => {

    const { id } = useParams()

    // fields values
    const [ fieldName, setFieldName ] = useState('')
    const [ fieldDescription, setFieldDescription ] = useState('')

    let dataProvider = new FormData()
        dataProvider.append("id", id != null ? id : 0)
        dataProvider.append("name", fieldName != null ? fieldName : "")
        dataProvider.append("description", fieldDescription != null ? fieldDescription : "")

    useEffect(() => {
        let dataProvider = new FormData()
        dataProvider.append('id', Number(id))

        const getGood = async () => {
            await api.post('/goods/get-provider', dataProvider)
                .then(res => {
                    setFieldName(res.data.name != null ? res.data.name : "")
                    setFieldDescription(res.data.description != null ? res.data.description : "")
                })
                .catch(e => console.log(e))
        }

        if (id != null) {
            getGood()
        }

    // eslint-disable-next-line
    },[])

    return (

        <div className="Element">
            <div className='element-top'>
                <div className='element-links'>
                    <Link to={'/'}>Главная</Link>
                    <span>→</span>
                    <Link to={'/goods/provider'}>Поставщики</Link>
                </div>
                <div className='element-h1'>{ id != null ? fieldName : 'Новый поставщик' }</div>
            </div>
            <ContentBlock title="Главная информация" name="main" defaultState={true} button={null} content={
                <div className='ContentBlockBody'>
                    <div className='content-body-padding-70'>
                        <InputH label={'Название'} value={fieldName} method={setFieldName} marginY={25} required={true} />
                        <InputHTextarea label={'Описание'} value={fieldDescription} method={setFieldDescription} marginY={25} size={'big'}/>
                    </div>
                </div>
            }/>
            <Foother left={true} contant={
                <div className="element-foother">
                    <ButtonSaveData text="Сохранить" url={'/goods/save-provider'} params={dataProvider}/>
                    <div id="element-save__success" className="d-none">Сохранено</div>
                    <div id="element-save__wrong" className="d-none">Ошибка сохранения</div>
                </div>
            }/>
        </div>
        
    )
})

export default ProviderNew;