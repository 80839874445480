import './filters.css';
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import shopFilterStorage from "../../../../storage/shop-filter"
import Status from "./shops-filter/status"
import Name from "./shops-filter/name"
import StatusesGroup from './shops-filter/status-group';
import api from "../../../../services/api"

const StatusFilter = observer(() => {

    // eslint-disable-next-line
    const { setStatusGroup, setStatus, setName, setRender } = shopFilterStorage;
    const [ statusGroupActive, setStatusGroupActive ] = useState(null)

    const applyFilter = () => {
        setRender()
    }

    useEffect(() => {
        const getData = async () => {
            await api.post('/orders/get-order-status-group', {})
                .then(res => setStatusGroupActive(res.data))
                .catch(e => console.log(e))
        }
        getData();
    }, [])

    return (
        <div className='filter-zone' id="filter-zone">
                <div className='filter-zone-row'>
                    <Status change={ setStatus }/>
                    { 
                        statusGroupActive !== null ?
                        <StatusesGroup change={ setStatusGroup } data={ statusGroupActive } />
                        : ""
                    }
                    <Name change={ setName }/>
                </div>
            <div className='filter-btn' onClick={ applyFilter }>Применить</div>
        </div>
    )
})

export default StatusFilter;