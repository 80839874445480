import './Notification.css';
import React, { useEffect, useState } from 'react'
import { showNotifications } from "../../menu/MenuFirstElement/MenuFirstElement"
import api from "../../../services/api"

const Notification = () => {

    const currentUser = JSON.parse(window.localStorage.getItem('user'))
    const [ users, setUsers ] = useState(null)
    const [ user, setUser ] = useState(null)

    const createMarkup = (html) => {
        return {__html: html};
    }

    useEffect(() => {
        const getData = async () => {
            await api.post('/goods/get-users', {})
                .then(res => setUsers(res.data))
                .catch(e => console.log(e))
        }
        getData()
    }, [])

    useEffect(() => {

        if (users != null) {
            let userDB = users.filter(user => user.id === currentUser.id)[0]
            setUser(userDB)
        }
    // eslint-disable-next-line
    }, [users])

    return (
        <div className="Notification">
            <div className='notification__top'>
                <div className='notification__top-left'>
                    <div className='notification-title notification-title__active'>Оповещения</div>
                </div>
                <div className='notification__top-right'>
                    <div className='notification-close' onClick={ () => showNotifications() }>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="32" className="notification-close__btn">
                            <path d="M7.113 6.176a.6.6 0 01.848 0L12 10.214l4.038-4.038a.6.6 0 01.849 0l.937.937a.6.6 0 010 .848L13.785 12l4.04 4.04a.6.6 0 010 .848l-.937.937a.6.6 0 01-.849 0L12 13.784l-4.038 4.04a.6.6 0 01-.849 0l-.937-.937a.6.6 0 010-.848l4.04-4.04-4.039-4.038a.6.6 0 010-.849l.937-.936z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className='notification__middle'>
                <div className=''></div>
            </div>
            <div className='notification__area'>
                {
                    user != null ?
                    user.notifications.map(item => {
                        return (
                            <div key={ Math.random() } className='notification-element'>
                                <div>{ item.title }</div>
                                <div dangerouslySetInnerHTML={ createMarkup(item.description) }></div>
                            </div>
                        )
                    })
                    : ''
                }
            </div>

        </div>
    )
}

export default Notification;