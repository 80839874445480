import { makeAutoObservable } from 'mobx';

class ManagersFilterStorage {

    render = 0
    name = null
    totalOrder = null
    averageOrder = null
    countOrder = null

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setName = (data) => {
        this.name = data
    }

    setTotalOrder = (data) => {
        this.totalOrder = data
    }

    setAverageOrder = (data) => {
        this.averageOrder = data;
    }

    setCountOrder = (data) => {
        this.countOrder = data;
    }
    
}

const data = new ManagersFilterStorage();
export default data;