import "./marking.css"
import React from 'react'

const FootherIn = ({ contant }) => {

    return (
        <div className="foother-container-in">
            { contant }
        </div>
    )
}

export default FootherIn;