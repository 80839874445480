import './filters.css';
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import managersStorage from "../../../../storage/managers-filter"
import FilterPopUpManagers from './filter-popup-managers';
import CustomerFilterName from './managers-filters/name'
import CustomerFilterTotalOrder from './managers-filters/total-order'
import CustomerFilterAverageOrder from './managers-filters/average-order'
import CustomerFilterCountOrder from './managers-filters/count-order'

const ManagersFilter = observer(() => {

    const { render, setRender,
        setName,
        setTotalOrder,
        setAverageOrder,
        setCountOrder
    } = managersStorage;

    const applyFilter = () => {
        setRender()
    }

    const showPopUp = () => {
        const area = document.querySelector('#filter-zone-popup')
        area.classList.add('filter-zone-popup__active')
    }

    useEffect(() => {

    }, [render])

    useEffect(() => {
        let filterOrder = JSON.parse(window.localStorage.getItem('filter-customer'))
        let dataFilters = document.querySelectorAll('[data-order-filter]')
        if (filterOrder !== null) {
            dataFilters.forEach(item => {
                if (filterOrder.includes(Number(item.dataset.orderFilter)))
                    item.classList.remove('d-none')
                else
                    item.classList.add('d-none')
            })
        }
    })

    return (
        <div>
            <FilterPopUpManagers />
            <div className='filter-zone' id="filter-zone">
                    <div className='filter-zone-row'>

                        <CustomerFilterName change={ setName } />
                        <CustomerFilterTotalOrder change={ setTotalOrder } />
                        <CustomerFilterAverageOrder change={ setAverageOrder } />
                        <CustomerFilterCountOrder change={ setCountOrder } />

                    </div>
                <div className='filter-btn' onClick={ applyFilter }>Применить</div>
                <div className='filter-btn-settings' onClick={() => showPopUp()}></div>
            </div>
        </div>
    )
})

export default ManagersFilter;