import '../filters.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import ordersStorage from "../../../../../storage/orders"

const OrdersFilterTypesPayment = observer(({ selectChangeColor, data }) => {

    const { setTypePayment } = ordersStorage;

    return (

        <div className='filter-element' data-order-filter="8">
            <label htmlFor="type_payment">Тип оплаты</label>
            <select id="type_payment" defaultValue="" onChange={(e) => {
                    selectChangeColor()
                    setTypePayment(e.target.value)
                }}>
                <option value="">Выберите значение</option>
                {
                    data.map(item => <option key={ Math.random() } value={ item.id }>{ item.name }</option>)
                }
            </select>
        </div>
        
    )
})

export default OrdersFilterTypesPayment;