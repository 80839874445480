import './Customer.css';
import React from 'react'
import { observer } from 'mobx-react-lite';
import Row from '../../common/marking/row';

const Tasks = observer(({ customer }) => {

    return (
        customer.tasks.map(taskItem => {
            return (
                <div key={ Math.random() } className="CustomerContainer">
                    <div className="customer-container">
                        <Row contant={
                            <div key={ Math.random() }>
                                <div className='customer-order-top'>
                                    <div className='customer-order-top__column'>
                                        <span>{ taskItem.title }</span>
                                    </div>
                                    <div className='customer-order-top__column-close'></div>
                                </div>
                                <div className='customer-order-body'>
                                    <div className='customer-order-body__good'>
                                        <div className='customer-order-body__task customer-order-body__task-date'>{new Date(taskItem.date).toLocaleDateString() }</div>
                                        <div className='customer-order-body__task customer-order-body__task-user'>{ taskItem.users[0] !== null ? taskItem.users[0] : "-" }</div>
                                        <div className='customer-order-body__task customer-order-body__task-order'></div>
                                    </div>
                                </div>
                            </div>
                        }/>
                    </div>
                </div>
            )
        })
    )
})

export default Tasks;