import { makeAutoObservable } from 'mobx';

class OrdersStorage {
    
    render = 0;
    number = ''
    types = ''
    customer = ''
    managers = ''
    statusPayment = ''
    goods = ''
    shops = ''
    typePayment = ''
    typeDelivery

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setNumber = (data) => {
        this.number = data;
    }

    setTypes = (data) => {
        this.types = data;
    }

    setCustomer = (data) => {
        this.customer = data;
    }

    setManagers = (data) => {
        this.managers = data;
    }

    setStatusPayment = (data) => {
        this.statusPayment = data;
    }

    setGoods = (data) => {
        this.goods = data;
    }

    setShops = (data) => {
        this.shops = data;
    }

    setTypePayment = (data) => {
        this.typePayment = data;
    }

    setTypeDelivery = (data) => {
        this.typeDelivery = data;
    }

}

const user = new OrdersStorage();
export default user;