import './table.css';
import React, { useEffect, useState } from 'react'
import { insertPaginator } from '../elements/paginator';
import api from '../../../services/api'

const TableShopCardProducts = ({ products, countElements, method, goodsInOrder, setGoodsInOrder }) => {

    const [ currentPage, setCurrentPage ] = useState(1)

    const chooseGood = (id) => {
        const good = document.querySelector(`#goods-id_${id}`)
        console.log(good)
        if (good != null)
            good.innerHTML = `<div class='card-shop-right-element-td card-shop-right-element-td_choosen'>Товар добавлен</div>`
    }

    const linkToGood = (id) => {
        if (goodsInOrder.filter(item => item.id === id).length < 1) {
            const getGood = async () => {
                const data = new FormData();
                data.append('id', Number(id))

                await api.post('/goods/get-good', data)
                    .then(res => {
                        if (res.data != null) {
                            let tempShopCard = goodsInOrder
                            tempShopCard.push({
                                id: res.data.id,
                                goods: res.data,
                                count: 1,
                                totalPrice: null
                            })
                            setGoodsInOrder(tempShopCard)
                        }
                    })
                    .catch(e => console.log(e))
            }
            getGood()
            const good = document.querySelector(`#goods-id_${id}`)
            good.innerHTML = `<div class='card-shop-right-element-td card-shop-right-element-td_choosen'>Товар добавлен</div>`
        }
    }

    useEffect(() => {
        if (goodsInOrder != null && goodsInOrder.length > 0)
            goodsInOrder.forEach(item => chooseGood(item.id))
    }, [goodsInOrder])

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (products.length > 0) {
                products.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id > b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    html += `
                        <tr id="goods-id_${element.id}">
                            <td class='table-products-col'>${ element.name }</td>
                            <td class='table-products-col'>${ element.status ? "<div class='active-element'></div>" : "" }</td>
                            <td class='table-products-col'>${ element.shops.name }</td>
                            <td class='table-products-col'>${ element.groupGoods.name }</td>
                            <td class='table-products-col'>${ "0" }</td>
                            <td class='table-products-col'>${ element.price }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            insertPaginator(countElements, currentPage, setCurrentPage)
            products.forEach(element => {
                let link = document.querySelector('#goods-id_' + element.id)
                link.addEventListener('click', () => linkToGood(element.id))
            })

    // eslint-disable-next-line
    }, [products])

    useEffect(() => {
        method(currentPage)
    // eslint-disable-next-line
    }, [currentPage])

    return (
        <div className='table-box__column'>
            <div className="table-foother" id='paginator'></div>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-products-col'><span>Название</span></th>
                        <th className='table-products-col'><span>Активность</span></th>
                        <th className='table-products-col'><span>Магазин</span></th>
                        <th className='table-products-col'><span>Группа</span></th>
                        <th className='table-products-col'><span>Количество</span></th>
                        <th className='table-products-col'><span>Стоимость</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
    )
}

export default TableShopCardProducts;