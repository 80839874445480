import { makeAutoObservable } from 'mobx';

class CustomerFilterStorage {

    render = 0
    name = null
    email = null
    phone = null
    manager = null
    totalOrder = null
    averageOrder = null
    countOrder = null
    emailSubscription = null
    sex = null

    constructor() {
        makeAutoObservable(this);
    }

    setRender = () => {
        this.render++
    }

    setName = (data) => {
        this.name = data
    }

    setEmail = (data) => {
        this.email = data
    }

    setPhone = (data) => {
        this.phone = data
    }

    setManager = (data) => {
        this.manager = data
    }

    setTotalOrder = (data) => {
        this.totalOrder = data
    }

    setAverageOrder = (data) => {
        this.averageOrder = data;
    }

    setEmailSubscription = (data) => {
        this.emailSubscription = data
    }

    setSex = (data) => {
        this.sex = data
    }

    setCountOrder = (data) => {
        this.countOrder = data;
    }
    
}

const data = new CustomerFilterStorage();
export default data;