import { observer } from 'mobx-react-lite';
import './table.css';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const TableGoodsReceipt = observer(({ data }) => {

    const navigate = useNavigate()

    const linkToGood = (id) => {
        navigate('/goods/receipt/' + id)
    }

    useEffect(() => {
            const body = document.querySelector('#table-body')
            
            let html = ""
            if (data.length > 0) {
                data.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id > b.id) return 1;
                    else return -1;
                }).forEach(element => {
                    html += `
                        <tr id="link-to_${element.id}">
                            <td class='table-receipt-col-1'>${ element.number != null || element.number !== "" ? element.number : "б/н" }</td>
                            <td class='table-receipt-col-2'>${ element.date != null ? new Date(element.date).toLocaleString() : "-" }</td>
                            <td class='table-receipt-col-3'>${ element.provider.name }</td>
                            <td class='table-receipt-col-4'>${ 
                                element.shopCards.map(item => item.goods.name)
                             }</td>
                        </tr>
                    `
                })
            } else {
                html += `<tr class="table-empty-data">
                            <td>Нет данных</td>
                            </tr>`
            }

            body.innerHTML = html;
            data.forEach(element => {
                let link = document.querySelector('#link-to_' + element.id)
                link.addEventListener('click', () => linkToGood(element.id))
            })

    // eslint-disable-next-line
    }, [data])

    return (
        <div className='table-box'>
            <table className="Table" id="table">
                <thead id="table-head">
                    <tr>
                        <th className='table-receipt-col-1'><span>Номер</span></th>
                        <th className='table-receipt-col-2'><span>Дата</span></th>
                        <th className='table-receipt-col-3'><span>Поставщик</span></th>
                        <th className='table-receipt-col-4'><span>Товары</span></th>
                    </tr>
                </thead>
                <tbody id="table-body"></tbody>
            </table>
        </div>
    )
})

export default TableGoodsReceipt;